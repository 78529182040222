import { MenuModule } from "tabulator-tables"
import * as types from "./mutation-types"
import i18n from "../lang"

const buildMenu = permissions => {
  var menu = []

  if (!permissions) {
    return menu
  }

  if (permissions.find(item => item.name == "LAB")) {
    menu.push({
      icon: "ActivityIcon",
      pageName: "lab-analysis-list",
      title: "components.side_menu.laboratory_analysis"
    })
  }

  if (permissions.find(item => item.name == "FOOD")) {
    var menuItem = {
      icon: "CustomIcon",
      iconName: "food",
      pageName: "food-list",
      title: "components.side_menu.foods",
      subMenu: [
        {
          pageName: "food-list",
          title: "components.side_menu.list"
        }
      ]
    }

    if (permissions.find(item => item.name == "FOOD_BY_USER")) {
      menuItem.subMenu.push({
        pageName: "food-list-by-user",
        title: "components.side_menu.list_by_user"
      })
    }
    menu.push(menuItem)
  }

  if (permissions.find(item => item.name == "RECIPES")) {
    var menuItemR = {
      icon: "CustomIcon",
      iconName: "receipes",
      pageName: "receipes-list",
      title: "components.side_menu.personalized_recipes",
      subMenu: [
        {
          pageName: "receipes-list",
          title: "components.side_menu.list"
        }
      ]
    }

    menuItemR.subMenu.push({
      pageName: "receipes-list-by-user",
      title: "components.side_menu.list_by_user"
    })

    menu.push(menuItemR)
  }

  if (permissions.find(item => item.name == "SUPPLEMENTS")) {
    menu.push({
      icon: "CustomIcon",
      iconName: "meds",
      pageName: "supplement-list",
      title: "components.side_menu.supplements"
    })

    menu.push({
      icon: "CustomIcon",
      iconName: "drug",
      pageName: "medication-list",
      title: "components.side_menu.medication",
      subMenu: [
        {
          pageName: "medication-family-list",
          title: "components.side_menu.medication_class"
        },
        {
          pageName: "medication-list",
          title: "components.side_menu.medication"
        }
      ]
    })

    menu.push({
      icon: "CustomIcon",
      iconName: "disease",
      pageName: "disease-list",
      title: "components.side_menu.disease"
    })
  }

  if (permissions.find(item => item.name == "HEALTHY_ACTIVITIES")) {
    menu.push({
      icon: "CustomIcon",
      iconName: "activities",
      pageName: "activities-list",
      title: "components.side_menu.activities"
    })
  }

  if (permissions.find(item => item.name == "SESSIONS")) {
    menu.push({
      icon: "ListIcon",
      pageName: "sessions-list",
      title: "components.side_menu.sessions"
    })
  }

  if (permissions.find(item => item.name == "IA")) {
    menu.push({
      icon: "CustomIcon",
      iconName: "brain",
      pageName: "ai-input",
      title: "components.side_menu.ia"
    })
  }

  /*menu.push({
    icon: 'UsersIcon',
    pageName: 'sessions-list',
    title: 'components.side_menu.users',
    disabled: true});*/

  menu.push({
    icon: "TrelloIcon",
    pageName: "side-menu-profile",
    title: "components.side_menu.report",
    disabled: true,
    subMenu: []
  })

  return menu
}

const state = () => {
  var menu = []

  if (!localStorage.backoffice_permissions) {
    return { menu }
  }

  var permissions = JSON.parse(localStorage.backoffice_permissions)

  menu = buildMenu(permissions)

  return { menu }

  // Dejar el menu de abajo como referencia

  return {
    menu: [
      //   {
      //     icon: 'BoxIcon',
      //     pageName: 'side-menu-menu-layout',
      //     title: 'Menu Layout',
      //     subMenu: [
      //       {
      //         icon: '',
      //         pageName: 'side-menu-dashboard-overview-1',
      //         title: 'Side Menu',
      //         ignore: true
      //       },
      //       {
      //         icon: '',
      //         pageName: 'simple-menu-dashboard-overview-1',
      //         title: 'Simple Menu',
      //         ignore: true
      //       },
      //       {
      //         icon: '',
      //         pageName: 'top-menu-dashboard-overview-1',
      //         title: 'Top Menu',
      //         ignore: true
      //       }
      //     ]
      //   },
      //   {
      //     icon: 'InboxIcon',
      //     pageName: 'side-menu-inbox',
      //     title: 'Inbox'
      //   },
      //   {
      //     icon: 'HardDriveIcon',
      //     pageName: 'side-menu-file-manager',
      //     title: 'File Manager'
      //   },
      //   {
      //     icon: 'CreditCardIcon',
      //     pageName: 'side-menu-point-of-sale',
      //     title: 'Point of Sale'
      //   },
      //   {
      //     icon: 'MessageSquareIcon',
      //     pageName: 'side-menu-chat',
      //     title: 'Chat'
      //   },
      //   {
      //     icon: 'FileTextIcon',
      //     pageName: 'side-menu-post',
      //     title: 'Post'
      //   },
      //   {
      //     icon: 'CalendarIcon',
      //     pageName: 'side-menu-calendar',
      //     title: 'Calendar'
      //   },
      //   'devider',
      {
        icon: "UsersIcon",
        pageName: "sessions-list",
        title: "components.side_menu.users",
        disabled: true
        // subMenu: [
        //   {
        //     icon: 'FileTextIcon',
        //     pageName: 'users',
        //     title: 'components.side_menu.result_users'
        //   },
        // {
        //   icon: '',
        //   pageName: 'side-menu-users-layout-2',
        //   title: 'Layout 2'
        // },
        // {
        //   icon: '',
        //   pageName: 'side-menu-users-layout-3',
        //   title: 'Layout 3'
        // }
        // ]
      },
      {
        icon: "TrelloIcon",
        pageName: "side-menu-profile",
        title: "components.side_menu.report",
        disabled: true,
        subMenu: [
          // {
          //   icon: '',
          //   pageName: 'side-menu-profile-overview-1',
          //   title: 'Overview 1'
          // },
          // {
          //   icon: '',
          //   pageName: 'side-menu-profile-overview-2',
          //   title: 'Overview 2'
          // },
          // {
          //   icon: '',
          //   pageName: 'side-menu-profile-overview-3',
          //   title: 'Overview 3'
          // }
        ]
      },
      // {
      //   icon: 'LayoutIcon',
      //   pageName: 'side-menu-layout',
      //   title: 'Pages',
      //   subMenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-wizards',
      //       title: 'Wizards',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-wizard-layout-1',
      //           title: 'Layout 1'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-wizard-layout-2',
      //           title: 'Layout 2'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-wizard-layout-3',
      //           title: 'Layout 3'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-blog',
      //       title: 'Blog',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-blog-layout-1',
      //           title: 'Layout 1'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-blog-layout-2',
      //           title: 'Layout 2'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-blog-layout-3',
      //           title: 'Layout 3'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-pricing',
      //       title: 'Pricing',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-pricing-layout-1',
      //           title: 'Layout 1'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-pricing-layout-2',
      //           title: 'Layout 2'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-invoice',
      //       title: 'Invoice',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-invoice-layout-1',
      //           title: 'Layout 1'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-invoice-layout-2',
      //           title: 'Layout 2'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-faq',
      //       title: 'FAQ',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-faq-layout-1',
      //           title: 'Layout 1'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-faq-layout-2',
      //           title: 'Layout 2'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-faq-layout-3',
      //           title: 'Layout 3'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'login',
      //       title: 'Login'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'register',
      //       title: 'Register'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'error-page',
      //       title: 'Error Page'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-update-profile',
      //       title: 'Update profile'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-change-password',
      //       title: 'Change Password'
      //     }
      //   ]
      // },
      "devider"
      // {
      //   icon: 'InboxIcon',
      //   pageName: 'side-menu-components',
      //   title: 'Components',
      //   subMenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-table',
      //       title: 'Table',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-regular-table',
      //           title: 'Regular Table'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-tabulator',
      //           title: 'Tabulator'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-overlay',
      //       title: 'Overlay',
      //       subMenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-modal',
      //           title: 'Modal'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-slide-over',
      //           title: 'Slide Over'
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-notification',
      //           title: 'Notification'
      //         }
      //       ]
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-accordion',
      //       title: 'Accordion'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-button',
      //       title: 'Button'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-alert',
      //       title: 'Alert'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-progress-bar',
      //       title: 'Progress Bar'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-tooltip',
      //       title: 'Tooltip'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dropdown',
      //       title: 'Dropdown'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-typography',
      //       title: 'Typography'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-icon',
      //       title: 'Icon'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-loading-icon',
      //       title: 'Loading Icon'
      //     }
      //   ]
      // },
      // {
      //   icon: 'SidebarIcon',
      //   pageName: 'side-menu-forms',
      //   title: 'Forms',
      //   subMenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-regular-form',
      //       title: 'Regular Form'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-datepicker',
      //       title: 'Datepicker'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-tom-select',
      //       title: 'Tom Select'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-file-upload',
      //       title: 'File Upload'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-wysiwyg-editor',
      //       title: 'Wysiwyg Editor'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-validation',
      //       title: 'Validation'
      //     }
      //   ]
      // },
      // {
      //   icon: 'HardDriveIcon',
      //   pageName: 'side-menu-widgets',
      //   title: 'Widgets',
      //   subMenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-chart',
      //       title: 'Chart'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-slider',
      //       title: 'Slider'
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-image-zoom',
      //       title: 'Image Zoom'
      //     }
      //   ]
      // }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {
  updateMenu({ commit }, permissions) {
    commit(types.SET_MENU, { permissions })
  }
}

// mutations
const mutations = {
  [types.SET_MENU](state, { permissions }) {
    state.menu = buildMenu(permissions)
    localStorage.backoffice_permissions = JSON.stringify(permissions)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
