import ApiService from './api.service'

const LabService = {

    getUserLabResult: async function(params) {

        const response = await ApiService._get("/api/quiz/get-user-lab-analysis-result-per-quiz", params);
        return response.data

    },
    
    getLabItems: async function(params) {

        const response = await ApiService._get("/api/admin/lab", params);
        return response.data

    },
    saveLabItem: async function(params) {

        const response = await ApiService.post("/api/admin/lab/save-lab-item", params);
        return response.data

    },
    saveLabItemLevel: async function(params) {

        const response = await ApiService.post("/api/admin/lab/save-lab-item-level", params);
        return response.data

    },
    getRecomendationsByLabItem: async function(params) {

        const response = await ApiService._get("/api/lab-analysis/display-recommendations", params);
        return response.data

    }

}

export default LabService
