import ApiService from './api.service'

const SupplemetService = {

    getAll: async function(params) {

        const response = await ApiService._get("/api/admin/suplement", params);
        return response.data

    },

    getSupplement: async function(id, lang = null) {

        const response = await ApiService.get("/api/admin/suplement/"+id, lang);
        return response.data

    },

    getCategories: async function(lang = null) {

        const response = await ApiService.get("/api/admin/suplement/categories", lang);
        return response.data

    },

    getBrands: async function(lang = null) {

      const response = await ApiService.get("/api/admin/suplement/brands", lang);
      return response.data

    },

    save: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/suplement/supplement", params, lang);
      return response.data

    },

    saveCategory: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/suplement/create_category", params, lang);
      return response.data

    },

    getInteractionSupplement: async function(params, lang = null) {

      const response = await ApiService._get("/api/admin/suplement/get-supplement-interaction", params, lang);
      return response.data

    },

    saveInteractionSupplement: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/suplement/save-supplement-interaction", params, lang);
      return response.data

    },

    deleteInteractionSupplement: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/suplement/delete-supplement-interaction", params, lang);
      return response.data

    }

}

export default SupplemetService
