import ApiService from './api.service'

const MedicationService = {

    getAll: async function(params) {

        const response = await ApiService._get("/api/admin/medication/get-drug", params);
        return response.data

    },

    getDrug: async function(id, lang = null) {

        const response = await ApiService.get("/api/admin/medication/get-drug?id="+id, lang);
        return response.data

    },


    save: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/medication/save-drug", params, lang);
      return response.data

    },


    remove: async function(id, lang = null) {

      const response = await ApiService.get("/api/admin/medication/delete-drug?id="+id, lang);
      return response.data

    },

    getAllFamilies: async function() {

      const response = await ApiService.get("/api/admin/medication/get-medication-class");
      return response.data

    },

    saveFamily: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/medication/save-medication-class", params, lang);
      return response.data

    },

    removeFamily: async function(id, lang = null) {

      const response = await ApiService.get("/api/admin/medication/delete-medication-class?id="+id, lang);
      return response.data

    },

    addMedicineSupplement: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/medication/save-supplement-interaction", params, lang);
      return response.data

    },

    getMedicineSupplement: async function(params, lang = null) {

      const response = await ApiService._get("/api/admin/medication/get-supplement-interaction", params, lang);
      return response.data

    },

    deleteMedicineSupplement: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/medication/delete-supplement-interaction", params, lang);
      return response.data

    }

}

export default MedicationService
