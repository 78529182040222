<template>
  <AlertMessage id_alert="drugEdit" ref="alertMessage" class="dark myAuto" v-bind:message="'<p>Alerta</p>'" />
  <div>
    <div id="save-drug-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.medication.text9') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="remove-drug-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.medication.text10') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.medication.text11') }}: {{ lang_inputs[global_lang].name }}
      </h2>
      <!-- <p class="mt-3">Ingrese los datos de la receta.</p> -->
    </div>
   <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
            <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__'+lang.key)" :data-target="'#lang__'+lang.key" href="javascript:;" class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md" :class="(activeLang === 'lang__'+lang.key) ? 'active' : ''" :id="'lang__'+lang.key+'-tab'" role="tab" :aria-controls="'lang__'+lang.key" aria-selected="false"> {{ $t('components.langs.'+lang.key+'.name') }} </a>
        </div> -->
<!-- 
        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__'+lang.key" class="tab-content">
          <div :id="'lang__'+lang.key" class="tab-pane " :class="(activeLang === 'lang__'+lang.key) ? 'active' : ''" role="tabpanel" :aria-labelledby="'lang__'+lang.key+'-tab'"> -->

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5">
              <div>
                <label for="crud-form-1" class="form-label ">{{ $t('views.medication.text12') }}</label>
                <div class="input-group">
                  <input
                    id="crud-form-1"
                    type="text"
                    name="chart-tooltip"
                    class="form-control w-full"
                    :placeholder="$t('views.medication.text12')"
                    v-model="drug.name"
                    maxlength="110"
                  />
                </div>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t('views.medication.text13') }}
                </div>
              </div>
              <div class="mt-5">
                <label for="crud-form-1" class="form-label ">{{ $t('views.medication.text20') }}</label>
                <div class="input-group">
                  <TomSelect
                    ref="family-lang"
                    class="w-full"
                    v-model="medication_class_drugs"
                    :placeholder="$t('views.food.text24')"
                  >
                    <option v-for="(item, key) in families_drug" :key="key" :value="item.id">
                      {{ item.name }}
                    </option>
                    
                  </TomSelect>
                </div>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t('views.medication.text13') }}
                </div>
              </div>

              <div class="mt-5">
                <label for="crud-form-1" class="form-label ">{{ $t('views.medication.text8') }}</label>

                <template v-for="(item, itemKey) in drug.drug_commercials" :key="itemKey">
                    <div class="input-group mt-2"  >
                        <input
                            id="crud-form-1"
                            type="text"
                            name="chart-tooltip"
                            class="form-control w-full"
                            :placeholder="$t('views.medication.text14')"
                            v-model="item.name"
                            maxlength="110"
                            @blur="addrugComercial"
                            v-on:keyup.enter="addrugComercial"
                        />
                        <button
                            v-on:click="removeDrugComercial(item)"
                            type="button"
                            class="btn-outline-dark btn-rounded btn-sm remove-item"
                        >
                            <XCircleIcon class="btn-source-icon" />
                        </button>
                    </div>
                </template>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text15') }}
                </div>
              </div>

              


              <div class="text-right mt-5">
                <button
                  v-on:click="confirmRemove"
                  type="button"
                  class="btn btn-outline-dark w-24 mr-5"
                >
                  <LoadingIcon v-if="removeLoading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.delete') }}</span>
                </button>
                <button
                  v-on:click="saveDrug"
                  type="button"
                  class="btn btn-primary w-24"
                >
                  <LoadingIcon v-if="loading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.save') }}</span>
                </button>
              </div>
            </div>
            <!-- END: Form Layout -->
          <!-- </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}
.mt-3 {
  color: #fff;
}
.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}
.max-w-label {
    max-width: 90%;
}

.dark .form-check-input[type=checkbox] {
    background-color: #182033 !important;
    border-color: rgba(255, 255, 255, 0.5) !important;
}
.dark .nutrient{
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.dark .nutrient .form-check-input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.dark .nutrient .form-check-label{
  border-color: #6c869a;
}
.dark .nutrient:hover input ~ .form-check-label {
  background-color: rgba(108, 134, 154, 0.5);
}

/* When the checkbox is checked, add a blue background */
.form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: white;
}
.dark .nutrient input:checked ~ .form-check-label {
  background-color: #6c869a;
  color: white;
}
.form-check-input.checked[type=radio]{
  border-color: #6c869a !important;
}
.form-check-input[type=radio]:before {
    background-color: #6c869a !important;
}
.dark .form-check-input.checked[type=radio]{
  border-color: rgba(255, 255, 255, 0.5) !important;
}
.dark .form-check-input.checked[type=radio]:before {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
<style>
.receipe-item:last-child .remove-item{
  visibility: hidden;
}
</style>
<script>
import MedicationService from "/src/services/medications.service";
import Toastify from "toastify-js";
import AlertMessage from "@/components/alert/Main.vue"

export default {
  components: {
    AlertMessage
  },
  setup() {},
  data() {
    return {
      validations: {
        name: false
      },
      loading: false,
      removeLoading: false,
      drug : {
        name : "",
        drug_commercials : [{
          name: ""
        }]
      },
      activeLang: 'lang__'+(localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs : this.$global.getLangs(),
      lang_key: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      global_lang: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      lang_inputs : [],
      getData : true,
      families_drug: [],
      medication_class_drugs: []
    };
  },
  watch:{
    '$i18n.locale': function(l) {
      this.getDrug();
    },
    activeLang: function(){
      this.lang_key = this.activeLang.replace("lang__", "")
    }
  },
  computed: {

  },
  created() {
    this.lang_inputs = this.langs.map(l => l.key).reduce((x, y)=> (x[y]={name : ""},x),{});
    this.lang_inputs[this.lang_key]["name"] = this.drug["name"]
    this.getDrug();
    this.getFamiliesDrug();
  },
  mounted(){

  },
  methods: {
    getDrug: async function () {
      this.drug =  (localStorage.drugItem) ? JSON.parse(localStorage.drugItem) : this.drug
      this.medication_class_drugs = this.drug.medication_class_drugs.map(d => d.medication_class_id)
      this.addrugComercial();
      this.drug.commercial_names_deleted = []
      Object.keys(this.lang_inputs).map(function(lang) {
        Object.keys(this.lang_inputs[lang]).map(function(key) {
            this.lang_inputs[lang][key] = this.drug[key]
        }.bind(this))
      }.bind(this));
    },
    saveSuccessDrug() {
      Toastify({
        node: cash('#save-drug-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('drugItem');
      this.$router.push({ path: '/medication-list' });
    },
    saveDrug : async function() {
        this.validations.name = (this.drug.name === "")

        if (this.validations.name)
          return

        this.drug.commercial_names = this.drug.drug_commercials.filter(d => d.name !== "")
        this.drug.medication_classes = this.medication_class_drugs
        this.loading = true
        var serverResponse = await MedicationService.save(this.drug, this.lang_key)
        this.loading = false
        if(serverResponse && serverResponse.data && serverResponse.data.length > 0 && !this.drug.id){
          this.drug.id = serverResponse.data[0].id
        }
        this.saveSuccessDrug()
    },
    getFamiliesDrug(){
        MedicationService.getAllFamilies().then((response)=>{
            this.families_drug = response.data

        })
    },
    confirmRemove(){
      var message = "<p class='text-base px-4'>"+this.$t('views.receipes.text16')+"</p>"
      this.$refs.alertMessage.show(message, true, true, this.removeDrug)
    },
    removeDrug(){
      this.removeLoading = true
      this.$refs.alertMessage.hide()
      MedicationService.remove(this.drug.id).then(function(response){
        this.removeLoading = false
        this.removeSuccessDrug()
      }.bind(this)).catch((error)=>{
        this.removeLoading = false
      })
    },
    removeSuccessDrug() {
      Toastify({
        node: cash('#remove-drug-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('drugItem');
      this.$router.push({ path: '/medication-list' });
    },
    changeLang(l){
      this.activeLang = l
    },
    addrugComercial(){
        var empty = this.drug.drug_commercials.filter(d => d.name === "")
        if(empty.length === 0){
            this.drug.drug_commercials.push({name : ""})
        }
    },
    removeDrugComercial(item){
        var i = this.drug.drug_commercials.findIndex(d => d.name === item.name)

        if(i !== -1 && this.drug.drug_commercials.length > 1){
            if(this.drug.drug_commercials[i].id)
                this.drug.commercial_names_deleted.push(this.drug.drug_commercials[i].id)
            this.drug.drug_commercials.splice(i, 1)
        
        }
        
        this.addrugComercial()

    }
  },
};

</script>
<style>
</style>
