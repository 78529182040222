<template>
  <div>
    <div class="flex items-baseline justify-between">
      <h2 class="intro-y text-lg font-medium mt-10">{{ $t('views.supplements.text1') }}</h2>
      <button type="button" class="btn btn-primary w-24 h-10" @click="newSupplementItem"><span>{{ $t('views.new') }}</span></button>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-12">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <p class="mr-auto">{{ $t('views.supplements.text2') }}</p>
        <div class="cursor w-full sm:w-56 mt-3 sm:mt-0 sm:ml-auto md:ml-0 mr-5">
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <TomSelect
              class="w-full cursor"
              v-model="category"
            >
              <option value="0">{{ $t('views.supplements.text3') }}</option>
              <option v-for="(cat, key) in categories" :key="key" :value="cat.id">{{ cat.name }}</option>
            </TomSelect>
          </div>
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('views.supplements.text4')"
              v-model="searchInput"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">
                  <div class="grid supplements-grid">
                    <div>{{ $t('views.supplements.text5') }}</div>
                    <div>{{ $t('views.supplements.text6') }}</div>
                    <div>{{ $t('views.supplements.text45') }}</div>
                    <div>{{ $t('views.supplements.text47') }}</div>
                    <div>{{ $t('views.supplements.text46') }}</div>
                  </div>
              </th>
              <th class="text-center whitespace-nowrap">{{ $t('views.supplements.text8') }}</th>
            </tr>
          </thead>
          <tbody  class="">
            <template v-for="(item, itemKey) in supplemets" :key="itemKey">
            <tr class="intro-x" >
              <td >
                <div class="grid supplements-grid cursor-pointer" @click="item.show = !item.show">
                    <div>{{ item.name }}</div>
                    <div v-if="!item.show" class="flex flex-wrap">
                      <template v-for="(cat, catKey) in item.categories" :key="catKey">
                          <div class="py-1 px-2 rounded-md text-xs bg-theme-9 text-white cursor-pointer font-medium mr-3 my-1 bg-color-1">{{ getCat(cat) }}</div>
                      </template>
                    </div>
                    <div v-if="!item.show" class="truncate">
                      <Tippy data-theme="light"  :content="(item.benefits) ? item.benefits.join(', ') : ''">{{ item.benefits.join(', ') }}</Tippy>
                    </div>
                    <div v-if="!item.show">
                      <p class="" v-if="item.dose.modality === 'RANGE'">{{ 'Min: ' + item.dose.min + ' (' + item.dose.unit + ') - Max: ' + item.dose.max + ' ('+item.dose.unit+')' }}</p>
                      <p class="" v-if="item.dose.modality === 'SPECIFIC' && item.dose.min">{{ item.dose.min + ' (' + item.dose.unit + ')' }}</p>
                    </div>
                    <div v-if="!item.show" class="truncate">
                      <Tippy data-theme="light"  :content="(item.brands) ? item.brands.join(', ') : ''">{{ item.brands.join(', ') }}</Tippy>
                    </div>
                </div>
                <div class="w-full intro-x mt-5" v-if="item.show">
                  <h2 v-if="item.description !== '' " class="dark:text-white font-medium">{{ $t('views.supplements.text7') }}: </h2>
                  <p v-if="item.description !== '' " class="mt-3">{{ item.description }}</p>
                  <h2 v-if="item.categories.length > 0 " class="dark:text-white font-medium mt-5">{{ $t('views.supplements.text6') }}: </h2>
                  <div v-if="item.categories.length > 0 " class="flex flex-wrap mt-2">
                    <template v-for="(cat, catKey) in item.categories" :key="catKey">
                        <div class="py-1 px-2 rounded-md text-xs bg-theme-9 text-white cursor-pointer font-medium mr-3 my-1 bg-color-1">{{ getCat(cat) }}</div>
                    </template>
                  </div>
                  <h2 v-if="item.benefits.length > 0 " class="dark:text-white font-medium mt-5">{{ $t('views.supplements.text45') }}: </h2>
                  <div v-if="item.benefits.length > 0" class="truncate mt-3">
                    <Tippy data-theme="light"  :content="(item.benefits) ? item.benefits.join(', ') : ''">{{ item.benefits.join(', ') }}</Tippy>
                  </div>
                  <h2 v-if="item.dose.modality" class="dark:text-white font-medium mt-5">{{ $t('views.supplements.text47') }}: </h2>
                  <p class="mt-3" v-if="item.dose.modality === 'RANGE'">{{ 'Min: ' + item.dose.min + ' (' + item.dose.unit + ') - Max: ' + item.dose.max + ' ('+item.dose.unit+')' }}</p>
                  <p class="mt-3" v-if="item.dose.modality === 'SPECIFIC' && item.dose.min">{{ item.dose.min + ' (' + item.dose.unit + ')' }}</p>

                  <h2 v-if="item.brands.length > 0" class="mt-5 dark:text-white font-medium">{{ $t('views.supplements.text46') }}:</h2>
                  <div v-if="item.brands.length > 0" class="grid grid-cols-1 md:grid-cols-2">
                      <div class="flex items-center mt-4" v-for="(brand, ingKey) in item.brands" :key="ingKey">
                          <div class="w-2 h-2 bg-theme-13 rounded-full mr-3"></div>
                          <span class="truncate">{{ brand }}</span>
                      </div>
                  </div>
                  <h2 v-if="item.schedule" class="dark:text-white font-medium mt-5">{{ $t('views.supplements.text28') }}: </h2>
                  <p v-if="item.schedule" class="mt-3">{{ $t('views.posology.'+item.schedule)  }}</p>
                </div>
              </td>
              <td class="table-report__action w-56 relative p-5" style="width:25px">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;" @click="editSupplementItem(item)">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    {{ $t('views.edit') }}
                  </a>
                </div>
              </td>
            </tr>
            </template>
            <tr v-if="loading">
              <td colspan="4" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && supplemets.length === 0">
              <td colspan="4" class="p-4">
                 {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
        v-if="supplemets.length > 0"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(1)">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('first')" v-bind:class="{'pagination__link--active': pagination.page == getNumberPgae('first')}" href="javascript:;"  @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
          </li>
          <li>
            <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('second')" v-bind:class="{'pagination__link--active': pagination.page == getNumberPgae('second')}" href="javascript:;"  @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
          </li>
          <li v-if="getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
            <a class="pagination__link" v-bind:class="{'pagination__link--active': pagination.page == getNumberPgae('')}" href="javascript:;"  @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage('next')">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="ml-auto mr-5 text-gray-600">
          {{ $t('views.pagination.items', {total : supplemets.length}) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page" @change="() => { changePerPage() }">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<style scoped>
  .cursor{
    cursor: pointer !important;
  }
  .table td.p-0{
    padding: 0px !important;
  }
  .bg-color-1{
    background-color: #828181;
  }
  .btn-primary {
    background: #6c869a !important;
    border-color: #6c869a !important;
  }
</style>

<style>
  .tom-select .ts-dropdown .option.active{
    background: #6c869a !important;
  }
  .top-bar .breadcrumb > a:not(.breadcrumb--active){
    color: #6c869a !important;
  }
  .detail-food{
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: initial;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .pagination li, .pagination a, .pagination a:focus, .pagination a:active{
    outline: none !important;
  }
  .supplements-grid{
    grid-template-columns: 150px 200px 150px 150px 150px;
    align-items: center;
    width: 100%;
  }
  @media screen and (min-width: 1450px) {
      .supplements-grid{
        grid-template-columns: 12vw auto 15vw 12vw 12vw;
      }
  }
</style>

<script>
import SupplementService from "/src/services/supplements.service";
import _ from 'lodash';

export default {
  setup() {
  },
  data() {
    return {
      supplemets: [],
      searchInput: "",
      quizToSearch:0,
      pagination : {
        page : 1,
        total_pages : 3,
        per_page : 50,
        total : 0
      },
      categories : [],
      category : 0,
      bg : 1,
      loading : true,
      brands: [],
    }
  },
  watch:{
    searchInput: _.debounce(function (newSearch, oldSearch) {
      this.pagination.page = 1
      this.getAll();
    }, 500),
    category: function () {
      this.pagination.page = 1
      this.getAll();
    },
    '$i18n.locale': function(l) {
      this.getCategories()
    }
  },
  created(){
    window.vue = this
    this.getCategories()
  },
  methods:{
    getAll(){
      var params = {
        pagination : this.pagination
      }
      if(this.searchInput != ""){
        params.search = this.searchInput.replaceAll(" ", "_")
      }
      if(this.category > 0){
        params.category = this.category
      }

      var _this = this
      SupplementService.getAll(params).then((response)=>{
        this.loading = false
        this.supplemets = (response.data) ? response.data : response
        this.supplemets = this.supplemets.map(s => {
          s.show = false
          return s
        })
        this.pagination.total_pages = (response.pagination.total_pages) ? response.pagination.total_pages : 0
        this.pagination.total = (response.pagination.total) ? response.pagination.total : response.data.length
      }).catch(function(e){
        _this.loading = false
      });
    },
    getCategories() {
      var _this = this
      SupplementService.getCategories().then((response) => {
        this.categories = (response.data) ? response.data : response
        this.getBrands();
      }).catch(function(e){
        _this.loading = false
      });
    },
    getBrands() {
      var _this = this
      SupplementService.getBrands().then((response) => {
        var brands = (response.data) ? response.data : response
        brands.map(b => {
          _this.brands[b.id] = b.name
        })
        this.getAll();
      }).catch(function(e){
        _this.loading = false
      });
    },
    editSupplementItem(supplementItem){
      localStorage.setItem('supplementItem', JSON.stringify(supplementItem));
      this.$router.push({ path: '/supplement-edit' });
    },
    newSupplementItem(supplementItem){
      localStorage.removeItem('supplementItem');
      this.$router.push({ path: '/supplement-new' });
    },

    goPage : function(param){
      if(param == 'prev'){
        this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
      }else if(param == 'next'){
        this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
      }else{
        this.pagination.page = param
      }
      this.getAll();
    },
    getNumberPgae(type){
      if(type == "first"){
        return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
      }else if(type == "second"){
        return (this.pagination.page > 2) ? this.pagination.page : 2
      }else{
        return (this.pagination.page > 2) ? (this.pagination.page + 1 > this.pagination.total_pages) ? this.pagination.total_pages : this.pagination.page + 1 : 3
      }
    },
    changePerPage(){
      this.pagination.page = 1
      this.pagination.per_page = Number(this.pagination.per_page)
      this.getAll()
    },
    getCategory(item){
      return (item.category && item.category.name) ? item.category.name : ""
    },
    getBG(){
      this.bg = (this.bg == 4) ? 1 : this.bg + 1
      return 'bg-color-'+this.bg
    },
    getCat(id){
      var cat = this.categories.find(function(c){
        return c.id == id
      })

      cat = (cat) ? cat.name : id
      return cat
    }
  }
}
</script>
