<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar border-dark-3">
    <div
      id="save-success"
      class="toastify-content hidden flex"
    >
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('components.message_sent') }}</div>
        <div class="text-gray-600 mt-1">
        </div>
      </div>
    </div>
    <div
      id="save-error"
      class="toastify-content hidden flex"
    >
      <LoadingIcon icon="oval" class="w-8" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('components.message_sent_error') }}
        <br>
          {{ $t('components.trying_again') }} {{errorNotifyErrorCount}}...</div>
        <div class="text-gray-600 mt-1">
        </div>
      </div>
    </div>
    <div id="button-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content box">
              <a data-dismiss="modal" href="javascript:;"> <i data-feather="x" class="w-8 h-8 text-gray-500"></i> </a>
                <div class="intro-y box p-5">
                  <div class="mt-3">
                    <label for="crud-form-3" class="form-label">{{ $t('components.report_error') }}</label>
                    <div class="input-group">
                      <textarea
                        id="crud-form-3"
                        type="text"
                        class="form-control"
                        :placeholder="$t('components.message')"
                        aria-describedby="input-group-1"
                        v-model="errorNotifyMessage"
                      />
                    </div>
                  </div>
                  <div class="text-right mt-5">
                    <button v-bind:disabled="errorNotifyMessage.length == 0" data-dismiss="modal" v-on:click="saveErrorNotify" type="button" class="btn btn-primary w-24">
                      <LoadingIcon v-if="errorNotifyLoading" icon="three-dots" class="" />
                      <span v-else>{{ $t('components.send') }}</span>
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden">
      <a href="javascript:void(0)" id="first-bread">{{ $t('components.laboratory_analysis') }}</a>
      <ChevronRightIcon class="breadcrumb__icon" />
      <a href="javascript:void(0)" class="breadcrumb--active"  id="second-bread">{{ $t('components.indicators') }}</a>
    </div>
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <template v-for="(bread, key) in breadcrumbs" :key="key">
          <router-link v-if="bread.link != ''" v-bind:to="bread.link" v-bind:class="{ 'breadcrumb--active' : key == 1}">{{ $t(bread.title) }}</router-link>
          <a v-else href="javascript:void(0)" v-bind:class="{ 'breadcrumb--active' : key == 1}">{{ $t(bread.title) }}</a>
          <ChevronRightIcon v-if="breadcrumbs.length -1 > key" class="breadcrumb__icon" />
      </template>
    </div>
    <!-- END: Breadcrumb -->

    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6 hidden">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input form-control border-transparent placeholder-theme-13"
          :placeholder="$t('components.search')"
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href="">
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">{{ $t('components.pages') }}</div>
          <div class="mb-5">
            <a href="" class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">{{ $t('components.mail_settings') }}</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">{{ $t('components.users_permissions') }}</div>
            </a>
            <a href="" class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">{{ $t('components.transactions_report') }}</div>
            </a>
          </div>
          <div class="search-result__content__title">{{ $t('components.users') }}</div>
          <div class="mb-5">
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                {{ faker.users[0].email }}
              </div>
            </a>
          </div>
          <div class="search-result__content__title">{{ $t('components.products') }}</div>
          <a
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.images[0]}`)"
              />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              {{ faker.products[0].category }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div class="intro-x dropdown mr-auto sm:mr-6">
      <div
        class="dropdown-toggle notification cursor-pointer"
        role="button"
        aria-expanded="false"
      >
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-menu">
        <div
          class="notification-content__box dropdown-menu__content box dark:bg-dark-6"
        >
          <div class="notification-content__title">{{ $t('components.notifications') }}</div>
          <div
            v-for="(faker, fakerKey) in $_.take($f(), 3)"
            :key="fakerKey"
            class="cursor-pointer relative flex items-center"
            :class="{ 'mt-5': fakerKey }"
          >
            <!-- <div class="w-12 h-12 flex-none image-fit mr-1">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="require(`@/assets/images/${faker.photos[0]}`)"
              />
              <div
                class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
              ></div>
            </div> -->
            <div class="ml-2 overflow-hidden">
              <div class="flex items-center">
                <a href="javascript:;" class="font-medium truncate mr-5">{{
                  faker.users[0].name
                }}</a>
                <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                  {{ faker.times[0] }}
                </div>
              </div>
              <div class="w-full truncate text-gray-600 mt-0.5">
                {{ faker.news[0].shortContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img
          alt=""
          :src="require(`@/assets/images/ilong/ilong-profile-photo.png`)"
        />
      </div>
      <div class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-custom-gray-1 dark:bg-dark-6 text-white"
        >
          <div class="p-4 border-b border-gray-400">
            <div class="font-medium">{{ (user.session_name) ? user.session_name : $f()[0].users[0].name }}</div>
            <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
              <!-- {{ (user.job) ? user.job : $f()[0].jobs[0] }} -->
            </div>
          </div>
          <div class="p-2">
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> {{ $t('components.my_profile') }}
            </a>
            <!-- <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-dark-3 rounded-md"
            >
              <EditIcon class="w-4 h-4 mr-2" /> Add Account
            </a> -->
            <a
              data-toggle="modal" data-target="#button-modal-preview"
              href="javascript:;"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-dark-3 rounded-md"
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" /> {{ $t('components.report_error') }}
            </a>

          </div>
          <div class="p-2 border-t border-gray-400">
              <div class="accordion-item">
                <div id="lang-content" class="accordion-header" >
                    <button class="accordion-button collapsed w-full text-left p-2 items-center" type="button" data-bs-toggle="collapse" data-bs-target="#lang-accordion-collapse" aria-expanded="false" aria-controls="lang-accordion-collapse">
                        <FlagIcon class="w-4 h-4 mr-2" /> {{ $t('components.idiom') }}
                    </button>
                </div>
                <div id="lang-accordion-collapse" class="accordion-collapse collapse" aria-labelledby="lang-content" data-bs-parent="#food-accordion-1" style="display : none">
                    <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed flex p-2 flex-wrap gap-4">
                      <!-- <template v-for="(lang, itemKey) in langs" :key="itemKey">
                        <div class="form-check mr-2 lang">
                          <input :id="'checkbox-switch-'+lang.key" class="form-check-input hidden" type="checkbox" v-model="lang.active" @change="changeLang(lang)">
                          <label class="form-check-label w-max p-2 border rounded block m-0" :for="'checkbox-switch-'+lang.key">{{ $t('components.langs.'+lang.key+'.name')}}</label>
                        </div>
                      </template> -->
                      <TomSelect
                        class="w-full cursor without_search"
                        v-model="activeLang"
                        :input_hide="true"
                      >
                        <option v-for="(lang, key) in langs" :key="key" :value="lang.key">{{ $t('components.langs.'+lang.key+'.name') }}</option>
                      </TomSelect>
                    </div>
                </div>
            </div>
          </div>
          <div class="p-2 border-t border-gray-400">
            <DarkModeSwitcher v-bind:type="'button'" />
            <button type="button"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-dark-3 rounded-md w-full"
              @click="logout"
            >
              <LogOutIcon class="w-4 h-4 mr-2" /> {{ $t('components.logout') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>
<style scoped>
  .dark .lang .form-check-label{
    border-color: #6c869a;
  }
  .dark .lang:hover input ~ .form-check-label {
    background-color: rgba(108, 134, 154, 0.5);
  }

  /* When the checkbox is checked, add a blue background */
  .dark .lang input:checked ~ .form-check-label {
    background-color: #6c869a;
    color: white;
  }
</style>
<script>
import { defineComponent, ref } from 'vue'
import Toastify from 'toastify-js'

import { setHeader } from "/src/services/api.service";
import UserService from "/src/services/user.service";
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    const searchDropdown = ref(false)

    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown
    }
  },
  created(){
    var t = localStorage.getItem("token")
    if(!t){
      window.location.href  = "/"
    }
  },
  data() {
    return {
      errorNotifyMessage : "",
      errorNotifyLoading: false,
      errorNotifyErrorCount: 0,
      breadcrumbs : [],
      breadcrumbs_list : {
        "lab-analaysis-list" : [
          { title: 'components.breadcrumbs.laboratory_analysis', link: "/lab-analysis-list"},
          {title : 'components.breadcrumbs.indicators', link : ""}
        ],
        "lab-analaysis-edit" : [
          {title : 'components.breadcrumbs.laboratory_analysis', link: "/lab-analysis-list"},
          {title : 'components.breadcrumbs.edit', link : ""}
        ],
        "food-list" : [
          { title: 'components.breadcrumbs.foods', link: "/food-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "food-list-by-user" : [
          { title: 'components.breadcrumbs.foods', link: "/food-list-by-user"},
          { title : 'components.breadcrumbs.list_by_user', link : ""}
        ],
        "food-edit" : [
          { title: 'components.breadcrumbs.foods', link: "/food-list"},
          {title : 'components.breadcrumbs.edit', link : ""}
        ],
        "food-new" : [
          { title: 'components.breadcrumbs.foods', link: "/food-list"},
          {title : 'components.breadcrumbs.new_food', link : ""}
        ],
        "supplement-list" : [
          { title: 'components.breadcrumbs.supplements', link: "/supplement-list"},
          {title : 'components.breadcrumbs.list', link : ""}
        ],
        "supplement-new" : [
          { title: 'components.breadcrumbs.supplements', link: "/supplement-list"},
          {title : 'components.breadcrumbs.new', link : ""}
        ],
        "supplement-edit" : [
          { title: 'components.breadcrumbs.supplements', link: "/supplement-list"},
          { title : 'components.breadcrumbs.edit', link : ""}
        ],
        "sessions-list":[
          { title: 'components.breadcrumbs.sessions', link: "/sessions-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "recommendation-comparison":[
          { title: 'components.breadcrumbs.sessions', link: "/sessions-list"},
          { title : 'components.breadcrumbs.comparison_of_recommendations',link : ""}
        ],
        "receipes-list":[
          { title: 'components.breadcrumbs.personalized_recipes', link: "/receipes-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "receipes-list-by-user":[
          { title: 'components.breadcrumbs.personalized_recipes', link: "/receipes-list-by-user"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "receipe-new":[
          { title: 'components.breadcrumbs.personalized_recipes', link: "/receipes-list"},
          { title : 'components.breadcrumbs.new_recipe', link : ""}
        ],
        "receipe-edit":[
          { title: 'components.breadcrumbs.personalized_recipes', link: "/receipes-list"},
          { title : 'components.breadcrumbs.edit', link : ""}
        ],
        "activities-list":[
          { title: 'components.breadcrumbs.activities', link: "/activities-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "activity-new":[
          { title: 'components.breadcrumbs.activities', link: "/activities-list"},
          { title : 'components.breadcrumbs.new_activity', link : ""}
        ],
        "activity-edit":[
          { title: 'components.breadcrumbs.activities', link: "/activities-list"},
          { title : 'components.breadcrumbs.edit', link : ""}
        ],
        "medication-list":[
          { title: 'components.breadcrumbs.medications', link: "/medication-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "medication-new":[
          { title: 'components.breadcrumbs.medications', link: "/medication-list"},
          { title : 'components.breadcrumbs.medication_new', link : ""}
        ],
        "medication-edit":[
          { title: 'components.breadcrumbs.medications', link: "/medication-list"},
          { title : 'components.breadcrumbs.edit', link : ""}
        ],

        "medication-family-list":[
          { title: 'components.breadcrumbs.medication_family', link: "/medication-family-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "medication-family-new":[
          { title: 'components.breadcrumbs.medication_family', link: "/medication-family-list"},
          { title : 'components.breadcrumbs.medication_family_new', link : ""}
        ],
        "medication-family-edit":[
          { title: 'components.breadcrumbs.medication_family', link: "/medication-family-list"},
          { title : 'components.breadcrumbs.edit', link : ""}
        ],

        "disease-list":[
          { title: 'components.breadcrumbs.diseases', link: "/disease-list"},
          { title : 'components.breadcrumbs.list', link : ""}
        ],
        "disease-new":[
          { title: 'components.breadcrumbs.diseases', link: "/disease-list"},
          { title : 'components.breadcrumbs.disease_new', link : ""}
        ],
        "disease-edit":[
          { title: 'components.breadcrumbs.diseases', link: "/disease-list"},
          { title : 'components.breadcrumbs.edit', link : ""}
        ],
        "ai-input":[
          { title: 'components.breadcrumbs.ai', link: ""},
        ],
        "user-results":[
          { title: 'components.breadcrumbs.sessions', link: "/sessions-list"},
          { title: 'components.breadcrumbs.result_users', link : ""}
        ],
        "home":[
          { title: 'components.breadcrumbs.home', link: ""},

        ],
      },
      user : {
        "session_name": null,
        "gender": null,
        "lang": null,
        "job" : null
      },
      langs : this.$global.getLangs(),
      activeLang : (localStorage.lang) ? localStorage.lang : (navigator.language).split("-")[0]
    }
  },
  watch: {
    $route(to, from) {
      var route_name = to.name
      this.breadcrumbs = (this.breadcrumbs_list[route_name]) ? this.breadcrumbs_list[route_name] : this.breadcrumbs_list["lab-analaysis-list"]
    },
    activeLang : function() {
      this.setLang()
    }
  },
  mounted(){
    var user = localStorage.getItem("user")
    if(!user){
      this.getUser()
    } else {
      this.user = JSON.parse(user)
    }

    var route_name = this.$route.name
    this.breadcrumbs = (this.breadcrumbs_list[route_name]) ? this.breadcrumbs_list[route_name] : this.breadcrumbs_list["lab-analaysis-list"]
    if(!localStorage.lang){
      this.setLang()
    }
  },
  methods: {
    setLang(){
      var l = this.activeLang
      localStorage.setItem('lang', l);
      this.$i18n.locale = l
      setHeader.lang()
    },
    saveError() {
      Toastify({
        node: cash('#save-error')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    saveSuccess() {
      Toastify({
        node: cash('#save-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    saveErrorNotify(){
      this.errorNotifyErrorCount = 0
      UserService.notifyError({
        description: this.errorNotifyMessage,
        extra_info: JSON.stringify({url: window.location.href})
      }).then((serverResponse) => {
        this.saveSuccess()
        this.errorNotifyMessage = ""
      }).catch((error) => {
        this.errorNotifyErrorCount++
        this.saveError()
        setTimeout(()=>{
          if (this.errorNotifyErrorCount < 6) {
            this.saveErrorNotify()
          } else {
            this.errorNotifyErrorCount = 0
          }
        }, 4000)
      })

    },
    getUser(){
      UserService.getProfile().then((response) => {
        if(response && response.user){
          localStorage.user = JSON.stringify(response.user)
          this.user = response.user
        }
      })
    },
    logout(){
      UserService.logout().then((response) => {
          //this.$router.push({ path: '/' });
          window.location = process.env.VUE_APP_CLIENT_URL
      })
    },
    changeLang(l){
      this.langs.map(lang => lang.active = false)
    }
  }
})
</script>
