<template>
  <AlertMessage id_alert="activityEdit" ref="alertMessage" class="dark myAuto" v-bind:message="'<p>Alerta</p>'" />
  <div>
    <div id="save-activity-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.activities.text9') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="remove-activity-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.activities.text10') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.activities.text11') }}: {{ activity.healthy_activity_langs[global_lang].value }}
      </h2>
    </div>

    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
          <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__' + lang.key)"
            :data-target="'#lang__' + lang.key" href="javascript:;"
            class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md"
            :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''" :id="'lang__' + lang.key + '-tab'" role="tab"
            :aria-controls="'lang__' + lang.key" aria-selected="false"> {{ $t('components.langs.' + lang.key + '.name') }} </a>
        </div>
        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__' + lang.key" class="tab-content">
          <div :id="'lang__' + lang.key" class="tab-pane " :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''"
            role="tabpanel" :aria-labelledby="'lang__' + lang.key + '-tab'">
            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5 rounded-tl-none rounded-b-none">
              <div :id="'lang-accordion-' + lang.key" class="accordion">
                <div class="accordion-item">
                  <div :id="'lang-accordion-content-' + lang.key" class="accordion-header">
                    <label :for="'crud-form-1-' + lang.key" class="form-label">
                      {{ $t('views.activities.text13') }}*
                    </label>
                    <div class="input-group">
                      <input :id="'crud-form-1-' + lang.key" :class="validations[lang.key].name && 'border border-theme-6'"
                        type="text" name="chart-tooltip" class="form-control w-full bg-theme-32"
                        :placeholder="$t('views.activities.text13')"
                        v-model="activity.healthy_activity_langs[lang.key].value" />
                    </div>
                    <div v-if="validations[lang.key].name" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>

                    <label :for="'crud-form-1-' + lang.key" class="form-label mt-4">
                      {{ $t("views.activities.text8") }}
                    </label>
                    <div class="input-group">
                      <textarea :id="'crud-form-1-' + lang.key" :class="validations[lang.key].description && 'border border-theme-6'"
                        type="text" name="chart-tooltip" class="form-control w-full bg-theme-32"
                        :placeholder="$t('views.activities.text8')"
                        v-model="activity.healthy_activity_langs[lang.key].description"></textarea>
                    </div>
                    <div v-if="validations[lang.key].description" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>


                    <label :for="'crud-form-1-' + lang.key" class="form-label mt-4">
                      {{ $t("views.activities.text13") }}
                    </label>
                    <div class="input-group">
                      <textarea :id="'crud-form-1-' + lang.key" :class="validations[lang.key].goal && 'border border-theme-6'"
                        type="text" name="chart-tooltip" class="form-control w-full bg-theme-32"
                        :placeholder="$t('views.activities.text13')"
                        v-model="activity.healthy_activity_langs[lang.key].goal"></textarea>
                    </div>
                    <div v-if="validations[lang.key].goal" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>


                    <label :for="'crud-form-1-' + lang.key" class="form-label mt-4">
                      {{ $t("views.activities.text17") }}
                    </label>
                    <div class="input-group">
                      <textarea :id="'crud-form-1-' + lang.key" :class="validations[lang.key].benefits && 'border border-theme-6'"
                        type="text" name="chart-tooltip" class="form-control w-full bg-theme-32"
                        :placeholder="$t('views.activities.text17')"
                        v-model="activity.healthy_activity_langs[lang.key].benefits"></textarea>
                    </div>
                    <div v-if="validations[lang.key].benefits" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>


                    <label :for="'crud-form-1-' + lang.key" class="form-label mt-4">
                      {{ $t("views.activities.text18") }}
                    </label>
                    <div class="input-group">
                      <textarea :id="'crud-form-1-' + lang.key" :class="validations[lang.key].keep_in_mind && 'border border-theme-6'"
                        type="text" name="chart-tooltip" class="form-control w-full bg-theme-32"
                        :placeholder="$t('views.activities.text18')"
                        v-model="activity.healthy_activity_langs[lang.key].keep_in_mind"></textarea>
                    </div>
                    <div v-if="validations[lang.key].keep_in_mind" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- END: Form Layout -->
          </div>
        </div>

        <div class="box p-5 rounded-t-none pt-0 pb-8">
          <label for="energy_value_input" class="form-label mt-6">
                    {{ $t("views.activities.text19") }}*
                    </label>
                    <div class="mt-2 gap-4 gap-x-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                        <div class="form-check mr-2"><input id="checkbox-switch-4" class="form-check-input" :class="activity.energy_level === 1 ? 'checked' : ''"  type="radio" name="energy_level" :value="'1'" v-model="activity.energy_level"> <label class="form-check-label" for="checkbox-switch-4">{{ $t('views.energy.1') }}</label> </div>
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-5" class="form-check-input" :class="activity.energy_level === 2 ? 'checked' : ''"  type="radio" name="energy_level" :value="'2'" v-model="activity.energy_level"> <label class="form-check-label" for="checkbox-switch-5">{{ $t('views.energy.2') }}</label> </div>
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-6" class="form-check-input" :class="activity.energy_level === 3 ? 'checked' : ''"  type="radio" name="energy_level" :value="'3'" v-model="activity.energy_level"> <label class="form-check-label" for="checkbox-switch-6">{{ $t('views.energy.3') }}</label> </div>
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-7" class="form-check-input" :class="activity.energy_level === 4 ? 'checked' : ''"  type="radio" name="energy_level" :value="'4'" v-model="activity.energy_level"> <label class="form-check-label" for="checkbox-switch-7">{{ $t('views.energy.4') }}</label> </div>
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-8" class="form-check-input" :class="activity.energy_level === 5 ? 'checked' : ''"  type="radio" name="energy_level" :value="'5'" v-model="activity.energy_level"> <label class="form-check-label" for="checkbox-switch-8">{{ $t('views.energy.5') }}</label> </div>
                    </div>

                    <label for="moods_input" class="form-label mt-6">
                      {{ $t('views.activities.text7') }}*
                    </label>
                    <div class="mb-4">
                      <input
                        type="text" name="chart-tooltip" class="form-control bg-theme-32 w-1/2 lg:w-1/3"
                        :placeholder="$t('views.activities.text20')"
                        v-on:keyup="searchMoods" />
                    </div>
                    <div class="mt-2 gap-4 gap-x-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:gap-x-6 xxl:grid-cols-6">
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="all-moods" class="form-check-input" type="checkbox" v-model="all_moods" :value="true" v-on:change="checkAllMoods"> <label class="form-check-label" for="all-moods">{{ $t('views.all') }}</label> </div>
                        <template v-for="(mood, key) in moods">
                          <div class="form-check mr-2 mt-2 sm:mt-0" v-if="mood.show">
                            <input :id="'checkbox-switch'+mood.id" class="form-check-input" type="checkbox" v-model="activity.healthy_activity_moods" :value="mood.id">
                            <label class="form-check-label" :for="'checkbox-switch'+mood.id">{{ mood.value }}</label>
                          </div>
                        </template>
                    </div>
                    <div v-if="validations.healthy_activity_moods" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>

                    <div class="grid 2xl:grid-cols-2 gap-6">
                      <div class="pr-12">
                        <label for="time_input" class="form-label mt-6">
                          {{ $t('views.activities.text21') }}*
                        </label>
                        <div class="mt-4 mb-10">
                          <div id="slider-time"></div>
                        </div>
                      </div>
                      <div class="pr-6">
                        <label for="temperature_input" class="form-label mt-6">
                          {{ $t('views.activities.text22') }}*
                        </label>
                        <div class="mt-4 mb-10">
                          <div id="slider-temperature"></div>
                        </div>
                      </div>
                    </div>

                    <label for="moment_input" class="form-label mt-6">
                      {{ $t('views.activities.text23') }}*
                    </label>
                    <div class="mt-2 gap-4 gap-x-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:gap-x-6 xxl:grid-cols-6">
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-ALL" class="form-check-input" type="checkbox" v-model="all_moments" :value="true" v-on:change="checkAllMoment"> <label class="form-check-label" for="checkbox-switch-ALL">{{ $t('views.all') }}</label> </div>
                        <div v-for="(moment, key) in _moments" class="form-check mr-2 mt-2 sm:mt-0">
                          <input :id="'checkbox-switch-'+moment" class="form-check-input" type="checkbox" :value="moment" v-model="activity.healthy_activity_moments">
                          <label class="form-check-label" :for="'checkbox-switch-'+moment">{{ $t('views.moments.'+moment) }}</label>
                        </div>
                    </div>
                    <div v-if="validations.healthy_activity_moments" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>


                    <label for="weathers_input" class="form-label mt-6">
                      {{ $t('views.activities.text24') }}*
                    </label>
                    <div class="mt-2 gap-4 gap-x-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:gap-x-6 xxl:grid-cols-6">
                        <div class="form-check mr-2 mt-2 sm:mt-0"> <input id="checkbox-switch-weathers-all" class="form-check-input" type="checkbox" v-model="all_weathers" :value="true" v-on:change="checkAllWeathers"> <label class="form-check-label" for="checkbox-switch-weathers-all">{{ $t('views.all') }}</label> </div>
                        <div v-for="(weather, key) in _weathers" class="form-check mr-2 mt-2 sm:mt-0">
                          <input :id="'checkbox-switch-'+weather" class="form-check-input" type="checkbox" :value="weather" v-model="activity.healthy_activity_weathers">
                          <label class="form-check-label" :for="'checkbox-switch-'+weather">{{ $t('views.weathers.'+weather) }}</label>
                        </div>
                    </div>
                    <div v-if="validations.healthy_activity_weathers" class="text-theme-6 mt-2">
                      {{ $t("views.activities.text14") }}
                    </div>

                    <label for="participants_input" class="form-label mt-6">
                      {{ $t("views.activities.text25") }}*
                    </label>
                    <div class="mt-4 mb-10 pr-6">
                      <div id="slider-participants"></div>
                    </div>
        </div>
        <div class="text-right mt-5">
                <button
                  v-on:click="confirmRemove"
                  type="button"
                  class="btn btn-outline-dark w-24 mr-5"
                >
                  <LoadingIcon v-if="removeLoading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.delete') }}</span>
                </button>
                <button
                  v-on:click="saveActivity"
                  type="button"
                  class="btn btn-primary w-24"
                >
                  <LoadingIcon v-if="loading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.save') }}</span>
                </button>
            </div>
      </div>
    </div>
  </div>
</template>

<style>
.dark .form-check-input[type=checkbox] {
    background-color: #182033 !important;
    border-color: rgba(255, 255, 255, 0.5) !important;
}

.form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: white;
}

.form-check-input.checked[type=radio]{
  border-color: #6c869a !important;
}

.form-check-input[type=radio]:before {
    background-color: #6c869a !important;
}

.noUi-horizontal{
  height: 0.75rem;
}

.noUi-target{
  background-color: #84d9a50a !important;
  border-radius: 20px;
  box-shadow: none;
  border: none;
}

.noUi-connect{
  background-color: #6c869a !important;
}

.noUi-horizontal div.noUi-handle{
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  box-shadow: none;
  background-color: #a0aec0;
}
.noUi-handle:before, .noUi-handle:after{
  display: none;
}

.noUi-horizontal .noUi-tooltip{
  background-color: #141a2a;
  border: none;
  color: #6c869a;
  bottom: -150%;
}
</style>


<script>
import ActivitiesService from "/src/services/activities.service";
import Toastify from "toastify-js";
import AlertMessage from "@/components/alert/Main.vue"
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default {
  components: {
    AlertMessage
  },
  setup() {
  },
  data() {
    return {
      validations: {
        es : {
          name : false,
          description : false,
          benefits : false,
          keep_in_mind : false,
          goal : false,
        },
        en : {
          name : false,
          description : false,
          benefits : false,
          keep_in_mind : false,
          goal : false,
        },
        healthy_activity_moments : false,
        healthy_activity_moods : false,
        healthy_activity_weathers : false,
      },
      loading: false,
      removeLoading: false,
      activity: {
        "name": null,
        "energy_level": 3,
        "min_time":30,
        "max_time":240,
        "min_temperature":0,
        "max_temperature":35,
        "min_participants":4,
        "max_participants":10,
        "exercise_id": null,
        "healthy_activity_moments":[],
        "healthy_activity_moods":[],
        "healthy_activity_weathers":[],
        "healthy_activity_langs":{
            "en":{
              "value": null,
              "description": null,
              "benefits": null,
              "keep_in_mind": null,
              "goal": null,
              "lang":"en"
            },
            "es":{
              "value": null,
              "description": null,
              "benefits": null,
              "keep_in_mind": null,
              "goal": null,
              "lang":"es"
            }
        }
    },
      activeLang: 'lang__' + (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs: this.$global.getLangs(),
      lang_key: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      global_lang: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      getData: true,
      all_moments : false,
      all_weathers : false,
      moods: [],
      all_moods : false,
      value : 20,
      _weathers: this.$global.getWeathers(),
      _moments: this.$global.getMoments(),
    };
  },
  watch: {
    '$i18n.locale': function (l) {
      this.getActivity();
      this.getAllMoods();
    },
    activeLang: function () {
      this.lang_key = this.activeLang.replace("lang__", "")
    }
  },
  created() {
    this.getActivity();
    this.getAllMoods();

  },
  mounted() {
    var timeActivity = document.getElementById('slider-time');
    noUiSlider.create(timeActivity, {
        start: [this.activity.min_time, this.activity.max_time],
        connect: true,
        range: {
            'min': 0,
            'max': 1440
        },
        step: 5,
        tooltips: true,
        tooltips: {
          to: function (value) {
            function formatHours(tMin) {
              function padTo2Digits(num) {
                return num.toString().padStart(2, '0');
              }
              const minutes =  Math.floor(tMin % 60);
              const hours = Math.floor(tMin / 60);
              return hours === 0 ? `${padTo2Digits(minutes)} min` : minutes === 0 ? `${padTo2Digits(hours)} horas` : `${padTo2Digits(hours)}:${padTo2Digits(minutes)} min`;
            }
            const time = formatHours(Math.ceil(value))
            return (time);
          },
          from: function (value) {
              return value; 
          }
      }
    });
    timeActivity.noUiSlider.on('update', function (values) {
      if(values.length > 0)
        this.activity.min_time = parseInt(values[0])
      if(values.length > 1)
        this.activity.max_time = parseInt(values[1])
    }.bind(this));

    var tempActivity = document.getElementById('slider-temperature');
    noUiSlider.create(tempActivity, {
        start: [this.activity.min_temperature, this.activity.max_temperature],
        connect: true,
        range: {
            'min': -90,
            'max': 60
        },
        step: 1,
        tooltips: true,
        tooltips: {
          to: function (value) {
            return `${Math.floor(value)}°C`
          },
          from: function (value) {
            return `${Math.floor(value)}°C`
          }
      }
    });
    tempActivity.noUiSlider.on('update', function (values) {
      if(values.length > 0)
        this.activity.min_temperature = parseInt(values[0])
      if(values.length > 1)
        this.activity.max_temperature = parseInt(values[1])
    }.bind(this));

    var peopleActivity = document.getElementById('slider-participants');
    noUiSlider.create(peopleActivity, {
        start: [this.activity.min_participants, this.activity.max_participants],
        connect: true,
        range: {
            'min': 1,
            'max': 30
        },
        step: 1,
        tooltips: true,
        tooltips: {
          to: function (value) {
            return `${Math.ceil(value)} ${value > 1 ? 'personas' : 'persona'}`
          },
          from: function (value) {
            return `${Math.ceil(value)} ${value > 1 ? 'personas' : 'persona'}`
          }
      }
    });
    peopleActivity.noUiSlider.on('update', function (values) {
      if(values.length > 0)
        this.activity.min_participants = parseInt(values[0])
      if(values.length > 1)
        this.activity.max_participants = parseInt(values[1])
    }.bind(this));
  },
  methods: {
    getActivity: async function () {
      this.activity = (localStorage.activityItem) ? JSON.parse(localStorage.activityItem) : this.activity
      const a_langs = []
      for (const l in this.activity.healthy_activity_langs) {
        const a_lang = this.activity.healthy_activity_langs[l]
        a_langs[a_lang.lang] = a_lang
      }
      this.activity.healthy_activity_langs = a_langs
      this.activity.healthy_activity_weathers = this.activity.healthy_activity_weathers.map(w => w.weather)
      this.all_weathers = this.activity.healthy_activity_weathers.length === 9
      this.activity.healthy_activity_moments = this.activity.healthy_activity_moments.map(w => w.moment)
      this.all_moments = this.activity.healthy_activity_moments.length === 5
      this.activity.healthy_activity_moods = this.activity.healthy_activity_moods.map(w => w.mood_id)
    },
    getAllMoods(){
      ActivitiesService.getAllMoods(this.lang_key).then((response)=>{
        this.moods = (response.data) ? response.data : response
        this.moods = this.moods.map(m => {
          m.show = true
          return m
        })
      })
    },
    saveSuccessActivity() {
      Toastify({
        node: cash('#save-activity-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('activityItem');
      this.$router.push({ path: '/activities-list' });
    },
    saveActivity: async function () {
     
      this.activity.lang_inputs = {...this.activity.healthy_activity_langs}

      for (const l in this.activity.lang_inputs) {
        this.activity.lang_inputs[l] = {"langs" : [this.activity.lang_inputs[l]]}
      }

      if (this.validation())
      return

      const activity = {
        "id": this.activity.id,
        "name": this.activity.healthy_activity_langs.es.value || "",
        "energy_level": this.activity.energy_level,
        "min_time": this.activity.min_time,
        "max_time": this.activity.max_time,
        "min_temperature": this.activity.min_temperature,
        "max_temperature": this.activity.max_temperature,
        "min_participants": this.activity.min_participants,
        "max_participants": this.activity.max_participants,
        "exercise_id": this.activity.exercise_id,
        "healthy_activity_moments": this.activity.healthy_activity_moments,
        "healthy_activity_moods": this.activity.healthy_activity_moods,
        "healthy_activity_weathers": this.activity.healthy_activity_weathers,
        "lang_inputs": this.activity.lang_inputs
      }

      this.loading = true
      var serverResponse = await ActivitiesService.save(activity)
      this.loading = false
      if(serverResponse && serverResponse.data && serverResponse.data.length > 0 && !this.activity.id){
          this.activity.id = serverResponse.data[0].id
      }
      this.saveSuccessActivity()
    },
    confirmRemove() {
      var message = "<p class='text-base px-4'>" + this.$t('views.activities.text16') + "</p>"
      this.$refs.alertMessage.show(message, true, true, this.removeDrug)
    },
    removeDrug() {
      this.removeLoading = true
      this.$refs.alertMessage.hide()
      ActivitiesService.remove(this.activity.id).then(function(response){
        this.removeLoading = false
        this.removeSuccessActivity()
      }.bind(this)).catch((error)=>{
        this.removeLoading = false
      })
    },
    removeSuccessActivity() {
      Toastify({
        node: cash('#remove-activity-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('activityItem');
      this.$router.push({ path: '/activities-list' });
    },
    changeLang(l) {
      this.activeLang = l
    },
    checkAllMoment(ev) {
      if(ev.target.checked)
        this.activity.healthy_activity_moments = this._moments
      else
        this.activity.healthy_activity_moments = []
    },
    checkAllWeathers(ev){
      if(ev.target.checked)
        this.activity.healthy_activity_weathers = this._weathers
      else
        this.activity.healthy_activity_weathers = []
    },
    checkAllMoods(ev){
      if(ev.target.checked){
        this.activity.healthy_activity_moods = this.moods.map(w => w.id)
      }else{
        this.activity.healthy_activity_moods = []
      }
    },
    searchMoods(ev){
      this.moods.map(m => {
        m.show = m.value.toLowerCase().includes(ev.target.value.toLowerCase())
        return m
      })
    },
    validation(){
      let validate = false
      if(!this.activity.healthy_activity_langs.es.value){
        validate = true
        this.validations.es.name = true
      }
      if(!this.activity.healthy_activity_langs.es.description){
        validate = true
        this.validations.es.description = true
      }
      if(!this.activity.healthy_activity_langs.es.benefits){
        validate = true
        this.validations.es.benefits = true
      }
      if(!this.activity.healthy_activity_langs.es.keep_in_mind){
        validate = true
        this.validations.es.keep_in_mind = true
      }
      if(!this.activity.healthy_activity_langs.es.goal){
        validate = true
        this.validations.es.goal = true
      }
      if(!this.activity.healthy_activity_moments.length > 0){
        validate = true
        this.validations.healthy_activity_moments = true
      }
      if(!this.activity.healthy_activity_moods.length > 0){
        validate = true
        this.validations.healthy_activity_moods = true
      }
      if(!this.activity.healthy_activity_weathers.length > 0){
        validate = true
        this.validations.healthy_activity_weathers = true
      }

      return validate
    }
  },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>