import ApiService from "./api.service"

import axios from "axios"

const ReceipeService = {
  getAll: async function(params) {
    const response = await ApiService._get("/api/admin/food_recipe", params)
    return response.data
  },

  get: async function(id, lang = null) {
    const response = await ApiService.get("/api/admin/food_recipe/" + id, lang)
    return response.data
  },

  save: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/food_recipe/save",
      params,
      lang
    )
    return response.data
  },

  upload: async function(params) {
    const response = await ApiService.post(
      "/api/admin/food_receipe/upload-gallery",
      params
    )
    return response.data
  },

  getUnits: async function(lang = null) {
    const response = await ApiService.get("/api/admin/food_recipe/units", lang)
    return response.data
  },

  remove: async function(id) {
    const response = await ApiService.delete(
      "/api/admin/food_recipe/remove/" + id
    )
    return response.data
  },

  getAllBenefits: async function(params) {
    const response = await ApiService._get(
      "/api/admin/food_recipe/benefits/get-all",
      params
    )
    return response.data
  },

  getIG: async function(params, lang = null) {
    const response = await ApiService.post(
      "/api/admin/food_recipe/calculate-recipe-ig-group",
      params,
      lang
    )
    return response.data
  },

  deleteGalleryPhoto: async function(photoId) {
    const response = await ApiService._get(
      "/api/admin/food_recipe/delete-gallery-photo",
      { id: photoId }
    )
    return response.data
  }
}

export default ReceipeService
