<template>
  <select v-select="{ props, emit, computedOptions }" class="tom-select">
    <template v-if="computedOptions.options">
      <option v-for="option in computedOptions.options" :value="option.id" :key="option.id">
          {{ option.name }}
      </option>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </select>
</template>

<script>
import { defineComponent, computed, watch } from 'vue'
import { setValue, init, reInit } from './index'

export default defineComponent({
  directives: {
    select: {
      mounted(el, { value }) {

        // Clone the select element to prevent tom select remove the original element
        const clonedEl = cash(el)
          .clone()
          .insertAfter(el)[0]
        cash(el).attr('hidden', true)

        // Initialize tom select
        setValue(clonedEl, value.props)
        init(clonedEl, value.emit, value.computedOptions)
      },
      updated(el, { value }) {
        const clonedEl = cash(el).next()[0]
        setValue(clonedEl, value.props)
        reInit(clonedEl, value.props, value.emit, value.computedOptions)
      }
    }
  },
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    modelValue: {
      type: [String, Number, Array],
      default: ''
    }
  },
  setup(props, context) {
    // Compute all default options
    const computedOptions = computed(() => {
      var plugins = {
          ...props.options.plugins
      }

      if(context.attrs && context.attrs.plugins && context.attrs.plugins.length > 0){
          context.attrs.plugins.forEach(element => {
            plugins[element] = {}
          });
      }else{
        plugins.dropdown_input = {}
      }

      let options = {
        ...props.options,
        plugins: plugins,
        create: (context.attrs && context.attrs.create) ? context.attrs.create : false,
        controlInput : (context.attrs && context.attrs.input_hide == true) ? "<input class='my-custom-input' value='' style='display : none !important'/>" : null,
        maxOptions: 1000,
      }

      if(context.attrs.flag){
        options.render = {
          option: function(data, escape) {
            if(!data.flag)
              return '<div class="flex items-center">' + data.text + '</div>';
            return '<div class="flex items-center">' +
                '<img src="' + data.flag + '" class="w-5 w-5" /> <span class="ml-5">'+data.text+'</span>' +
              '</div>';
          },
          item: function(data, escape) {
            if(!data.flag)
              return '<div class="flex items-center ">' + data.text + '</div>';
            return '<div class="flex item items-center justify-center w-full">' + '<img src="' + data.flag + '" class="w-8 w-8 m-auto my-flag" />' + '</div>';
          }
        }
      }

      if(context.attrs.category){
        options.render = {
          item: function(data, escape) {
            if(data.main)
              return '<div class="flex items-center">' + data.main + '</div>';
            else if (data.cat)
              return '<div class="flex items-center ">' + data.cat + " " + data.text + '</div>';
            else
              return '<div class="flex items-center ">' + data.text + '</div>';
            
          },
          option: function(data, escape) {
            if(data.main)
              return '<div class="flex items-center">' + data.main + '</div>';
            return '<div class="flex items-center pl-4">' + data.text + '</div>';
          }
        }
      }

      if (Array.isArray(props.modelValue)) {
        options = {
          persist: false,
          maxItems : (context.attrs && context.attrs.max) ? context.attrs.max : null,
          controlInput : null,
          onDelete: function(values) {
            // return confirm(
            //   values.length > 1
            //     ? 'Are you sure you want to remove these ' +
            //         values.length +
            //         ' items?'
            //     : 'Are you sure you want to remove "' + values[0] + '"?'
            // )
          },
          ...options,
          plugins: {
            remove_button: {
              title: 'Remove this item'
            },
            ...options.plugins
          }
        }
      }

      return options
    })

    // Watch value change
    watch(
      computed(() => props.modelValue),
      () => {
        context.emit('change')
      }
    )

    return {
      props,
      ...context,
      computedOptions
    }
  }
})
</script>
