<template>
    <div>
        <div class="flex items-baseline justify-between">
            <h2 class="intro-y text-lg font-medium mt-10">{{ $t("views.food.text1") }}</h2>
        </div>
        <div class="grid grid-cols-12 gap-6 mb-12">
            <div class="intro-y col-span-12 flex flex-wrap items-center mt-2 gap-3">
                <p class="mr-auto order-1">{{ $t("views.food.text3") }}</p>
                <div class="w-full sm:w-1/3 mt-3 sm:mt-0 sm:ml-auto md:ml-0 order-2 ">
                    <div class="w-full relative text-gray-700 dark:text-gray-300">
                        <input type="text" class="form-control w-full box pr-10 placeholder-theme-13"
                            :placeholder="$t('views.food.text23')" v-model="searchInput" />
                        <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
                    </div>
                </div>
                <button v-on:click="filterClean" type="button" class="btn btn-custom-gray-1 w-24 order-3">
                    {{ $t('views.clean') }}
                </button>
            </div>
            <div class="grid grid-cols-7 gap-6 w-full col-span-12 justify-between">
                <div class="grid grid-cols-3 col-span-7 xl:col-span-5 gap-6 order-2 xl:order-1">
                    <div class="cursor w-full mt-3 sm:mt-0 col-span-3 sm:col-span-1">
                        <h3 class="intro-y text-base font-medium mb-2">Usuario</h3>
                        <div class="w-full h-12 xl:h-12 relative text-gray-700 dark:text-gray-300 categories-filter">
                            <TomSelect class="w-full h-full cursor " v-model="user">
                                <option :value="0">{{ "Seleccione" }}</option>
                                <option v-for="(user, key) in users" :key="key" :value="user.id" :label="user.session_name">
                                    <span>{{ user.session_name }}</span>
                                </option>
                            </TomSelect>
                        </div>
                    </div>
                    <div class="col-span-3 sm:col-span-2">
                        <h3 class="intro-y text-base font-medium mb-2">Rango de fechas</h3>
                        <div class="flex gap-6 h-12 xl:h-12">
                            <div class="cursor w-full h-full mt-3 sm:mt-0">
                                <div class="w-full h-full relative text-gray-700 dark:text-gray-300">
                                    <Litepicker id="date_start" :options="{ format: 'DD-MM-YYYY', noDefault: true }" v-model="date_start"
                                        class="datepicker form-control rounded w-full h-full block"
                                        :placeholder="`Desde ${today}`"></Litepicker>
                                </div>
                            </div>
                            <div class="cursor w-full h-full mt-3 sm:mt-0">
                                <div v-if="startDate !== false" class="w-full h-full relative text-gray-700 dark:text-gray-300">
                                    <LitepickerEnd id="date_end" :options="{ format: 'DD-MM-YYYY', noDefault: true, minDate: startDate }" v-model="date_end"
                                        class="datepicker form-control rounded w-full h-full block"
                                        :placeholder="`Hasta ${today}`"></LitepickerEnd>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="flex gap-8 w-full col-span-7 xl:col-span-2 justify-start sm:justify-end order-1 xl:order-2">
                    <div class="flex flex-col gap-2 justify-center items-center">
                        <h3 class="intro-y text-base font-medium">Total cargados</h3>
                        <span class="flex justify-center items-center rounded-full bg-custom-gray-9 w-16 h-16">{{ total_charge
                        }}</span>
                    </div>
                    <div class="flex flex-col gap-2 justify-center items-center">
                        <h3 class="intro-y text-base font-medium">% Nutrientes</h3>
                        <span class="flex justify-center items-center rounded-full bg-custom-gray-9 w-16 h-16">{{ percent
                        }}%</span>
                    </div>
                </div>
            </div>
            <!-- BEGIN: Data List -->
            <div class="intro-y col-span-12 overflow-hidden" :class="{ 'overflow-x-auto': !loading }">
                <table class="table table-report -mt-2">
                    <thead>
                        <tr>
                            <th class="whitespace-nowrap">{{ $t("views.food.text5") }}</th>
                            <th class="whitespace-nowrap">{{ 'Fecha' }}</th>
                            <th class="whitespace-nowrap">{{ 'Cant. Nutrientes' }}</th>
                            <th class="text-center whitespace-nowrap">{{ 'Referencias' }}</th>
                        </tr>
                    </thead>
                    <tbody id="food-accordion-1" class="accordion">
                        <template v-for="(item, itemKey) in foods" :key="itemKey">
                            <tr class="intro-x" v-if="!loading">
                                <td class="p-0">
                                    <div class="accordion-item">
                                        <div :id="'food-content-' + item.id" class="accordion-header"
                                            v-bind:class="item.show ? 'show-food' : ''">
                                            <button
                                                class="accordion-button collapsed w-full text-left p-5 flex items-center gap-4"
                                                type="button" data-bs-toggle="collapse"
                                                :data-bs-target="'#food-accordion-collapse-' + item.id"
                                                :aria-expanded="item.show"
                                                :aria-controls="'food-accordion-collapse-' + item.id"
                                                @click="setShow(item)">
                                                <div>
                                                    <div
                                                        class="font-medium whitespace-nowrap truncate description text-gray-800 dark:text-white">
                                                        <span class="rounded-full inline-block mr-2"
                                                            :class="{ 'green-ok': item.categories.includes(5), 'orange-medium': item.categories.includes(6), 'red-danger': item.categories.includes(96) }"
                                                            style="width:7px; height:7px; margin-bottom:1px"></span>
                                                        <Tippy data-theme="light" :content="item.name || item.description">
                                                            {{
                                                                item.name || item.description
                                                            }}
                                                        </Tippy>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div :id="'food-accordion-collapse-' + item.id"
                                            class="accordion-collapse collapse p-5 pt-0"
                                            :aria-labelledby="'food-content-' + item.id" data-bs-parent="#food-accordion-1"
                                            :style="'display : ' + item.show">
                                            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                                                <div class="grid grid-cols-3 gap-x-4 gap-y-1 mt-2">
                                                    <template v-for="(n, nKey) in show_nutritional_content">
                                                        <div class="dark:text-white" :class="getOrder(nKey)" :key="nKey"
                                                            v-if="!hiddenKey.includes(nKey) && item.nutritional_content && item.nutritional_content.hasOwnProperty(nKey) && (item.nutritional_content[nKey] != null && item.nutritional_content[nKey] != 0)">
                                                            <div v-if="item.nutritional_content[nKey]" class="flex">
                                                                <div class="max-w-xs truncate">
                                                                    <Tippy data-theme="light" :content="formatName(nKey)">{{
                                                                        formatName(nKey) }}</Tippy>
                                                                </div>
                                                                :
                                                                <span class="flex min-w-max mx-2"> {{
                                                                    item.nutritional_content[nKey] }} {{ formatUnit(nKey,
        item) }}</span>
                                                                <span
                                                                    v-if="item.nutritional_content_by_percent && item.nutritional_content_by_percent.hasOwnProperty(nKey) && item.nutritional_content_by_percent[nKey]">/</span>
                                                                <span class="flex min-w-max mx-2"
                                                                    v-if="item.nutritional_content_by_percent && item.nutritional_content_by_percent.hasOwnProperty(nKey) && item.nutritional_content_by_percent[nKey]">{{
                                                                        item.nutritional_content_by_percent[nKey] }}
                                                                    %</span>
                                                            </div>
                                                            <div v-else class="flex" style="opacity:35%">
                                                                <div class="max-w-xs truncate">
                                                                    <Tippy data-theme="light" :content="formatName(nKey)">{{
                                                                        formatName(nKey) }}</Tippy>
                                                                </div>
                                                                :
                                                                <span class="flex min-w-max ml-2">
                                                                    {{ item.nutritional_content[nKey] }} {{ formatUnit(nKey,
                                                                        item) }}</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                                <div v-if="getHas(item) === true">{{ $t("views.food.text7") }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ moment(item.created_by_user_at).format('DD/MM/YYYY') }}</td>
                                <td>{{ item.nutrients_count }}/{{ item.nutrients_total }}</td>
                                <td class="table-report__action w-56 relative"
                                    v-bind:class="item.total_nutrition < 9 && item.show ? 'min-height align-end' : ''"
                                    style="width:25px">
                                    <div class="alert alert-secondary font-bold show mb-2 absolute top-5 right-5 text-xs p-3 w-max"
                                        role="alert" v-if="item.show && item.hasNutritional">
                                        {{ `${$t('views.food.text8')} ${item.serving_quantity ? item.serving_quantity :
                                            '100'} ${item.serving_unit_id ? getUnit(item.serving_unit_id) : 'gr'} ` }}
                                        <span v-if="item.nutritional_content.energy_value"> / {{
                                            item.nutritional_content.energy_value }} kcal</span>
                                    </div>
                                    <div class="flex justify-center items-center gap-4">
                                        <a v-if="item.source" class="flex items-center" href="javascript:;"
                                            v-bind:class="item.total_nutrition < 9 && item.show ? 'mb-2' : ''"
                                            @click="goSource(item)">
                                            <LinkIcon class="w-4 h-4 mr-1" />
                                            Link
                                        </a>
                                        <a class="flex items-center mr-3" href="javascript:;"
                                            v-bind:class="item.total_nutrition < 9 && item.show ? 'mb-2' : ''"
                                            @click="editFoodItem(item)">
                                            <CheckSquareIcon class="w-4 h-4 mr-1" />
                                            {{ $t("views.food.text9") }}
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="loading">
                            <td colspan="4" class="p-4">
                                {{ $t("views.loading") }}
                                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
                            </td>
                        </tr>
                        <tr v-if="!loading && foods.length === 0">
                            <td colspan="4" class="p-4">
                                {{ $t("views.no_data") }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- END: Data List -->
            <!-- BEGIN: Pagination -->
            <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center" v-if="foods.length > 0">
                <ul class="pagination">
                    <li>
                        <a class="pagination__link" href="javascript:;" @click="goPage(1)">
                            <ChevronsLeftIcon class="w-4 h-4" />
                        </a>
                    </li>
                    <li>
                        <a class="pagination__link" href="javascript:;" @click="goPage('prev')">
                            <ChevronLeftIcon class="w-4 h-4" />
                        </a>
                    </li>
                    <li>
                        <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('first')"
                            v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('first') }"
                            href="javascript:;" @click="goPage(getNumberPgae('first'))">{{ getNumberPgae("first") }}</a>
                    </li>
                    <li>
                        <a class="pagination__link" v-if="pagination.total_pages >= getNumberPgae('second')"
                            v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('second') }"
                            href="javascript:;" @click="goPage(getNumberPgae('second'))">{{ getNumberPgae("second") }}</a>
                    </li>
                    <li v-if="getNumberPgae('second') != getNumberPgae('') && pagination.total_pages >= getNumberPgae('')">
                        <a class="pagination__link"
                            v-bind:class="{ 'pagination__link--active': pagination.page == getNumberPgae('') }"
                            href="javascript:;" @click="goPage(getNumberPgae(''))">{{ getNumberPgae("") }}</a>
                    </li>
                    <li>
                        <a class="pagination__link" href="javascript:;" @click="goPage('next')">
                            <ChevronRightIcon class="w-4 h-4" />
                        </a>
                    </li>
                    <li>
                        <a class="pagination__link" href="javascript:;" @click="goPage(pagination.total_pages)">
                            <ChevronsRightIcon class="w-4 h-4" />
                        </a>
                    </li>
                </ul>
                <div class="ml-auto mr-5 text-gray-600">
                    {{ $t("views.food.text10", { total: foods.length }) }}
                </div>
                <select class="w-20 form-select box mt-3 sm:mt-0" v-model="pagination.per_page"
                    @change="() => { changePerPage() }">
                    <option>10</option>
                    <option>25</option>
                    <option>35</option>
                    <option>50</option>
                </select>
            </div>
            <!-- END: Pagination -->
        </div>
    </div>
</template>

<style scoped>
.cursor {
    cursor: pointer !important;
}

.table td.p-0 {
    padding: 0px !important;
}

.bg-color-1 {
    background-color: #828181;
}

.truncate.description {
    max-width: 25vw;
}

.show-food .accordion-button {
    display: block;
    width: 95%;
}

.show-food .truncate.description {
    white-space: initial;
    width: 100%;
    display: block;
    max-width: initial;
    margin-bottom: 1rem;
}

.show-food .tag-category {
    display: inline-block;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td.table-report__action.min-height.align-middle {
    vertical-align: middle;
    padding-bottom: 0px;
}



@media (max-width: 1024px) {
    .truncate.description {
        max-width: 50vw;
    }
}

@media (max-width: 600px) {
    .truncate.description {
        max-width: 100vw;
    }
}
</style>

<style>
.tom-select .ts-dropdown .option.active {
    background: #6c869a !important;
}

.top-bar .breadcrumb>a:not(.breadcrumb--active) {
    color: #6c869a !important;
}

.detail-food {
    display: grid;
    grid-auto-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: initial;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.pagination li,
.pagination a,
.pagination a:focus,
.pagination a:active {
    outline: none !important;
}

.btn-primary {
    background: #6c869a !important;
    border-color: #6c869a !important;
}

.categories-filter .tom-select .ts-dropdown .option.pl-4 {
    padding-left: 1.5rem;
}

.green-ok {
    background-color: #33A05D;
}

.orange-medium {
    background-color: #F0AF55
}

.red-danger {
    background-color: #D5453C
}

.ts-dropdown-content,
.dropdown-input-wrap {
    background-color: #232a3b;
}
.litepicker .container__days .day-item.is-locked{
    opacity: 0.5;
}
.litepicker .container__days div.day-item.is-locked:hover{
    background-color: transparent;
}
</style>

<script>
import { computed } from 'vue'
import FoodService from "/src/services/food.service";
import UserService from "/src/services/user.service";
import ReceipeService from "/src/services/receipes.service";
import { useStore } from '@/store'
import _ from 'lodash';
import moment from 'moment';

export default {
    setup() {
    },
    data() {
        return {
            foods: [],
            searchInput: "",
            quizToSearch: 0,
            pagination: {
                page: 1,
                total_pages: 3,
                per_page: 50,
                total: 0
            },
            hiddenKey: ['id', 'id_api', 'food_id', 'updatedAt', 'createdAt', 'deletedAt', 'vitamin_c', 'calculated_energy', 'energy_value', 'niacin_preformed_nicotinic_acid_nicotinamide', 'energy_value_kj', 'energy_unit'],
            loading: true,
            units: [],
            show_nutritional_content: this.$global.getNutritionalContents(),
            users: [],
            user: null,
            date_start: "",
            startDate: null,
            date_end: "",
            total_charge: null,
            percent: null,
            moment: moment,
            today: moment().format('DD-MM-YYYY')
        }
    },
    computed: {
        authError() {
            const store = useStore()
            const authError = computed(() => store.state.main.authError).value
            return authError
        }
    },
    watch: {
        searchInput: _.debounce(function (newSearch, oldSearch) {
            this.pagination.page = 1
            this.getFoodItems()
        }, 500),
        user: function () {
            this.pagination.page = 1
            this.getFoodItems();
        },
        date_start: function () {
            this.startDate = false
            setTimeout(() => {
                this.startDate = (this.date_start !== "") ? `${this.date_start.split("-")[2]}-${this.date_start.split("-")[1]}-${this.date_start.split("-")[0]}` : null
            }, 20);
            this.pagination.page = 1
            this.getFoodItems();
        },
        date_end: function () {
            this.pagination.page = 1
            this.getFoodItems();
        },
        '$i18n.locale': function (l) {
            this.getFoodItems();
            this.getUnist()
        },
        authError(newAuth, oldAuth) {
            if (newAuth === false && oldAuth === true) {
                this.getFoodItems();
            }
        }
    },
    created() {
        window.vue = this
        this.getFoodItems();
        this.getUnist()
        this.getUsers()
    },
    mounted() {
        var sort = Object.keys(this.show_nutritional_content)
            .sort((a, b) => {
                var aa = this.$t('views.lang_nutrition_contents.' + a) ? this.$t('views.lang_nutrition_contents.' + a + '.name') : a
                aa = aa.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                var bb = this.$t('views.lang_nutrition_contents.' + b) ? this.$t('views.lang_nutrition_contents.' + b + '.name') : b
                bb = bb.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if (aa.toLowerCase() < bb.toLowerCase()) {
                    return -1;
                }
                if (bb.toLowerCase() > aa.toLowerCase()) {
                    return 1;
                }
                return 0;

            }).reduce((obj, key) => {
                obj[key] = this.show_nutritional_content[key];
                return obj;
            }, {});
        this.show_nutritional_content = sort
    },
    methods: {
        getFoodItems() {
            this.loading = true
            var params = {
                search: this.searchInput.replaceAll(" ", "_"),
                user_id: (this.user > 0) ? this.user : null,
                date_start: (this.date_start !== "") ? `${this.date_start.split("-")[2]}-${this.date_start.split("-")[1]}-${this.date_start.split("-")[0]}` : null,
                date_end: (this.date_end !== "") ? `${this.date_end.split("-")[2]}-${this.date_end.split("-")[1]}-${this.date_end.split("-")[0]}` : null,
                pagination: this.pagination,
                request_foods_by_user: true
            }

            var _this = this
            FoodService.getFoodItems(params).then((response) => {
                this.loading = false
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (response && response.data) {
                    this.foods = (response.data) ? response.data : response
                    this.pagination.total_pages = (response.pagination.total && response.pagination.per_page) ? Math.ceil(response.pagination.total / response.pagination.per_page) : 0
                    this.pagination.total = (response.pagination.total) ? response.pagination.total : response.pagination.length
                    this.pagination.per_page = (response.pagination.per_page) ? response.pagination.per_page : 50
                    this.total_charge = response.pagination.total;
                    this.percent = response.nutrientsAvgPercentil;

                    this.foods.map(function (f) {
                        f.show = false
                        var has = Object.keys(f.nutritional_content).filter(function (key, index) {
                            return f.nutritional_content[key] !== null && !this.hiddenKey.includes(key);
                        }.bind(this));
                        f.total_nutrition = has.length
                        f.hasNutritional = has.length > 0
                        return f
                    }.bind(this))
                }
            }).catch(function (e) {
                _this.loading = false
            });
        },
        getUnist() {
            ReceipeService.getUnits().then((response) => {
                this.units = (response.data) ? response.data : response
            })
        },
        getUsers() {
            UserService.getAdminUsers().then((response) => {
                this.users = (response.data) ? response.data : response
            })
        },
        editFoodItem(foodItem) {
            localStorage.setItem('returnTo', '-by-user');
            localStorage.setItem('foodItem', JSON.stringify(foodItem));
            this.$router.push({ path: '/food-edit' });
        },
        goSource(food) {
            window.open(food.source)
        },
        formatName(text) {
            return (this.$t('views.lang_nutrition_contents.' + text)) ? this.$t('views.lang_nutrition_contents.' + text + '.name') : text
        },
        formatUnit(text, item) {
            var u = (this.$t('views.lang_nutrition_contents.' + text)) ? this.$t('views.lang_nutrition_contents.' + text + '.unit') : text
            if (u === "%") {
                u = (item.nutritional_content_by_percent && item.nutritional_content_by_percent.hasOwnProperty(text) && item.nutritional_content_by_percent[text]) ? "mg" : u
            }
            return u
        },
        goPage: function (param) {
            if (param == 'prev') {
                this.pagination.page = (this.pagination.page > 1) ? this.pagination.page - 1 : 1
            } else if (param == 'next') {
                this.pagination.page = (this.pagination.page < this.pagination.total_pages) ? this.pagination.page + 1 : this.pagination.total_pages
            } else {
                this.pagination.page = param
            }

            this.getFoodItems();
        },
        getNumberPgae(type) {
            if (type == "first") {
                return (this.pagination.page < 3) ? 1 : this.pagination.page - 1
            } else if (type == "second") {
                return (this.pagination.page > 2) ? this.pagination.page : 2
            } else {
                return (this.pagination.page > 2) ? (this.pagination.page + 1 > this.pagination.total_pages) ? this.pagination.total_pages : this.pagination.page + 1 : 3
            }
        },
        changePerPage() {
            this.pagination.page = 1
            this.pagination.per_page = Number(this.pagination.per_page)
            this.getFoodItems()
        },
        setShow(item) {
            var show = item.show
            this.foods.map(function (f) {
                f.show = false
                return f
            })

            item.show = !show
        },
        getUnit(id) {
            var u = this.units.find(u => u.id === id)
            if (u) {
                return u.value
            } else {
                return 'gr'
            }
        },
        getHas(f) {
            if (f.nutritional_content === null)
                return true

            var has = Object.keys(f.nutritional_content).filter(function (key, index) {
                return f.nutritional_content[key] !== null && !this.hiddenKey.includes(key);
            }.bind(this));

            return has.length ? false : true
        },
        filterClean() {
            this.searchInput = ""
            this.user = null
            this.date_start = ""
            this.date_end = ""
        },
        getOrder(text) {
            var i = 0
            var order = "order-last"
            if (text === "energy_value") order = "order-" + i
            i++
            if (text === "protein") order = "order-" + i
            i++
            if (text === "monounsaturated_fat" || text === "saturated_fat" || text === "polyunsaturated_fat" || text === "trans_fat") order = "order-" + i
            i++
            if (text === "fat" || text === "unsaturated_fat") order = "order-" + i
            i++
            if (text === "carbohydrates" || text == "available_carbohydrates") order = "order-" + i
            i++
            if (text === "biotin") order = "order-" + i
            i++

            if (text.includes("vitamin") || ["riboflavin", "niacin_equivalents", "folate"].includes(text)) order = "order-" + i
            i++
            if (text.includes("calcium") || text.includes("iron")) order = "order-" + i
            i++
            if (text.includes("phosphor")) order = "order-" + i
            i++
            if (text.includes("magnesium") || text.includes("omega")) order = "order-" + i
            i++
            if (text.includes("sodium") || text.includes("sodio")) order = "order-" + i
            i++
            if (text.includes("potassium")) order = "order-" + i
            i++
            if (text.includes("zinc")) order = "order-" + i
            i++
            if (text.includes("selenium")) order = "order-" + i

            return order
        }
    }
}
</script>
