import ApiService from './api.service'

const SessionService = {

    getAll: async function(params) {

        const response = await ApiService._get("api/session/get", params);
        return response.data

      

    },

    updateContact: async function(id, contact) {


        const response = await ApiService.post("api/update-contact/"+id, contact);
        return response.data


    },

    destroy: async function(slug) {


        const response = await ApiService.delete("api/delete-contact/"+slug);
        return response.data

      

    },   
    

}

export default SessionService
