import ApiService from './api.service'

const ActivitiesService = {

    getAll: async function(params) {

        const response = await ApiService.post("/api/admin/healthy-activity/get", params);
        return response.data

    },

    getAllMoods: async function(lang = null) {

        const response = await ApiService.get("/api/admin/healthy-activity/get-moods", lang);
        return response.data

    },


    save: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/healthy-activity/save", params, lang);
      return response.data

    },


    remove: async function(id, lang = null) {

      const response = await ApiService.get("/api/admin/healthy-activity/delete?id="+id, lang);
      return response.data

    },


}

export default ActivitiesService
