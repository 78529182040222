<template>
  <div>
  <div v-if="offsetScroll > 480" @click="goToTop" class="cursor-pointer shadow-md fixed bottom-0 left-20 box border rounded-full w-20 h-12 flex items-center justify-center z-50 mb-10 mr-10">
    <div class="text-white-400">
      {{ $t('views.sessions.text3') }}
    </div>
  </div>
    <div class="intro-y">
      <div class="col-span-12 xxl:col-span-3 relative z-10 mb-4">
        <div class="border-theme-25 pb-0 intro-y">
          <div class="flex items-center mt-8">
            <h2 class="text-lg font-medium mr-auto mb-2">{{ $t('views.sessions.text4') }} {{actual_session.session_name ? `de ${actual_session.session_name}` : ""}}</h2>
          </div>
          <p class="w-3/4">{{ $t('views.sessions.text5') }}</p>
          <div class="xxl:pl-6 grid grid-cols-12 gap-6">
            <div class="col-span-12 sm:col-span-12 md:col-span-6 xxl:col-span-6 mt-3">
              <div class="intro-x flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">{{ $t('views.sessions.text6') }}</h2>
              </div>
              <div class="mt-5"
                  v-for="(this_level, this_level_key) in comparison_A"
                  :key="this_level_key">
                <FilterItem
                  v-bind:this_level="this_level" v-bind:anyCheckboxPending="anyCheckboxPending" v-bind:hasAnyCheckedCheckbox="hasAnyCheckedCheckbox" v-bind:checkboxChangeInsideArray="checkboxChangeInsideArray" />
              </div>
            </div>
            <div class="col-span-12 sm:col-span-12 md:col-span-6 xxl:col-span-6 mt-3">
              <div class="intro-x flex items-center h-10">
                <h2 class="text-lg font-medium truncate mr-5">{{ $t('views.sessions.text7') }}</h2>
              </div>
              <div class="mt-5"
                  v-for="(this_level, this_level_key) in comparison_B"
                  :key="this_level_key">
                <FilterItem
                  v-bind:this_level="this_level" v-bind:anyCheckboxPending="anyCheckboxPending" v-bind:hasAnyCheckedCheckbox="hasAnyCheckedCheckbox" v-bind:checkboxChangeInsideArray="checkboxChangeInsideArray" />
              </div>
              <div class="mt-5">
                <button @click="getResults()" style="float:right" type="button" class="btn btn-primary w-30 h-8">
                  <Tippy v-if="!loading" data-theme="light" content="Volver a comparar">
                    {{ $t('views.sessions.text8') }}
                  </Tippy>
                  <Tippy v-if="loading" data-theme="light" content="Comparando">
                    <LoadingIcon icon="tail-spin" class="text-white w-3 h-3" color="white" />
                  </Tippy>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="text-center mb-6">
        <div class="w-10 mx-auto">
          <Tippy data-theme="light" content="Comparando">
            <LoadingIcon icon="three-dots" class="text-white" color="white" />
          </Tippy>
        </div>
        <!-- <button type="button" class="btn btn-primary w-24 h-10" @click="newFoodItem"><span>Comparar</span></button> -->
      </div>


      <div v-if="displayResults" class="flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">{{ $t('views.sessions.text9') }}</h2>
      </div>
      <p v-if="displayResults && message_A && message_B" v-html="$t('views.sessions.text10', {message_A, message_B})">
      </p>

      <ComparisonResults ref="healthGoals" title="Objetivos de salud" v-on:setMatch="updateChart"></ComparisonResults>
      <ComparisonResults ref="foodModality" title="Modalidad de alimentación" v-on:setMatch="updateChart"></ComparisonResults>
      <ComparisonResults ref="food" title="Alimentos" v-on:setMatch="updateChart"></ComparisonResults>
      <ComparisonResults ref="supplements" title="Suplementos" v-on:setMatch="updateChart"></ComparisonResults>
      <ComparisonResults ref="exercise" title="Ejercicios" v-on:setMatch="updateChart"></ComparisonResults>
      <ComparisonResults ref="labItems" title="Análisis de laboratorio" v-on:setMatch="updateChart"></ComparisonResults>
      <ComparisonResults ref="labItemsOthers" title="Otros análisis" v-on:setMatch="updateChart"></ComparisonResults>

      <!-- <button class="btn btn-primary" @click="generateChart1(); generateChart2()"> chart</button> -->

      <template v-if="displayResults">
        <div class="flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">{{ $t('views.sessions.text11') }}</h2>
        </div>
        <p class="mb-5">{{ $t('views.sessions.text12') }}</p>

        <div id="chartdiv1" class="mxy-8"></div>
      </template>

      <template v-if="displayChart && displayResults">
        <div class="flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">{{ $t('views.sessions.text13') }}</h2>
        </div>
        <p class="mb-5">{{ $t('views.sessions.text14') }}</p>

        <div id="chartdiv2" class="my-8"></div>
      </template>

      <template v-if="displayResults">
        <div class="flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">{{ $t('views.sessions.text15') }}</h2>
        </div>
        <p class="mb-5">{{ $t('views.sessions.text16') }}</p>
      </template>


      <ComparisonResults ref="healthGoalsFinal" title="Objetivos de salud" v-bind:isFinalResult="true"></ComparisonResults>
      <ComparisonResults ref="supplementsFinal" title="Suplementos" v-bind:isFinalResult="true"></ComparisonResults>
      <ComparisonResults ref="exerciseFinal" title="Ejercicios" v-bind:isFinalResult="true"></ComparisonResults>
      <ComparisonResults ref="foodModalityFinal" title="Modalidad de alimentación" v-bind:isFinalResult="true"></ComparisonResults>
      <ComparisonResults ref="foodFinal" title="Alimentos" v-bind:isFinalResult="true"></ComparisonResults>
      <ComparisonResults ref="labItemsFinal" title="Análisis de laboratorio" v-bind:isFinalResult="true"></ComparisonResults>
      <ComparisonResults ref="labItemsOthersFinal" title="Otros análisis" v-bind:isFinalResult="true"></ComparisonResults>

    <!-- <div class="masonry before:box-inherit after:box-inherit">
      <div class="break-inside">
        <ComparisonResults ref="healthGoalsFinal" title="Objetivos de salud" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
      <div class="break-inside">
        <ComparisonResults ref="supplementsFinal" title="Suplementos" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
      <div class="break-inside">
        <ComparisonResults ref="exerciseFinal" title="Ejercicios" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
      <div class="break-inside">
        <ComparisonResults ref="foodModalityFinal" title="Modalidad de alimentación" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
      <div class="break-inside">
        <ComparisonResults ref="foodFinal" title="Alimentos" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
      <div class="break-inside">
        <ComparisonResults ref="labItemsFinal" title="Análisis de laboratorio" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
      <div class="break-inside">
        <ComparisonResults ref="labItemsOthersFinal" title="Otros análisis" v-bind:isFinalResult="true"></ComparisonResults>
      </div>
    </div> -->



    </div>
  </div>
</template>
<style>
g[opacity='0.3']{
  display: none;
}
</style>
<style scoped>

.pointer{
  cursor: pointer;
}

#chartdiv1,
#chartdiv2 {
  width: 100%;
  height: 120px;
}

#gear{
    animation-name: ckw;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



</style>
<script>
import FilterItem from "/src/views/recommendations-comparison/components/filter-item";
import ComparisonResults from "/src/views/recommendations-comparison/components/comparison-results";
import QuizService from "/src/services/quiz.service";

import Toastify from "toastify-js";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


export default {
  components: {
    FilterItem,
    ComparisonResults
  },
  setup() {},
  data() {
    return {
      offsetScroll: 0,
      actual_session: JSON.parse(localStorage.session),
      comparison_A : [],
      comparison_B : [],
      toFilterPost_A : [],
      toFilterPost_B : [],
      displayResults:false,
      loading:false,
      resultA:{
        healthGoals:[],
        foods:[],
        foodsToReduce:[],
        supplements:[],
        supplementsToReduce:[],
        exercise:[],
        exercisesToReduce:[],
        labItems:[],
        labItemsOthers:[],
        intermittent_fasting_suggested:false,
      },
      resultB:{
        healthGoals:[],
        foods:[],
        foodsToReduce:[],
        supplements:[],
        supplementsToReduce:[],
        exercise:[],
        exercisesToReduce:[],
        labItems:[],
        labItemsOthers:[],
        intermittent_fasting_suggested:false,
      },
      totalSelectedQuizes: [],
      message_A: "",
      message_B: "",
      totalA:{
        healthGoals:0,
        foodModality:0,
        foods:0,
        supplements:0,
        exercise:0,
        labItems:0,
        labItemsOthers:0,
      },
      totalB:{
        healthGoals:0,
        foods:0,
        foodModality:0,
        supplements:0,
        exercise:0,
        labItems:0,
        labItemsOthers:0,
      },
      totalMatch:{
        healthGoals:0,
        foods:0,
        foodModality:0,
        supplements:0,
        exercise:0,
        labItems:0,
        labItemsOthers:0,
      },
      totalLabAddReduce:{
        healthGoals:0,
        foodModality:0,
        foods:0,
        supplements:0,
        exercise:0,
        labItems:0,
        labItemsOthers:0,
      },
      totalLabSuspend:{
        healthGoals:0,
        foodModality:0,
        foods:0,
        supplements:0,
        exercise:0,
        labItems:0,
        labItemsOthers:0,
      },
      totalDoubleEvaluated:{
        healthGoals:0,
        foodModality:0,
        foods:0,
        supplements:0,
        exercise:0,
        labItems:0,
        labItemsOthers:0,
      },
      totalConsolidatedA:0,
      totalConsolidatedB:0,
      totalConsolidatedMatch:0,
      totalConsolidatedLabAddReduce:0,
      totalConsolidatedSuspended:0,
      totalConsolidatedDoubleEvaluated:0,
      displayChart:false,
      isLabIncludedA:false,
      isLabIncludedB:false,
      updateChartEvents:0,
    }
  },
  watch:{
    '$i18n.locale': function(l) {
      this.filterMenuSetup();
    }
  },
  computed: {
  },

  created () {
    this.filterMenuSetup();
    window.addEventListener('scroll', ()=>{
      this.offsetScroll = window.scrollY
    });
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    updateChart(component, totalA, totalB, match, totalLabAddReduce, totalLabSuspend, totalDoubleEvaluated){

      this.totalA[component] = totalA;
      this.totalB[component] = totalB;
      this.totalMatch[component] = match;
      this.totalLabAddReduce[component] = totalLabAddReduce;
      this.totalLabSuspend[component] = totalLabSuspend;
      this.totalDoubleEvaluated[component] = totalDoubleEvaluated;

      this.totalConsolidatedA = 0;
      for (const [key, value] of Object.entries(this.totalA)) {
        this.totalConsolidatedA += value || 0;
      }

      this.totalConsolidatedB = 0;
      for (const [key, value] of Object.entries(this.totalB)) {
        this.totalConsolidatedB += value || 0;
      }

      this.totalConsolidatedMatch = 0;
      for (const [key, value] of Object.entries(this.totalMatch)) {
        this.totalConsolidatedMatch += value || 0;
      }

      this.totalConsolidatedLabAddReduce = 0;
      for (const [key, value] of Object.entries(this.totalLabAddReduce)) {
        this.totalConsolidatedLabAddReduce += value || 0;
      }

      this.totalConsolidatedSuspended = 0;
      for (const [key, value] of Object.entries(this.totalLabSuspend)) {
        this.totalConsolidatedSuspended += value || 0;
      }

      this.totalConsolidatedDoubleEvaluated = 0;
      for (const [key, value] of Object.entries(this.totalDoubleEvaluated)) {
        this.totalConsolidatedDoubleEvaluated += value || 0;
      };

      this.updateChartEvents++;
      if (this.updateChartEvents < 7){
        return;
      }

      this.updateChartEvents = 0;

      this.$nextTick(() => {
        this.generateChart1();

        this.displayChart = false;
        if (this.toFilterPost_A.length > 0 && this.toFilterPost_B.length > 0){

          if ( (this.isLabIncludedA && !this.isLabIncludedB) || (!this.isLabIncludedA && this.isLabIncludedB) ){

            this.displayChart = true;
            this.$nextTick(() => {  this.generateChart2(); });

          }

        }

      });

    },
    generateChart1(){

        var aIsolated = this.totalConsolidatedA - this.totalConsolidatedDoubleEvaluated;
        var bIsolated = this.totalConsolidatedB - this.totalConsolidatedDoubleEvaluated;

        var totalSize = aIsolated + bIsolated + this.totalConsolidatedDoubleEvaluated;

        var aPercentil = Math.round(aIsolated * 100 / totalSize);
        var bPercentil = Math.round(bIsolated * 100 / totalSize);
        var matchPercentil = Math.round(this.totalConsolidatedDoubleEvaluated * 100 / totalSize);

        var fromChartA = 0;
        var toChartA = aPercentil;

        var fromChartB = toChartA;
        var toChartB = fromChartB + matchPercentil;

        var fromChartC = toChartB;
        var toChartC = 100;


        /* Chart code */
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        let chart = am4core.create("chartdiv1", am4charts.XYChart);

        // Add data
        chart.data = [{
          "category": "",
          "from":fromChartA,
          "to": toChartA,
          "name": this.$t('views.sessions.text17') + ": "+aPercentil+"%", //+" ("+aIsolated+")",
          "fill": am4core.color("#F8E9A9"),
        },
        {
          "category": "",
          "from": fromChartB,
          "to": toChartB,
          "name": this.$t('views.sessions.text18') +": "+matchPercentil+"%", //+" ("+matchPercentil+")",
          "fill": am4core.color("#84D9A5"),
        },
        {
          "category": "",
          "from": fromChartC,
          "to": toChartC,
          "name": this.$t('views.sessions.text19') +": "+bPercentil+"%", //+" ("+bIsolated+")",
          "fill": am4core.color("#7FB7E2"),
        }
        ];

        // Create axes
        let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.labels.template.disabled = true;

        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.labels.template.disabled = true;
        xAxis.min = 0;
        xAxis.max = 100 ;

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "to";
        series.dataFields.openValueX = "from";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "fill";
        series.columns.template.strokeOpacity = 0;
        series.columns.template.height = am4core.percent(100);

        // Ranges/labels
        chart.events.on("beforedatavalidated", function(ev) {
          let data = chart.data;
          for(var i = 0; i < data.length; i++) {
            let range = xAxis.axisRanges.create();
            range.value = data[i].to;
            range.label.text = data[i].to + "%";
            range.label.horizontalCenter = "right";
            range.label.paddingLeft = 5;
            range.label.paddingTop = 5;
            range.label.fontSize = 10;
            range.label.fill = am4core.color("#182034");
            range.grid.strokeOpacity = 0.2;
            range.tick.length = 18;
            range.tick.strokeOpacity = 0.2;
          }
        });

        // Legend
        let legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        legend.itemContainers.template.clickable = false;
        legend.itemContainers.template.focusable = false;
        legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        legend.align = "right";
        legend.data = chart.data;
        legend.labels.template.fill = am4core.color("white");

    },
    generateChart2(){

        var aIsolated = this.totalConsolidatedA - this.totalConsolidatedMatch - this.totalConsolidatedLabAddReduce - this.totalConsolidatedSuspended;
        var bIsolated = this.totalConsolidatedB - this.totalConsolidatedMatch - this.totalConsolidatedLabAddReduce - this.totalConsolidatedSuspended;

        var totalSize = aIsolated + bIsolated + this.totalConsolidatedMatch + this.totalConsolidatedLabAddReduce + this.totalConsolidatedSuspended


        var aPercentil = Math.round(aIsolated * 100 / totalSize);
        var bPercentil = Math.round(bIsolated * 100 / totalSize);
        var matchPercentil = Math.round(this.totalConsolidatedMatch * 100 / totalSize);
        var labAddReducePercentil = Math.round(this.totalConsolidatedLabAddReduce * 100 / totalSize);
        var labSuspendPercentil = Math.round(this.totalConsolidatedSuspended * 100 / totalSize);


        var fromChartA = 0;
        var toChartA = aPercentil;

        var fromChartMatch = toChartA;
        var toChartMatch = fromChartMatch + matchPercentil;

        var fromChartB = toChartMatch;
        var toChartB = fromChartB + bPercentil;

        var fromChartAddReduce = toChartB;
        var toChartAddReduce = fromChartAddReduce + labAddReducePercentil;

        var fromChartSuspend = toChartAddReduce;
        var toChartSuspend = 100;


        //Seguir graficando lo que viene despues aca. de los charts.


        /* Chart code */
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        // Create chart instance
        let chart = am4core.create("chartdiv2", am4charts.XYChart);

        // Add data
        chart.data = [{
          "category": "",
          "from":fromChartA,
          "to": toChartA,
          "name": this.$t('views.sessions.text17') + ": "+aPercentil+"%",
          "fill": am4core.color("#F8E9A9"),
        },
        {
          "category": "",
          "from": fromChartMatch,
          "to": toChartMatch,
          "name": "Match: "+matchPercentil+"%",
          "fill": am4core.color("#84D9A5"),
          "tooltipText":"123"
        },
        {
          "category": "",
          "from": fromChartB,
          "to": toChartB,
          "name": this.$t('views.sessions.text19') + ": "+bPercentil+"%",
          "fill": am4core.color("#7FB7E2"),
        },
        {
          "category": "",
          "from": fromChartAddReduce,
          "to": toChartAddReduce,
          "name": this.$t('views.sessions.text20') + ": "+labAddReducePercentil+"%",
          "fill": am4core.color("#F7915F"),
        },
        {
          "category": "",
          "from": fromChartSuspend,
          "to": toChartSuspend,
          "name": this.$t('views.sessions.text21') + ": "+labSuspendPercentil+"%",
          "fill": am4core.color("#FF695D"),
        }];

        // Create axes
        let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.labels.template.disabled = true;

        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.labels.template.disabled = true;
        xAxis.min = 0;
        xAxis.max = 100 ;

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "to";
        series.dataFields.openValueX = "from";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "fill";
        series.columns.template.strokeOpacity = 0;
        series.columns.template.height = am4core.percent(100);

        // Ranges/labels
        chart.events.on("beforedatavalidated", function(ev) {
          let data = chart.data;
          for(var i = 0; i < data.length; i++) {
            let range = xAxis.axisRanges.create();
            range.value = data[i].to;
            range.label.text = data[i].to + "%";
            range.label.horizontalCenter = "right";
            range.label.paddingLeft = 5;
            range.label.paddingTop = 5;
            range.label.fontSize = 10;
            range.label.fill = am4core.color("#182034");
            range.grid.strokeOpacity = 0.2;
            range.tick.length = 18;
            range.tick.strokeOpacity = 0.2;
          }
        });

        // Legend
        let legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        legend.itemContainers.template.clickable = false;
        legend.itemContainers.template.focusable = false;
        legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        legend.align = "right";
        legend.data = chart.data;
        legend.labels.template.fill = am4core.color("white");


    },
    filterMenuSetup(){
      QuizService.getQuizMenuToEdit({
        user_id: this.actual_session.id
      }).then((server_response)=>{
        this.comparison_A = JSON.parse(JSON.stringify(server_response.map((each)=>{
          if (each.quizzes){
            each.original_quizzes = JSON.parse(JSON.stringify(each.quizzes || []))
            each.quizzes = each.quizzes.map((a)=>{
              if (a.quizzes){
                a.original_quizzes = JSON.parse(JSON.stringify(a.quizzes || []))
                a.quizzes = a.quizzes.map((b)=>{
                  b.checkbox = false
                  return b
                })
              }
              a.checkbox = false
              return a
            })
          }
          each.checkbox = false
          return each
        })))
        this.comparison_A[0].checkbox = true

        this.comparison_B = JSON.parse(JSON.stringify(server_response.map((each)=>{
          if (each.quizzes){
            each.original_quizzes = JSON.parse(JSON.stringify(each.quizzes || []))
            each.quizzes = each.quizzes.map((a)=>{
              if (a.quizzes){
                a.original_quizzes = JSON.parse(JSON.stringify(a.quizzes || []))
                a.quizzes = a.quizzes.map((b)=>{
                  b.checkbox = false
                  return b
                })
              }
              a.checkbox = false
              return a
            })
          }
          each.checkbox = false
          return each
        })))
        this.comparison_B[0].checkbox = true
      })
    },
    async getResults(){

      this.loading = true;

      this.resultA = await this.askForRecommendations('A');
      this.resultB = await this.askForRecommendations('B');

      this.handleResult();
      this.loading = false;


    },
    async askForRecommendations(param){
      var filterToSend = []
      if (param == "A") {
        this.toFilterPost_A = []
        this.prepareDataToFilter(this.comparison_A, this.toFilterPost_A)
        filterToSend = this.toFilterPost_A
      }
      if (param == "B") {
        this.toFilterPost_B = []
        this.prepareDataToFilter(this.comparison_B, this.toFilterPost_B)
        filterToSend = this.toFilterPost_B
      }
      this.message_AA()
      this.message_BB()

      var result = await QuizService.getRecommendations({
        user_id: this.actual_session.id,
        filter: filterToSend.filter(a => a.quiz_id != undefined)
      });

      return result;
    },
    prepareDataToFilter(input, output){
    if (!input) {
        return
    }
    input.forEach((a) => {
      if (a.checkbox) {
        if (output) {
          var obj = output.find((toSearch)=>{
            return toSearch.quiz_id == a.answers_quiz_id
          })
          if (obj && a.answers_quiz_id) {
            obj.quiz_question_ids.push(a.quiz_question_id)
          } else {
            output.push({
              "quiz_id":a.quiz_id || a.answers_quiz_id,
              "quiz_question_ids":[]
            })
          }
        }
      this.prepareDataToFilter(a.quizzes || a.answers, output)
      }
    })
    },
    handleResult(){

      var resultA = this.resultA;
      var resultB = this.resultB;
      this.isLabIncludedA = resultA.isLabIncluded;
      this.isLabIncludedB = resultB.isLabIncluded;

      this.displayResults = false;
      if (resultA.foods.length > 0 || resultA.foodsToReduce.length > 0 ||
          resultA.supplements.length > 0 || resultA.supplementsToReduce.length > 0 ||
          resultA.exercise.length > 0 || resultA.exercisesToReduce.length > 0 ||
          resultA.labItems.length > 0 || resultA.labItemsOthers.length > 0 ){
            this.displayResults = true;
      }

      if (resultB.foods.length > 0 || resultB.foodsToReduce.length > 0 ||
          resultB.supplements.length > 0 || resultB.supplementsToReduce.length > 0 ||
          resultB.exercise.length > 0 || resultB.exercisesToReduce.length > 0 ||
          resultB.labItems.length > 0 || resultB.labItemsOthers.length > 0 ){
            this.displayResults = true;
      }

      //healthGoals
      this.$refs.healthGoals.refreshTree({
        A:resultA.healthGoals,
        B:resultB.healthGoals,
        component:"healthGoals"
      });

      this.$refs.healthGoalsFinal.refreshTree({
        A:resultA.healthGoals,
        B:resultB.healthGoals,
        component:"healthGoals"
      });

      var foodModalityA = [];
      if (resultA.intermittent_fasting_suggested){
        var reduce = resultA.intermittent_fasting_reduce || false;
        var amount = null
        // if (reduce){
        //   amount = ` (${resultA.intermittent_fasting_hours} hs)`
        // }
        foodModalityA.push({ id: 1,  unique_id: 1, name: this.$t('views.sessions.text22'), reduce, labRecommended:resultA.isLabIncluded, amount})
      }
      else if (resultA.intermittent_fasting_suspend){
        foodModalityA.push({ id: 1,  unique_id: 1, name: this.$t('views.sessions.text22'), suspend:true, labRecommended:resultA.isLabIncluded});

      }

      if (this.toFilterPost_A.find(x => x.quiz_id == 17)){
        foodModalityA.push({ id: 2,  unique_id: 2, name: this.$t('views.sessions.text23') + ": " + this.actual_session.alimentation_type})
      }

      var foodModalityB = [];
      if (resultB.intermittent_fasting_suggested){
        var reduce = resultB.intermittent_fasting_reduce || false;
        var amount = null
        // if (reduce){
        //   amount = `(${resultB.intermittent_fasting_hours} hs)`
        // }
        foodModalityB.push({ id: 1,  unique_id: 1, name: this.$t('views.sessions.text22'), reduce, labRecommended:resultB.isLabIncluded, amount})
      }
      else if (resultB.intermittent_fasting_suspend){
        foodModalityB.push({ id: 1,  unique_id: 1, name: this.$t('views.sessions.text22'), suspend:true, labRecommended:resultB.isLabIncluded});
      }

      if (this.toFilterPost_B.find(x => x.quiz_id == 17)){
        foodModalityB.push({ id: 2,  unique_id: 2, name: this.$t('views.sessions.text23') + ": " + this.actual_session.alimentation_type})
      }

      this.$refs.foodModality.refresh({
        A:foodModalityA,
        B:foodModalityB,
        key:"id",
        component:"foodModality",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
      });

      this.$refs.foodModalityFinal.refresh({
        A:foodModalityA,
        B:foodModalityB,
        AReduce:[],
        BReduce:[],
        ASuspend:[],
        BSuspend:[],
        key:"id"
        })

      this.$refs.food.refresh({
        A:resultA.foods,
        B:resultB.foods,
        key:"food_id",
        type:"food",
        component:"foods",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        });

      this.$refs.foodFinal.refresh({
        A:resultA.foods,
        B:resultB.foods,
        AReduce:resultA.foodsToReduce,
        BReduce:resultB.foodsToReduce,
        ASuspend:resultA.foodsToSuspend,
        BSuspend:resultB.foodsToSuspend,
        key:"food_id",
        type:"food",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        })

      this.$refs.supplements.refresh({
        A:resultA.supplements,
        B:resultB.supplements,
        key:"id",
        component:"supplements",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        })

      this.$refs.supplementsFinal.refresh({
        A:resultA.supplements,
        B:resultB.supplements,
        AReduce:resultA.supplementsToReduce,
        BReduce:resultB.supplementsToReduce,
        ASuspend:resultA.supplementsToSuspend,
        BSuspend:resultB.supplementsToSuspend,
        key:"id",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
       })

      this.$refs.exercise.refresh({
        A:resultA.exercise,
        B:resultB.exercise,
        key:"id",
        component:"exercise",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        })

      this.$refs.exerciseFinal.refresh({
        A:resultA.exercise,
        B:resultB.exercise,
        AReduce:resultA.exercisesToReduce,
        BReduce:resultB.exercisesToReduce,
        ASuspend:resultA.exercisesToSuspend,
        BSuspend:resultB.exercisesToSuspend,
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        key:"id"})

      this.$refs.labItems.refresh({
        A:resultA.labItems,
        B:resultB.labItems,
        key:"id",
        component:"labItems",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        })

      this.$refs.labItemsFinal.refresh({
        A:resultA.labItems,
        B:resultB.labItems,
        AReduce:[],
        BReduce:[],
        ASuspend:[],
        BSuspend:[],
        key:"id",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded
        })

      this.$refs.labItemsOthers.refresh({
        A:resultA.labItemsOthers,
        B:resultB.labItemsOthers,
        key:"id",
        component:"labItemsOthers",
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        })

      this.$refs.labItemsOthersFinal.refresh({
        A:resultA.labItemsOthers,
        B:resultB.labItemsOthers,
        AReduce:[],
        BReduce:[],
        ASuspend:[],
        BSuspend:[],
        isLabIncludedA:resultA.isLabIncluded,
        isLabIncludedB:resultB.isLabIncluded,
        key:"id"})

    },
    anyCheckboxPending (array){
      if (!array){
        return
      }
      var output = array.find((a)=>{
        if (a.quizzes) {
          return this.anyCheckboxPending(a.quizzes)
        } else {
          return !a.checkbox
        }
      })
      return output != undefined
    },
    hasAnyCheckedCheckbox (array){
      if (!array){
        return
      }
      var output = array.find((a)=>{
        if (a.quizzes) {
          return this.hasAnyCheckedCheckbox(a.quizzes)
        } else {
          return a.checkbox
        }
      })
      return output != undefined
    },
    checkboxChangeInsideArray(array, state){
      if (array){
        array.forEach((a)=>{
          a.checkbox = state
          if (a.quizzes) {
            this.checkboxChangeInsideArray(a.quizzes, state)
          }
        })
      }
    },

    goToTop(){
      window.scroll(0,0)
    },
    getQuizCompletation(quiz){
      if (!quiz){
          return
      }
      var total_incount = quiz.filter((a)=>{return a.checkbox == true}).length
      var total = quiz.length
      return (total_incount == total || total_incount == 0) ? false : `${total_incount}/${total}`
    },
    getSelectedQuizes(array, prepend){
        var getSelected = array.filter((a)=>{return a.checkbox == true})
        var toAdd = []

        getSelected.forEach(a => {
            if (a.quizzes && (a.title == this.$t('views.sessions.text23') || a.title == this.$t('views.sessions.text24') || a.health_goal_id)){
                this.getSelectedQuizes(a.quizzes)
            }
            if (prepend){
                a.title = prepend + a.title
            }

            if (a.quiz_id){
                toAdd.push({
                    title: a.title,
                    status: this.getQuizCompletation(a.quizzes),
                    total: (a.quizzes || []).length
                })
            }

        });

        this.totalSelectedQuizes = this.totalSelectedQuizes.concat(toAdd)
    },
    message_AA(){
      this.totalSelectedQuizes = []
      this.getSelectedQuizes(this.comparison_A)
      this.message_A = this.totalSelectedQuizes.map((a)=>{
        return a.title + (a.status ? ` (${a.status})` : "")
      }).toString().replaceAll(",", ", ")
      var lIndex  = this.message_A.lastIndexOf(",");
      if (this.totalSelectedQuizes.length > 1) {
        this.message_A = this.message_A.substring(0, lIndex) + " "+ this.$t('views.sessions.text25') + this.message_A.substr(lIndex + 1);
      }
    },
    message_BB(){
      this.totalSelectedQuizes = []
      this.getSelectedQuizes(this.comparison_B)
      this.message_B = this.totalSelectedQuizes.map((a)=>{
        return a.title + (a.status ? ` (${a.status})` : "")
      }).toString().replaceAll(",", ", ")
      var lIndex  = this.message_B.lastIndexOf(",");
      if (this.totalSelectedQuizes.length > 1) {
        this.message_B = this.message_B.substring(0, lIndex) + " "+ this.$t('views.sessions.text25') + this.message_B.substr(lIndex + 1);
      }
    }
  }
};

</script>
