<template>
<div class="accordion-item">

  <div class="box px-5 py-3 mb-3 flex items-center">
    <div class="mr-auto">
      <div class="font-medium accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="false" @click="titleClick()">
          <div class="max-w-xs truncate">
            <Tippy data-theme="light" :content="cleanedHTML" v-html="shortedHTML"></Tippy>
            <span v-if="this_level.quizzes && this_level.quizzes.length > 0" class=""> ({{ itemsAmount }})</span>
          </div>
        </button>
      </div>
      <span v-if="this_level.answers && this_level.answers.length > 0" v-html="answersText"></span>
    </div>
    <div>
      <div class="form-check">
        <input v-on:change="checkboxChangeInsideArray(this_level.quizzes, this_level.checkbox); checkIfIsTheLastCheckbox()" v-model="this_level.checkbox" class="form-check-input" :class="{
          'partialy' : this_level.checkbox && anyCheckboxPending(this_level.quizzes)
          }" type="checkbox">
      </div>
    </div>
  </div>
  <div v-if="this_level.checkbox && this_level.quizzes && this_level.quizzes.length > 0" class="ml-10 accordion-collapse collapse" style="display:none">
    <FilterItem
      v-for="(next_level) in this_level.quizzes"
      :key="next_level" v-bind:parent_level="this_level" v-bind:this_level="next_level" v-bind:anyCheckboxPending="anyCheckboxPending" v-bind:hasAnyCheckedCheckbox="hasAnyCheckedCheckbox" v-bind:checkboxChangeInsideArray="checkboxChangeInsideArray" />
  </div>
</div>
</template>
<style scoped>
.dark .form-check-input[type=checkbox] {
    background-color: #182033 !important;
    border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
<script>
import FilterItem from "/src/views/recommendations-comparison/components/filter-item";
import QuizService from "/src/services/quiz.service";
import Toastify from "toastify-js";
import moment from 'moment';

export default {
  components:{
    FilterItem
  },
  setup() {
  },
  props: {
    parent_level: Object,
    this_level: Object,
    anyCheckboxPending: Function,
    hasAnyCheckedCheckbox: Function,
    checkboxChangeInsideArray: Function
  },
  emits: [
    "eventname"
  ],
  data() {
    return {
      actual_session: JSON.parse(localStorage.session),
      questions_answers:[]
    }
  },
  watch:{
    this_level: {
      deep: true,
      handler(newValue, oldValue){
        this.checkIfIsTheLastCheckbox()        
      }
    }
  },
  computed: {
    cleanedHTML(){
      return (this.this_level.title || "").replaceAll(`<br>`, ``).replaceAll(`<br/>`, ``).replaceAll(`</p>`, ``).replaceAll(`<p>`, ``)
    },
    shortedHTML(){
      return (this.this_level.title || "").replaceAll(`<br>`, ``).replaceAll(`<br/>`, ``).replaceAll(`</p>`, ``).replaceAll(`<p>`, ``).substr(0,100)
    },
    itemsAmount(){
      var total = (this.this_level.quizzes || this.this_level.answers || []).length 
      var actual = (this.this_level.quizzes || this.this_level.answers || []).filter(a => a.checkbox).length
      // return (actual == total || actual == 0) ? total : `${actual}/${total}`
      return total
    },
    answersText(){
      if (this.this_level.answers[0].input && this.this_level.answers[0].input.length > 9 && moment(this.this_level.answers[0].input,"YYYY-MM-DD").isValid()) {
        return moment(this.this_level.answers[0].input,"YYYY-MM-DD").format("DD/MM/YYYY")
      }
      var array = (this.this_level.quizzes || this.this_level.answers || []).filter((a)=>{
        return (a.selected == true) || (a.title)
      }).map((a)=>{
        return (a.input || a.description)
      })

      var str = array.toString().replaceAll(",", ", ")
      var lIndex  = str.lastIndexOf(",");
      if (array.length > 1) {
        str = str.substring(0, lIndex) + " y" + str.substr(lIndex + 1);
      }
      // return (actual == total || actual == 0) ? total : `${actual}/${total}`
      if (str) {
        return str[0].toUpperCase() + str.substring(1).toLowerCase()        
      } else {
        return str
      }
    }

  },
  created() {
    window.moment = moment
    if (this.actual_session && this.actual_session.id && this.this_level.quiz_id) {
      QuizService.getQuizToEdit({
        user_id: this.actual_session.id,
        quiz_id: this.this_level.quiz_id
      }).then((server_response)=>{
        this.questions_answers = server_response.questions.filter((a)=>{
          return !(this.this_level.quiz_id == "lab-analysis" && a.answers[0].input == "")
        }).map((a)=>{
          return {
            answers_quiz_id: this.this_level.quiz_id,
            quiz_question_id: a.quiz_question_id,
            title: a.description,
            answers: a.answers.filter((q) => {return q.selected}),
            checkbox: (this.parent_level || {checkbox : false}).checkbox
          }
        })
        this.this_level.quizzes = (this.this_level.original_quizzes || []).concat(this.questions_answers)
      })      
    }
  },
  methods: {
    checkIfIsTheLastCheckbox(){

      if (!this.parent_level) {
        return
      }

      this.parent_level.checkbox = this.hasAnyCheckedCheckbox(this.parent_level.quizzes);

    },
    titleClick(){
      if (this.this_level.checkbox == false) {
        this.this_level.checkbox = true
        this.checkboxChangeInsideArray(this.this_level.quizzes, true)        
      }
    }
  }
};

</script>