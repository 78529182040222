const TOKEN_KEY = 'token'
const IS_ADMIN = 'is_admin'

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },

    setIsAdmin () {
      localStorage.setItem(IS_ADMIN, true)
    },

    isAdmin() {
      return localStorage.getItem(IS_ADMIN)
    },

    isVerify(){
      var lastSecurityCheckpoint = localStorage.getItem("lastSecurityCheckpoint")
      return (lastSecurityCheckpoint && Number(lastSecurityCheckpoint) > ((new Date()).getTime() - (1000 * 7200)))
    },

    isLocal(){
      return localStorage.getItem("local")
    }

}

const UserInfo = {
    save(info){
      localStorage.setItem("user", user)
    },

    get(){
      return JSON.parse( localStorage.getItem("INFO") );
    },
}

const Recipes = {
    setIsSaving(value){
      localStorage.setItem("recipeIsSaving", value)
    },

    isSaving(){
      return localStorage.getItem("recipeIsSaving");
    },

    removeIsSaving(){
      return localStorage.removeItem("recipeIsSaving");
    }
}

export { TokenService, UserInfo, Recipes }
