import ApiService from "./api.service"
const TOKEN_KEY = "access_token"

const UserService = {
  loginAs: async function(params) {
    const response = await ApiService._get("/login-as", params)
    return response.data
  },
  delete: async function(params) {
    const response = await ApiService._get("/session/destroy", params)
    return response.data
  },
  duplicate: async function(params) {
    const response = await ApiService._get("/session/duplicate", params)
    return response.data
  },
  notifyError: async function(params) {
    const response = await ApiService.post("/api/dev-support/save", params)
    return response.data
  },
  getProfile: async function(params) {
    const response = await ApiService._get("/api/health-profile/get")
    console.log(response.data)
    return response.data
  },
  verifyAdmin: async function(params) {
    const response = await ApiService.post("/api/verify-admin", params)
    return response.data
  },
  verifyAdminToken: async function(params) {
    const response = await ApiService._get("/api/verify-admin-token", params)
    return response.data
  },
  verifyMasterKey: async function(params) {
    const response = await ApiService.post("/api/verify-masterkey", params)
    return response.data
  },
  logout: async function() {
    ApiService.removeHeader()
    localStorage.removeItem("token")
    localStorage.removeItem("access_token")
    var preferences = localStorage.getItem("preferences")
    localStorage.clear()
    localStorage.setItem("preferences", preferences)

    return true
  },
  getUserPermissions: async function() {
    const response = await ApiService._get("/api/admin/permissions/get-by-user")
    return response.data
  },

  getAllUsers: async function() {
    return true
    const response = await ApiService._get("/api/admin/permissions/get-by-user")
    return response.data
  },

  getAdminUsers: async function() {
    const response = await ApiService._get("/api/admin/users/get-admins")
    return response.data
  }
}

export default UserService
