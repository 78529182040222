<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">{{ $t('views.labAnalysis.text1') }}</h2>
    <div class="grid grid-cols-12 gap-6 ">
      <div class="intro-y col-span-12 flex flex-wrap lg:flex-nowrap items-center mt-2">
        
        <div class="cursor w-full lg:w-80">
          <p class="mr-auto">{{ $t('views.labAnalysis.text2') }}</p>
        </div>


        <div class="cursor w-full lg:w-20 mt-3 lg:mt-0 lg:ml-auto lg:invisible xl:visible">
          <p class="ml-auto">{{ $t('views.labAnalysis.text40') }}</p>
        </div>

        <div class="cursor w-full lg:w-56 mt-3 lg:mt-0 lg:ml-auto md:ml-0 mr-5">
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <TomSelect
              class="w-full cursor"
              v-model="categories"
            >
              <option value="0">{{ $t('views.labAnalysis.categories.value') }}</option>
              <option value="BLOOD_SUGAR">{{ $t('views.labAnalysis.categories.blood_sugar') }}</option>
              <option value="HORMONES">{{ $t('views.labAnalysis.categories.hormones') }}</option>
              <option value="CARDIOVASCULAR">{{ $t('views.labAnalysis.categories.cardiovascular') }}</option>
              <option value="VITAMINS">{{ $t('views.labAnalysis.categories.vitamins') }}</option>
              <option value="HEMOGRAM">{{ $t('views.labAnalysis.categories.hemogram') }}</option>
              <option value="MINERALES">{{ $t('views.labAnalysis.categories.minerals') }}</option>
              <option value="HEAVY_METALS">{{ $t('views.labAnalysis.categories.heavy_metals') }}</option>
              <option value="ENZYMES_MARKERS">{{ $t('views.labAnalysis.categories.enzymes_markers') }}</option>
              <option value="HEPATOGRAM">{{ $t('views.labAnalysis.categories.hepatogram') }}</option>
              <option value="PROTEINOGRAM">{{ $t('views.labAnalysis.categories.proteinogram') }}</option>
              <option value="RENAL_FUNCTION">{{ $t('views.labAnalysis.categories.renal_function') }}</option>
              <option value="INFLAMMATION_IMMUNITY">{{ $t('views.labAnalysis.categories.inflammation_immunity') }}</option>
              <option value="ANTIBODIES">{{ $t('views.labAnalysis.categories.antibodies') }}</option>
              <option value="NEUROTRANSMITTERS">{{ $t('views.labAnalysis.categories.neurotransmitters') }}</option>
              <option value="COAGULATION">{{ $t('views.labAnalysis.categories.coagulation') }}</option>
              <option value="OTHERS">{{ $t('views.labAnalysis.categories.others') }}</option>
            </TomSelect>
          </div>
        </div>
        <div class="cursor w-full lg:w-56 mt-3 lg:mt-0 lg:ml-auto md:ml-0 mr-5">
          <div class="w-full relative text-gray-700 dark:text-gray-300">
            <TomSelect
              class="w-full cursor"
              v-model="quizToSearch"
            >
              <option value="0">{{ $t('views.labAnalysis.desbalance.value') }}</option>
              <option value="1">{{ $t('views.labAnalysis.desbalance.value1') }}</option>
              <option value="2">{{ $t('views.labAnalysis.desbalance.value2') }}</option>
              <option value="3">{{ $t('views.labAnalysis.desbalance.value3') }}</option>
              <option value="4">{{ $t('views.labAnalysis.desbalance.value4') }}</option>
              <option value="5">{{ $t('views.labAnalysis.desbalance.value5') }}</option>
              <option value="6">{{ $t('views.labAnalysis.desbalance.value6') }}</option>
            </TomSelect>
          </div>
        </div>
        <div class="w-full lg:w-auto mt-3 lg:mt-0 lg:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              :placeholder="$t('views.labAnalysis.text3')"
              v-model="searchInput"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>

     
      

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto xl:overflow-visible">

      
        <table class="table table-report -mt-2">
            <thead class="sticky top-0 left-0 right-0 z-50" style="background-color:#192033">
              <tr>
                <th class="whitespace-nowrap">{{ $t('views.labAnalysis.text4') }}</th>
                <th class="whitespace-nowrap">{{ $t('views.labAnalysis.text5') }}</th>
                <th class="whitespace-nowrap">{{ $t('views.labAnalysis.text6') }}</th>
                <th class="whitespace-nowrap">{{ $t('views.labAnalysis.text7') }}</th>
                <th class="text-center whitespace-nowrap">{{ $t('views.labAnalysis.text8') }}</th>
              </tr>
            </thead>
          <tbody>
           
            <template v-for="(item, itemKey) in labParams" :key="itemKey">

            <tr v-if="item.showCategory" class="intro-x" style="background-color:#131A29">
              <td colspan="5" style="background-color:#131A29 !important;"  >{{ $t('views.labAnalysis.categories.'+String(item.category).toLowerCase() )}}</td>
            </tr>


            <tr class="intro-x" @click="editLabItem(item)">
              <td style="width:40px">
                <a href="javascript:void(0)" class="font-medium whitespace-nowrap">{{
                  item.name || item.key
                }}</a>
              </td>
              <td>
                {{
                  (item.unit == null) ? ` - ` : item.unit
                }}
              </td>
              <td>
                {{
                  (item.min != null) ? item.min : `${$t('views.labAnalysis.text9')}: ${(item.min_female == null) ? "-" : item.min_female} | ${$t('views.labAnalysis.text10')}: ${(item.min_male == null) ? "-" : item.min_male}`
                }}
              </td>
              <td>
                {{
                  (item.max != null) ? item.max : `${$t('views.labAnalysis.text9')}: ${item.max_female || "-"} | ${$t('views.labAnalysis.text10')}: ${item.max_male || "-"}`
                }}
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    {{ $t('views.labAnalysis.text11') }}
                  </a>
                </div>
              </td>
            </tr>
            </template>
            <tr v-if="loading">
              <td colspan="5" class="p-4">
                {{ $t("views.loading") }}
                <LoadingIcon icon="three-dots" color="currentColor" class="ml-4 inline-block" />
              </td>
            </tr>
            <tr v-if="!loading && labParams.length === 0">
              <td colspan="5" class="p-4">
                 {{ $t("views.no_data") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center mb-12"
      v-if="labParams.length > 0"
      >
        <ul class="pagination hidden">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <div class="ml-auto text-gray-600">
          {{ $t('views.labAnalysis.text11', {total : labParams.length}) }}
        </div>
        <select class="w-20 form-select box mt-3 sm:mt-0 hidden">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">{{ $t('views.labAnalysis.text13') }}</div>
              <div class="text-gray-600 mt-2" v-html="$t('views.labAnalysis.text14')"></div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
              {{ $t('views.cancel') }}
              </button>
              <button type="button" class="btn btn-danger w-24">{{ $t('views.delete') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<style scoped>
  .cursor{
    cursor: pointer !important;
  }

  .btn-primary {
    background: #6c869a !important;
    border-color: #6c869a !important;
  }
</style>

<style>
  .tom-select .ts-dropdown .option.active{
    background: #6c869a !important;
  }
  .top-bar .breadcrumb > a:not(.breadcrumb--active){
    color: #6c869a !important;
  }
</style>

<style>
</style>

<script>
import LabService from "/src/services/lab.service";
import _ from 'lodash';
export default {
  setup() {
  },
  data() {
    return {
      labParams: [],
      searchInput: "",
      quizToSearch:0,
      categories:0,
      loading : true,
    }
  },
  watch:{
    searchInput: _.debounce(function (newSearch, oldSearch) {
      this.getLabItems();
    }, 500),
    quizToSearch: function (newSearch, oldSearch) {
      this.getLabItems();
      console.log("quizToSearch", this.quizToSearch);
    },
    categories: function (newSearch, oldSearch) {
      this.getLabItems();
    },
    '$i18n.locale': function(l) {
      this.getLabItems();
    }
  },
  created(){
    window.vue = this
    this.getLabItems();
  },
  methods:{
    quizToSearchFunc(a){
      console.log("quizToSearchFunc", a);
    },
    getLabItems(){

      this.categoryTitle = "";
      var params = {
        search: this.searchInput ? this.searchInput.replaceAll(" ", "_") : null,
        quiz_id: null, 
        //cambiar a categories cuando sea un array, pasarlo asi: JSON.stringify(this.categories);
        category: this.categories!="0" ? this.categories : null
      }

      if (this.quizToSearch && this.quizToSearch != 0) {
        params.quiz_id = this.quizToSearch
      }


      var _this = this;
      var categoryTitle = "";
      LabService.getLabItems(params).then((data)=>{
        this.loading = false
        data.forEach(element => {
          element.key = element.key.replaceAll("_", " ");
          element.key = element.key.charAt(0).toUpperCase() + element.key.slice(1);
          element.showCategory = categoryTitle != element.category;
          categoryTitle = element.category;
        });


        this.labParams = data
      }).catch(function(e){
        _this.loading = false
      });
    },
    editLabItem(labItem){
      localStorage.setItem('labItem', JSON.stringify(labItem));
      this.$router.push({ path: '/lab-analaysis-edit' });
    }
  }
}
</script>
