<template>
  <div :id="id" class="modal overflow-y-auto flex" data-backdrop="static" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body px-5 py-8 p-10 text-center" >
                  <div class="text-center">
                      <XCircleIcon v-if="showIcon" class="feather feather-x-circle w-16 h-16 mx-auto " />
                      <div class="text-white mt-5" v-html="messageInstance"></div>
                      <div v-if="form" class="grid grid-cols-2 mt-8 gap-8">
                        <input class="form-control pr-10 placeholder-theme-13 w-full mr-3" v-on:keyup="showError = false" v-model="model.input" placeholder="Cantidad"  />
                        <TomSelect
                          id="serving_unit"
                          ref="serving-unit"
                          class="w-full ml-3"
                          v-model="model.select"
                          v-on:change="showError = false"
                          :placeholder="$t('components.select_unit')"
                        >
                          <option class="text-left" value="0">{{ $t('components.select_unit') }}</option>
                          <option class="text-left" v-for="(item, key) in options" :key="key" :value="item.id">{{ item.value }}</option>
                        </TomSelect>
                      </div>

                      <div v-if="formEnergy" class="grid grid-cols-2 mt-8 gap-8">
                        <div class="input-group w-full">
                          <input class="form-control placeholder-theme-13 w-full focus-none" v-on:keyup="showError = false" v-model="model.input" placeholder="Cantidad"  />
                          <div class="input-group-text border-0 border-transparent">kcal</div>
                        </div>
                        <div class="input-group w-full">
                          <input class="form-control placeholder-theme-13 w-full focus-none" v-on:keyup="showError = false" v-model="model.select" placeholder="Porcentaje"  />
                          <div class="input-group-text border-0 border-transparent">%</div>
                        </div>                        
                        
                      </div>

                      <div v-if="(form || formEnergy) && validate">
                        <div v-if="showError" class="text-theme-6 mt-2">
                            {{ errorMsg }}
                          </div>
                      </div>
                  </div>
                  <div v-if="showBtnConfirm || showBtnCancel || showBtnClose" class="pt-8 flex justify-center gap-8">
                    <button
                      v-if="showBtnConfirm"
                      @click="accept"
                      type="button"
                      class="btn btn-primary w-24"
                    >
                      <span>{{ acceptText }}</span>
                    </button>
                    <button
                      v-if="showBtnCancel"
                      @click="hide"
                      type="button"
                      class="btn btn-outline-dark w-24"
                    >
                      <span>{{ cancelText }}</span>
                    </button>
                    <button
                      v-if="showBtnClose"
                      @click="hide"
                      type="button"
                      class="btn btn-outline-dark w-24"
                    >
                      <span>{{ $t('components.close') }}</span>
                    </button>
                  </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>
  .dark .modal-body{
    --tw-border-opacity: 1;
    border-color: rgba(49, 58, 85, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));

  }

  .modal.show.myAuto > .modal-dialog,  #alert-message > .modal-dialog {
      margin-top: auto;
      margin-bottom: auto;
  }

</style>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {

  },
  props : ["message", "id_alert"],
  data () {
    return {
      messageInstance : "",
      confirm : function(){
          // console.log("confirm")
      },
      showBtnConfirm : false,
      showBtnCancel : false,
      showBtnClose : false,
      showIcon : true,
      model : {
        input : "",
        select : 0
      },
      options : [],
      form : false,
      formEnergy : false,
      id : "alert-message",
      errorMsg : "",
      validate : false,
      showError : false,
      cancelWarning : function(){
          // console.log("cancel")
      },
      acceptText : "Aceptar",
      cancelText : "Cancelar"
    }
  },
  mounted(){
    this.messageInstance = this.message
    this.id = (this.id_alert) ? this.id_alert : this.id
  },
  methods: {
    show(element, showBtnConfirm = false, showBtnCancel = false, accept = null, showIcon = true, options = [], values = {}, form = false, errorMsg = "", validate = false, showBtnClose = false, formEnergy = false, cancelWarning = null, acceptText = null, cancelText = null){
      this.messageInstance = element


      cash('#'+this.id).modal('show')
      this.showBtnConfirm = showBtnConfirm
      this.showBtnCancel = showBtnCancel
      this.showBtnClose = showBtnClose
      this.showIcon = showIcon
      this.options = options
      this.form = form 
      this.formEnergy = formEnergy
      if((form || formEnergy) && values){
        this.model.input = values.input
        this.model.select = values.select ? values.select.toString() : ""
      }
      if(accept){
        this.confirm = accept
      }
      if(cancelWarning){
        this.cancelWarning = cancelWarning
      }
      this.errorMsg = errorMsg
      this.validate = validate
      this.acceptText = acceptText || this.$t('components.accept')
      this.cancelText = cancelText || this.$t('components.cancel')
    },
    hide(){
      cash('#'+this.id).modal('hide')
      cash('#'+this.id).modal('destroy')
      this.showError = false
      this.cancelWarning()
    },
    accept(){
      if(this.form || this.formEnergy){
        if(this.validate){
          if(this.model.input === "" || this.model.select === "0"){
            this.showError = true
          }else{
            this.confirm(this.model)  
          }
        }else{
          this.confirm(this.model)
        }
      }else
        this.confirm()
    }
  }
})
</script>
