import Dropzone from 'dropzone'

const init = (el, props) => {
  Dropzone.autoDiscover = false
  el.dropzone = new Dropzone(el, props.options)
  el.dropzone.on("addedfile", file => {
    // props.events.addedFile(file)
  });
  el.dropzone.on("removedfile", file => {
    props.events.removedFile(file)
  });
  el.dropzone.on("complete", function(file) {
    if(file.status === "error"){
      if(file.size > 2000000){
        props.events.showMessage(file)
      }
      setTimeout(function(){
        el.dropzone.removeFile(file)
      }, 2000)
    }
    if(file.status === "success"){
      props.events.addedFile(file)
    }
  });
  props.existFiles.forEach(element => {
    let mockFile = { name: element.name, size: element.size };
    let callback = null;
    let crossOrigin = null;
    let resizeThumbnail = false;
    el.dropzone.displayExistingFile(mockFile, element.link, callback, crossOrigin, resizeThumbnail);
  })

}

export { init }
