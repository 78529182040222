import ApiService from './api.service'

const AIService = {

    generateContent: async function(params) {

        const response = await ApiService.post("/api/ai/generate-content", params);

        return response.data

    },

}

export default AIService
