import ApiService from './api.service'

const FoodService = {

    getFoodItems: async function(params, lang) {

        const response = await ApiService._get("/api/admin/food", params, lang);
        return response.data

    },

    getFood: async function(id, lang = null) {

        const response = await ApiService.get("/api/admin/food/"+id, lang);
        return response.data

    },

    getCategories: async function(lang = null) {

        const response = await ApiService.get("/api/admin/food/categories", lang);
        return response.data

    },

    getCategoriesTree: async function(lang = null) {

        const response = await ApiService.get("/api/admin/food/categories-tree", lang);
        return response.data

    },

    createFood: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/food/create", params, lang);
      return response.data

    },

    saveFood: async function(params, lang = null) {

        const response = await ApiService.post("/api/admin/food/save", params, lang);
        return response.data

    }

}

export default FoodService
