<template>
  <AlertMessage ref="alertMessageDenied" class="dark" v-bind:message="'<p></p>'" />
  <div :id="id" class="modal dark" data-backdrop="static" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div
                  class="progress-bar h-1 rounded"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="{
                    'width': `${((securityCheckpoint.secondsRemainding / securityCheckpoint.secondsToValidate) * 100)}%`,
                    'background': `rgb(${(108+(147-((securityCheckpoint.secondsRemainding / securityCheckpoint.secondsRemainding) * 147))).toFixed(0)} ${((securityCheckpoint.secondsRemainding / securityCheckpoint.secondsRemainding) * 134).toFixed(0)} ${((securityCheckpoint.secondsRemainding / securityCheckpoint.secondsRemainding) * 154).toFixed(0)})`
                  }"
                ></div>
                <div class="modal-body px-5 py-10">
                    <div class="text-center">
                        <h2 @click="securityCheckpoint.startTimer" class="intro-y text-lg font-medium">
                          <span v-if="messageData.timeout === 0">{{ $t('components.verify_admin.text1') }}</span>
                          <span v-else>{{ messageData.title }}</span>
                        </h2>
                        <p class="w-96 m-auto"> 
                          <span v-if="messageData.timeout === 0">{{ $t('components.verify_admin.text2') }} </span>
                          <span v-else> {{ messageData.text }} </span>
                        </p>
                        <div class="input-group mt-3">
                          <input
                            id="crud-form-4"
                            type="number"
                            class="form-control placeholder-gray-500"
                            v-bind:placeholder="messageData.timeout === 0 ? $t('components.verify_admin.text3') : messageData.placeholder"
                            aria-describedby="input-group-2"
                            v-model="securityCheckpoint.pin"
                            @change="securityCheckpoint.errorMessage = ''"
                            v-on:keypress.enter="securityCheckpoint.send"
                            v-bind:disabled="securityCheckpoint.isLoading"
                          />
                          <div id="input-group-2" class="input-group-text">
                            <button v-bind:disabled="securityCheckpoint.isLoading" @click="securityCheckpoint.send" type="button" class="btn btn-primary w-24">
                              <Tippy v-if="!securityCheckpoint.isLoading" data-theme="light" v-bind:content="$t('components.verify_admin.text4')">
                                {{ $t('components.verify_admin.text4') }}
                              </Tippy>
                              <Tippy v-if="securityCheckpoint.isLoading" data-theme="light" v-bind:content="$t('components.verify_admin.text5')">
                                <LoadingIcon icon="tail-spin" class="text-white w-3 h-3" color="white" />
                              </Tippy>
                            </button>
                          </div>
                        </div>
                        <Tippy v-if="securityCheckpoint.errorMessage" class="ml-1 mt-2" style="float: left;color: red;" data-theme="light" content=securityCheckpoint.errorTip>
                          {{securityCheckpoint.errorMessage}}
                        </Tippy>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dark .modal-body{
    --tw-border-opacity: 1;
    border-color: rgba(49, 58, 85, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));

  }

  .dark #crud-form-4{
    color: rgb(226, 232, 240) !important;
  }

  #crud-form-4::placeholder{
    color: black;
  }

  .dark #crud-form-4::placeholder{
    color: rgb(226, 232, 240) !important;
  }
  #access-denied-total.show > #access-denied-total-dialog {
      margin-top: auto;
      margin-bottom: auto;
  }
  #access-denied-total  .modal-body.check{
      background-color: #541111;
      color: #f0040a;
      border-color: #6f0305
  }
  #access-denied-total{
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    z-index: 10000;
  }
  #access-denied-total .modal-dialog .modal-content {
    background-color: transparent;
  }
</style>

<script>
import { defineComponent, ref, computed } from 'vue'
import UserService from "/src/services/user.service";
import Toastify from "toastify-js";
import { TokenService } from "../../services/storage.service"
import { useStore } from '@/store'
import AlertMessage from "@/components/alert/Main.vue"

export default defineComponent({
  components:{
    AlertMessage
  },
  setup() {
  },
  computed: {
    authError () {
      const authError = computed(() => useStore().state.main.authError).value
      return authError
    },
    verifyData () {
      const verifyData = computed(() => useStore().state.main.verifyData).value
      return verifyData
    },
    accessDenied () {
      const accessDenied = computed(() => useStore().state.main.accessDenied).value
      return accessDenied
    },
    accessDeniedMessage () {
      const accessDeniedMessage = computed(() => useStore().state.main.accessDeniedMessage).value
      return accessDeniedMessage
    }
  },
  watch: {
    authError (newauthError) {
      if(newauthError){
        localStorage.removeItem('lastSecurityCheckpoint');
      }
    },
    verifyData (newData) {
      this.messageData = newData
      if(newData.timeout !== 0){
        this.securityCheckpoint.secondsToValidate = newData.timeout
        this.securityCheckpoint.secondsRemainding = newData.timeout
      }
    },
    accessDenied () {
      if(this.accessDenied){
        cash("#"+this.$props.id).modal('hide')
        this.$refs.alertMessageDenied.show(this.accessDeniedMessage, true, false, this.exit)
          
      }
    },

  },
  props : ['check', 'id'],
  data () {
    return {
      messageData: {timeout : 0},
      securityCheckpoint: {
        defaultSeconds: 180,
        secondsToValidate: 180,
        secondsRemainding: 180,
        isLoading : false,
        attempts: 0,
        maxAttempts: 3,
        pin: "",
        errorMessage: "",
        send: (params)=>{
          this.securityCheckpoint.errorMessage = ""
          this.securityCheckpoint.isLoading = true
          UserService.verifyAdmin({
            "2FA": this.securityCheckpoint.pin
          }).then((response)=>{

            this.securityCheckpoint.isLoading = false
            this.securityCheckpoint.pin = ""

            if (response.valid) {
              this.securityCheckpoint.secondsToValidate = this.securityCheckpoint.defaultSeconds
              this.securityCheckpoint.secondsRemainding = this.securityCheckpoint.secondsToValidate
              localStorage.lastSecurityCheckpoint = (new Date()).getTime()
              clearInterval(this.securityCheckpoint.timer)
              this.securityCheckpoint.timer = null
              this.securityCheckpoint.attempts = 0
              this.securityCheckpoint.errorMessage = ``
              cash("#"+this.$props.id).modal('hide')


              useStore().dispatch('sideMenu/updateMenu', response.permissions)
              useStore().dispatch('main/setAuthError', false)
              useStore().dispatch('main/setVerifyData', {timeout: 0})

              if(this.$props.check){
                this.$router.push({ path: "/" })
              }

            } else {
              this.securityCheckpoint.attempts++
              if (this.securityCheckpoint.attempts < this.securityCheckpoint.maxAttempts) {
                this.securityCheckpoint.errorMessage = `${this.$t('components.verify_admin.text6')} (${this.securityCheckpoint.attempts}/${this.securityCheckpoint.maxAttempts})`
              } else {
                this.securityCheckpoint.errorMessage = `${this.$t('components.verify_admin.text6')}. Bye...`
                clearInterval(this.securityCheckpoint.timer)
                localStorage.clear();
                location.href = "/";
              }
            }


          }).catch((error)=>{

            this.securityCheckpoint.isLoading = false
            this.securityCheckpoint.errorMessage = `${this.$t('components.verify_admin.text7')}`
            console.log(error);

          })
        },
        startTimer:()=>{
          if (!this.securityCheckpoint.timer) {
            this.securityCheckpoint.timer = setInterval(()=>{
                this.securityCheckpoint.secondsRemainding -= 1
                if (this.securityCheckpoint.secondsRemainding < 0) {
                  clearInterval(this.securityCheckpoint.timer)
                  localStorage.clear();
                  location.href = "/";
                }
            }, 1000)
          }
        }
      }
    }
  },
  mounted(){
    if(!this.$props.check)
      this.init()
  },
  methods: {
    init(){
      cash("#"+this.$props.id).modal('hide')
      var local = localStorage.getItem("local")
      setInterval(() => {
        var isVerify = TokenService.isVerify()
          if (isVerify) {
            cash("#"+this.$props.id).modal('hide')
          } else {
            if(!local){
              cash("#"+this.$props.id).modal('show')
              this.securityCheckpoint.startTimer()
            }
        }
      }, 1000);
    },
    exit(){
      this.$refs.alertMessageDenied.hide()
      localStorage.clear();
      window.location.href = process.env.VUE_APP_CLIENT_URL
    }
  }
})
</script>
