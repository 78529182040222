<template>
  <div>
    <div id="2fa-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">Validación completada</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <VerifyAdmin v-bind:id="'security-checkpoint'" />
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ path: '/' }"
          tag="a"
          class="intro-x flex items-center pl-5 pt-4"
        >
          <img
            alt="ilongevity"
            class="w-32 hidden xl:block"
            src="@/assets/images/ilong/logo_1.png"
          />

          <img
            alt="ilongevity"
            class="w-8 xl:hidden"
            src="@/assets/images/ilong/logo_2.png"
          />
          <!-- <span class="hidden xl:block text-white text-lg ml-3">
            ilongevity
          </span> -->
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child  -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey" :class="{ 'disabled-menu': menu.disabled }">
              <SideMenuTooltip
                tag="a"
                :content="$t(menu.title)"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" :icon="menu.iconName"/>
                </div>
                <div class="side-menu__title">
                  {{ $t(menu.title) }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="$t(subMenu.title)"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <component v-if="menu.icon" :is="subMenu.icon" :icon="subMenu.iconName"/>
                        <ActivityIcon v-else />
                      </div>
                      <div class="side-menu__title">
                         {{ $t(subMenu.title) }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<style>

.progress-bar{
  transition: width 1s linear 0s !important;
}

body{
  background: #131A29 !important;
}
html:not(.dark) input{
  color: #131A29 !important;
}
html:not(.dark) .side-nav > ul > li > .side-menu:hover:not(.side-menu--active):not(.side-menu--open) .side-menu__icon:before {
    background-color: rgba(255, 255, 255 var(--tw-bg-opacity));
}
.dark .content{
  background: #182033 !important;
}
.dark .side-nav > ul > li > .side-menu.side-menu--active {
  background-color: #182033 !important;
}
.dark .side-nav > ul > li > .side-menu.side-menu--active .side-menu__icon:before {
  background-color: #182033 !important;
}
.dark .table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  background-color: #1c2741 !important;
}
.dark .dark-mode-switcher .dark-mode-switcher__toggle.dark-mode-switcher__toggle--active {
  background-color: #131A29 !important;
}
.dark .dark-mode-switcher, .bg-theme-1.progress-bar {
    background: #6c869a !important;
    border-color: #6c869a !important;
}
.dark .box:not(.dark-mode-switcher) {
    --tw-bg-opacity: 1;
    background-color: #131A29 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import VerifyAdmin from '@/components/verify-admin/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import UserService from "/src/services/user.service";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip,
    VerifyAdmin
  },
  data(){
    return {

    }
  },
  mounted(){
  },
  created(){

    useStore().dispatch('sideMenu/updateMenu', []);

  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>{
      return nestedMenu(store.state.sideMenu.menu, route)
    })
    

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    //get user permissions and build menu
    UserService.getUserPermissions().then(result =>{

      useStore().dispatch('sideMenu/updateMenu', result)
      formattedMenu.value = $h.toRaw(sideMenu.value)

    });


    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  }
})
</script>

<style scoped>
.disabled-menu {
 opacity: 0.25;
}
</style>
