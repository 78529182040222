<template>
  <div>
    <div
      id="tiny-slider-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mx-6">
              <div v-html="recomendations" class="center-mode"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="save-supplement-success" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.supplements.text9') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.supplements.text10') }}: {{ lang_inputs[global_lang].name }}
      </h2>
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
            <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__'+lang.key)" :data-target="'#lang__'+lang.key" href="javascript:;" class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md" :class="(activeLang === 'lang__'+lang.key) ? 'active' : ''" :id="'lang__'+lang.key+'-tab'" role="tab" :aria-controls="'lang__'+lang.key" aria-selected="false"> {{ $t('components.langs.'+lang.key+'.name') }} </a>
        </div>
        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__'+lang.key" class="tab-content">
          <div :id="'lang__'+lang.key" class="tab-pane " :class="(activeLang === 'lang__'+lang.key) ? 'active' : ''" role="tabpanel" :aria-labelledby="'lang__'+lang.key+'-tab'">

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5">
              <div>
                <label for="crud-form-1" class="form-label">{{ $t('views.supplements.text5') }} </label>
                <div class="input-group">
                  <input
                    id="crud-form-1"
                    type="text"
                    name="chart-tooltip"
                    class="form-control w-full"
                    :placeholder="$t('views.supplements.text5')"
                    v-model="lang_inputs[lang_key].name"
                  />
                </div>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t('views.supplements.text11') }}
                </div>
              </div>

              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text12') }}</label>
                <div class="input-group">
                  <TomSelect
                    ref="category"
                    class="w-full cursor"
                    v-model="supplementCategories"
                    :max="5"
                    :placeholder="$t('views.supplements.text13')"
                    :plugins="['dropdown_input']"
                  >
                    <option value="">{{ $t('views.supplements.text13') }}</option>
                    <option
                      v-for="(cat, key) in categories"
                      :key="key"
                      :value="cat.id"
                    >
                      {{ cat.name }}
                    </option>
                  </TomSelect>
                </div>
                <div v-if="validations.category" class="text-theme-6 mt-2">
                  {{ $t('views.supplements.text11') }}
                </div>
              </div>

              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text46') }}</label>
                <div class="input-group">
                  <TomSelect
                    ref="brands"
                    class="w-full cursor"
                    v-model="supplement.brands"
                    :max="5"
                    :placeholder="$t('views.supplements.text48')"
                    :plugins="['dropdown_input']"
                  >
                    <option value="">{{ $t('views.supplements.text48') }}</option>
                    <option
                      v-for="(brand, key) in brands"
                      :key="key"
                      :value="brand.id"
                    >
                      {{ brand.name }}
                    </option>
                  </TomSelect>
                </div>
                <div v-if="validations.brands" class="text-theme-6 mt-2">
                  {{ $t('views.supplements.text11') }}
                </div>
              </div>
              <div class="mt-3">
                <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text7' )}}</label>
                <div class="input-group">
                  <input
                    id="crud-form-3"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.supplements.text14')"
                    aria-describedby="input-group-1"
                    v-model="lang_inputs[lang_key].description"
                  />
                </div>
              </div>
              <hr class="my-10">
              <div class="mt-3">
                <label for="crud-form-3" class="form-label font-semibold text-base">{{ $t('views.supplements.text45' )}}</label>
                <div class="input-group benefits_item mt-3" v-for="(item, key) in supplement.benefits" :key="key">
                  <input
                    id="crud-form-3"
                    type="text"
                    class="form-control"
                    :placeholder="$t('views.supplements.text14')"
                    aria-describedby="input-group-1"
                    v-model="supplement.benefits[key]"
                    @blur="addBenefit()"
                    v-on:keyup.enter="addBenefit()"
                  />
                  <button
                      v-on:click="removeBenefit(item)"
                      type="button"
                      class="btn-outline-dark btn-rounded btn-sm remove-item"
                    >
                      <XCircleIcon class="btn-source-icon" />
                  </button>
                </div>

              </div>
              <hr class="my-10">
              <div class="">
                <label for="crud-form-3" class="form-label font-semibold text-base">{{ $t('views.supplements.text15') }}</label>
                <div class="flex flex-col sm:flex-row items-center gap-5">
                  <div class=" w-full">
                    <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text16') }}</label>
                    <TomSelect
                      ref="dose_modality"
                      class="w-full cursor without_search"
                      v-model="dose.modality"
                      :input_hide="true"
                      v-on:change="validate"
                    >
                      <option value="">{{ $t('views.supplements.text17') }}</option>
                      <option value="SPECIFIC">{{ $t('views.supplements.text18') }}</option>
                      <option value="RANGE">{{ $t('views.supplements.text19') }}</option>
                    </TomSelect>
                    <div v-if="validations.range || validations.value" class="text-theme-6 mt-2 transparent">
                      {{ $t('views.supplements.text11') }}
                    </div>
                  </div>
                  <div class=" w-full" v-if="dose.modality == 'SPECIFIC'">
                    <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text20') }}</label>
                    <input
                      id="crud-form-3"
                      type="number"
                      min="0"
                      class="form-control form-control-lg w-full text-sm-14"
                      :placeholder="$t('views.supplements.text20')"
                      aria-describedby="input-group-1"
                      v-model="dose.min"
                    />
                    <div v-if="validations.range || validations.value" class="text-theme-6 mt-2">
                      {{ $t('views.supplements.text21') }}
                    </div>
                  </div>
                  <div class="w-full" v-else>
                    <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text22') }}</label>
                    <div class="input-group w-full">
                      <input
                        id="crud-form-3"
                        type="number"
                        min="0"
                        class="form-control form-control-lg text-sm-14"
                        :placeholder="$t('views.supplements.text23')"
                        aria-describedby="input-group-1"
                        v-model="dose.min"
                      />
                      <div class="input-group-text" style="border: solid #293144">
                        {{ $t('views.supplements.text44') }}
                      </div>
                      <input
                        id="crud-form-3"
                        type="number"
                        min="0"
                        class="form-control form-control-lg text-sm-14"
                        :placeholder="$t('views.supplements.text24')"
                        aria-describedby="input-group-1"
                        v-model="dose.max"
                      />
                    </div>
                    <div v-if="validations.range || validations.value" class="text-theme-6 mt-2">
                      {{ $t('views.supplements.text21') }}
                    </div>
                  </div>
                  <div class="w-full">
                    <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text25') }}</label>
                    <TomSelect
                      ref="dose_unit"
                      class="w-full cursor"
                      v-model="dose.unit"
                      :input_hide="true"
                    >
                      <option value="">{{ $t('views.supplements.text26') }}</option>
                      <option value="g">{{ $t('views.units.g') }}</option>
                      <option value="mg">{{ $t('views.units.mg') }}</option>
                      <option value="mcg">{{ $t('views.units.mcg') }}</option>
                      <option value="UI">{{ $t('views.units.UI') }}</option>
                      <option value="gotas">{{ $t('views.units.gotas') }}</option>
                    </TomSelect>
                    <div v-if="validations.range || validations.value" class="text-theme-6 mt-2 transparent">
                      {{ $t('views.supplements.text11') }}
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-10">
              <div class="">
                <label for="crud-form-3" class="form-label font-semibold text-base">{{ $t('views.supplements.text28') }}</label>
                <div class="input-group gap-5">
                  <div class="w-50">
                    <label for="crud-form-3" class="form-label"
                      >Cuando</label
                    >
                    <TomSelect
                      ref="shedule"
                      class="w-full cursor"
                      v-model="schedule.form"
                      :placeholder="$t('views.supplements.text30')"
                      :input_hide="true"
                      v-on:change="validate"
                    >
                      <option value="">{{ $t('views.supplements.text30') }}</option>
                      <option v-for="(option, key) in posologies" :key="key" :value="option">{{ $t('views.posology.'+option) }}</option>
                      <!-- <option :value="$t('views.supplements.text32')">{{ $t('views.supplements.text32') }}</option>
                      <option :value="$t('views.supplements.text33')">{{ $t('views.supplements.text33') }}</option>
                      <option :value="$t('views.supplements.text34')">{{ $t('views.supplements.text34') }}</option>
                      <option :value="$t('views.supplements.text35')">{{ $t('views.supplements.text35') }}</option>
                      <option :value="$t('views.supplements.text36')">{{ $t('views.supplements.text36') }}</option>
                      <option :value="$t('views.supplements.text37')">{{ $t('views.supplements.text37') }}</option>
                      <option :value="$t('views.supplements.text38')">{{ $t('views.supplements.text38') }}</option> -->
                    </TomSelect>
                  </div>
                  
                  <div class=" w-50"
                      v-if="
                        schedule.form == $t('views.supplements.text31') ||
                        schedule.form == $t('views.supplements.text32')
                      ">
                    <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text39') }}</label>
                    <input
                      id="crud-form-3"
                      type="number"
                      min="0"
                      class="form-control w-full text-sm-14"
                      :placeholder="
                        schedule.form != $t('views.supplements.text31') &&
                        schedule.form != $t('views.supplements.text32')
                          ? $t('views.supplements.text40')
                          : $t('views.supplements.text39')
                      "
                      aria-describedby="input-group-1"
                      v-model="schedule.count"
                      :disabled="
                        schedule.form != $t('views.supplements.text31') &&
                        schedule.form != $t('views.supplements.text32')
                      "
                    />
                    <div v-if="validations.times" class="text-theme-6 mt-2">
                      {{ $t('views.supplements.text21') }}
                    </div>
                  </div>
                </div>

              </div>
              <hr class="my-10">



              <div class="">
                <label for="crud-form-3" class="form-label font-semibold text-base">Interacciones</label>
                <div class="input-group gap-5">
                  <div class="w-50">
                    <label for="crud-form-3" class="form-label">Suplementos</label>
                    <TomSelect
                      ref="int-supplements"
                      class="w-full cursor"
                      v-model="interactions.supplement"
                      :placeholder="'Suplementos'"
                      :input_hide="true"
                      v-on:change="addInteraction('supplements', 'supplement')"
                    >
                      <option value="0">{{ 'Suplementos' }}</option>
                      <option v-for="(supplement, key) in interactions.supplements" :key="key" :disabled="supplement.active" :value="supplement.id">{{ supplement.name }}</option>
                    </TomSelect>

                    <div class="flex flex-wrap gap-4 my-6">
                      <div class="px-4 py-2 form-control w-max flex items-center" v-for="(supplement, key) in interactions.supplements.filter(s => s.active)" :key="key">
                        <span class="block w-max">{{ supplement.name }}</span>
                        <button
                          v-on:click="removeInteraction(supplement, 'supplements')"
                          type="button"
                          class="btn-outline-dark p-0 ml-3 btn-rounded btn-sm remove-item"
                        >
                          <XCircleIcon class="btn-source-icon" />
                      </button>
                      </div>
                    </div>
                  </div>

                  <div class="w-50">
                    <label for="crud-form-3" class="form-label"
                      >Enfermedades</label
                    >
                    <TomSelect
                      ref="int-supplements"
                      class="w-full cursor"
                      v-model="interactions.sickn"
                      :placeholder="'Enfermedades'"
                      :input_hide="true"
                      v-on:change="addInteraction('sickness', 'sickn')"
                    >
                      <option value="0">{{ 'Enfermedades' }}</option>
                      <option v-for="(sickn, key) in interactions.sickness" :key="key" :disabled="sickn.active" :value="sickn.id">{{ sickn.name }}</option>
                    </TomSelect>

                    <div class="flex flex-wrap gap-4 my-6">
                      <div class="px-4 py-2 form-control w-max flex items-center" v-for="(sickn, key) in interactions.sickness.filter(s => s.active)" :key="key">
                        <span class="block w-max">{{ sickn.name }}</span>
                        <button
                          v-on:click="removeInteraction(sickn, 'sickness')"
                          type="button"
                          class="btn-outline-dark p-0 ml-3 btn-rounded btn-sm remove-item"
                        >
                          <XCircleIcon class="btn-source-icon" />
                      </button>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="">
                  <label for="crud-form-3" class="form-label font-semibold">Medicamentos</label>
                  <div class="grid gap-6">
                    <div class="border-custom-gray-8 border-1 rounded-lg p-6" v-for="(medicine, key) in interactions.medicines" :key="key">
                      <div class="input-group gap-5">
                        <div class="w-1/3">
                          <label for="crud-form-3" class="form-label">Familia de medicamentos</label>
                          <TomSelect
                            ref="int-medicines"
                            class="w-full cursor"
                            v-model="medicine.class_id"
                            :placeholder="'Seleccione'"
                            :input_hide="true"
                            v-on:change="addMedicine(medicine)"
                          >
                            <option value="0">{{ 'Seleccione' }}</option>
                            <option v-for="(m, key) in medicines" :key="key" :value="m.class_id">{{ m.name }}</option>
                          </TomSelect>
                        </div>
                        <div class="w-1/3">
                          <label for="crud-form-3" class="form-label">Nombre genérico</label>
                          <TomSelect
                            ref="int-medicines-list"
                            class="w-full cursor"
                            v-model="medicine.generic"
                            :placeholder="'Seleccione'"
                            :input_hide="true"
                            v-on:change="addMedicineItem(medicine)"
                          >
                            <option value="0">{{ 'Seleccione' }}</option>
                            <option v-for="(item, key) in medicine.medication_class_drugs" :key="key" :disabled="item.active" :value="item.id">{{ item.name }}</option>
                          </TomSelect>
                        </div>
                        <div class="w-1/3">
                          <label for="crud-form-3" class="form-label">Nivel</label>
                          <TomSelect
                            ref="int-medicines-list"
                            class="w-full cursor"
                            v-model="medicine.level"
                            :placeholder="'Seleccione'"
                            :input_hide="true"
                          >
                            <option value="0">{{ 'Seleccione' }}</option>
                            <option v-for="(item, key) in levels" :key="key" :value="item.value">{{ item.name }}</option>
                          </TomSelect>
                        </div>
                        <div class="w-auto pr-2 flex justify-end items-start">
                          <button
                              v-on:click="removeMedicine(medicine, key)"
                              type="button"
                              class="btn-outline-dark p-0 btn-rounded btn-md remove-item"
                            >
                              <XCircleIcon class="btn-source-icon w-6 h-6" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="flex flex-wrap gap-4 mt-6">
                          <div class="px-4 py-2 form-control w-max flex items-center" v-for="(m, key) in medicine.medication_class_drugs.filter(s => s.active)" :key="key">
                            <span class="block w-max">{{ m.name }}</span>
                            <button
                              v-on:click="removeMedicineItem(medicine, m)"
                              type="button"
                              class="btn-outline-dark p-0 ml-3 btn-rounded btn-sm remove-item"
                            >
                              <XCircleIcon class="btn-source-icon" />
                          </button>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4">
                        <label for="crud-form-3" class="form-label">Mecanismo de acción</label>
                        <div class="input-group">
                          <textarea
                            id="crud-form-3"
                            type="text"
                            class="form-control"
                            :placeholder="'Mecanismo de accion'"
                            aria-describedby="input-group-1"
                            rows="5"
                            v-model="medicine.action_mechanism"
                            maxlength="260"
                          />
                        </div>
                      </div>
                      <div class="mt-6">
                        <label for="crud-form-3" class="form-label">Descripción</label>
                        <div class="input-group">
                          <textarea
                            id="crud-form-3"
                            type="text"
                            class="form-control"
                            :placeholder="'Descripción'"
                            aria-describedby="input-group-1"
                            rows="5"
                            v-model="medicine.description"
                            maxlength="260"
                          />
                        </div>
                      </div>
                      <div class="mt-6">
                        <label for="crud-form-3" class="form-label">{{ $t('views.supplements.text41') }}</label>
                        <div class="input-group w-full">
                        <input
                          id="crud-form-1"
                          type="text"
                          name="chart-tooltip"
                          class="form-control "
                          :placeholder="$t('views.supplements.text42') +': https://www.medicalresearch.com/sources/evidence.pdf'"
                          aria-describedby="input-group-price"
                          v-model="medicine.source"
                          v-on:keyup="event => { medicineSourceAdd(event, medicine) }"
                          @focusout="event => { medicineSourceAdd(event, medicine) }"
                        />
                      </div>
                      </div>
                      <div class="mt-5" v-if="medicine.sources.length > 0">
                        <div
                          class="flex items-center"
                          v-for="(val, key) in medicine.sources"
                          :key="key"
                          :value="val.id"
                        >
                        <div class="items-center w-full relative" v-if="val.edit == false">
                          <div class="intro-x" >
                            <div class="box border px-5 mb-3 flex items-center">
                                <div class="flex h-10 items-center justify-center">
                                    <!-- <div class="w-2 h-2 bg-theme-13 rounded-full mr-3"></div> -->
                                    <LinkIcon class="btn-source-icon" />
                                </div>
                                <div class="ml-4 mr-auto">
                                    <div class="font-medium">{{ val.source }}</div>
                                </div>
                                <button
                                  v-on:click="val.edit = true"
                                  type="button"
                                  class="ml-5 btn-outline-dark btn-rounded btn-sm"
                                >
                                  <EditIcon class="btn-source-icon" />
                                </button>
                                <button
                                  v-on:click="medicineSourceDelete(val, medicine)"
                                  type="button"
                                  class="btn-outline-dark btn-rounded btn-sm"
                                >
                                  <Trash2Icon class="btn-source-icon" />
                                </button>
                            </div>
                          </div>
                        <div class="absolute" style="width : 80%; height: 100%; top: 0; left: 0; cursor: pointer; z-index: 999" @click="goSource(val.source)"></div>
                        </div>
                        <div class="block w-full" v-else>
                          <div class="input-group w-full mb-3">
                              <input
                                id="crud-form-1"
                                type="text"
                                name="chart-tooltip"
                                class="form-control "
                                :placeholder="$t('views.supplements.text42') + ': https://www.medicalresearch.com/sources/evidence.pdf'"
                                aria-describedby="input-group-price"
                                v-model="val.source"
                              />
                              <div id="input-group-price" class="input-group-text btn" @click="medicineSourceEdit(val, medicine)">
                                <SaveIcon />
                              </div>
                          </div>
                          <div v-if="val.error" class="text-theme-6 mt-2">
                            {{ $t('views.supplements.text43') }}
                          </div>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <hr class="my-10">


              <div class="">
                <label for="crud-form-3" class="form-label font-semibold text-base"
                  >{{ $t('views.supplements.text41') }}</label
                >
                <div class="input-group w-full">
                  <input
                    id="crud-form-1"
                    type="text"
                    name="chart-tooltip"
                    class="form-control "
                    :placeholder="$t('views.supplements.text42') +': https://www.medicalresearch.com/sources/evidence.pdf'"
                    aria-describedby="input-group-price"
                    v-model="source"
                    v-on:keyup="sourceAdd"
                    @focusout="sourceAdd"
                  />
                  <div id="input-group-price" class="input-group-text btn" @click="sourceAdd">
                    <PlusIcon />
                  </div>
                </div>
                <div v-if="validations.sources" class="text-theme-6 mt-2">
                  {{ $t('views.supplements.text43') }}
                </div>
                <div class="mt-5" v-if="sources.length > 0">
                  <div
                    class="flex items-center"
                    v-for="(val, key) in sources"
                    :key="key"
                    :value="val.id"
                  >
                  <div class="items-center w-full relative" v-if="val.edit == false">
                    <div class="intro-x" >
                      <div class="box border px-5 mb-3 flex items-center">
                          <div class="flex h-10 items-center justify-center">
                              <!-- <div class="w-2 h-2 bg-theme-13 rounded-full mr-3"></div> -->
                              <LinkIcon class="btn-source-icon" />
                          </div>
                          <div class="ml-4 mr-auto">
                              <div class="font-medium">{{ val.link }}</div>
                          </div>
                          <button
                            v-on:click="val.edit = true"
                            type="button"
                            class="ml-5 btn-outline-dark btn-rounded btn-sm"
                          >
                            <EditIcon class="btn-source-icon" />
                          </button>
                          <button
                            v-on:click="sourceDelete(val)"
                            type="button"
                            class="btn-outline-dark btn-rounded btn-sm"
                          >
                            <Trash2Icon class="btn-source-icon" />
                          </button>
                      </div>
                    </div>
                  <div class="absolute" style="width : 80%; height: 100%; top: 0; left: 0; cursor: pointer; z-index: 999" @click="goSource(val.link)"></div>
                  </div>
                  <div class="block w-full" v-else>
                    <div class="input-group w-full mb-3">
                        <input
                          id="crud-form-1"
                          type="text"
                          name="chart-tooltip"
                          class="form-control "
                          :placeholder="$t('views.supplements.text42') + ': https://www.medicalresearch.com/sources/evidence.pdf'"
                          aria-describedby="input-group-price"
                          v-model="val.link"
                        />
                        <div id="input-group-price" class="input-group-text btn" @click="sourceEdit(val)">
                          <SaveIcon />
                        </div>
                    </div>
                    <div v-if="val.error" class="text-theme-6 mt-2">
                      {{ $t('views.supplements.text43') }}
                    </div>
                  </div>
                  </div>

                </div>
              </div>
              <div class="text-right mt-5">
                <button
                  v-on:click="saveSupplement"
                  type="button"
                  class="btn btn-primary w-24"
                >
                  <LoadingIcon v-if="loading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.save') }}</span>
                </button>
              </div>
            </div>
            <!-- END: Form Layout -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}
.mt-3 {
  color: #fff;
}
/* .btn-primary {
  background: #6c869a !important;
  border-color: #6c869a !important;
} */

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}
.max-w-label {
  max-width: 90%;
}
.text-sm-14 {
  font-size: 0.875rem;
}
.btn-source-icon {
  width: 1rem;
}
.btn-source-icon.w-6 {
  width: 1.5rem;
}
.w-50 {
  width: 50%;
}
.my-custom-input{
  display: none !important;
}
.transparent{
  color: transparent;
}
.benefits_item:last-child .remove-item {
    visibility: hidden;
}
.benefits_item:nth-child(2){
  margin-top: 0px;
}
</style>
<script>
import SupplementService from "/src/services/supplements.service"
import MedicationService from "/src/services/medications.service";
import DiseaseService from "/src/services/diseases.service";
import Toastify from "toastify-js"

export default {
  setup() {},
  data() {
    return {
      validations: {
        name: false,
        sources: false,
        times : false,
        value : false,
        range : false
      },
      loading: false,
      recomendations: null,
      queryUrl: this.$route.query,
      hiddenKey: [
        "id",
        "id_api",
        "food_id",
        "updatedAt",
        "createdAt",
        "deletedAt"
      ],
      supplement: localStorage.supplementItem
        ? JSON.parse(localStorage.supplementItem)
        : {
            name: "",
            description: "",
            sources: "",
            dose: null,
            schedule: "",
            benefits: [""],
            brands: []
          },
      supplementCategories: [],
      categories: [],
      dose: {
        modality: "SPECIFIC",
        unit: "ml",
        min: null,
        max: null
      },
      sources: [],
      source: "",
      schedule : {
        form : "",
        count : null
      },
      activeLang: 'lang__'+(localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs : this.$global.getLangs(),
      lang_key: (localStorage.lang) ? localStorage.lang : (navigator.language).split("-")[0],
      global_lang: (localStorage.lang) ? localStorage.lang : (navigator.language).split("-")[0],
      lang_inputs : [],
      getData : true,
      brands: [],
      interactions : {
        supplement : "",
        sickn : "",
        medicine : "",
        supplements : [],
        sickness : [],
        medicines : [
          {
            id: '',
            class_id: '',
            name: '',
            level: '',
            action_mechanism : "", 
            description: "",
            generic: "",
            medication_class_drugs : [],
            source: "",
            sources: [],
            sources_deleted: []
            },
        ],
      },
      medicines : [],
      levels: [
        {value: "LOW", name: "Bajo"},
        {value: "MODERATE", name: "Moderado"},
        {value: "HIGH", name: "Alto"},
      ],
      drugs_exist : [],
      class_drug_exist : [],
      actual_interaction_supplement : [],
      actual_interaction_diseases : [],
      posologies : this.$global.getPosologyKey()
    }
  },
  watch: {
    supplementCategories: function () {
      var categories = this.categories
      this.supplementCategories.forEach(function (c) {
        var cat = categories.find(function (cc) {
          return cc.id == c
        })
        if (!cat) {
          categories.push({ id: c, name: c, new: true })
        }
      })
      this.categories = categories
    },
    '$i18n.locale': function(l) {
      this.getSupplement()
      this.getCategories()
      this.getAllSupplements()
      this.getAllMedicines()
      this.getAllDiseases()
    },
    activeLang: function(){
      this.lang_key = this.activeLang.replace("lang__", "")
    }
  },
  computed: {},
  created() {
    this.lang_inputs = this.langs.map(l => l.key).reduce((x, y)=> (x[y]={name : "", description: ""},x),{});
    this.lang_inputs[this.lang_key]["name"] = this.supplement["name"]
    this.getSupplement()
    this.getCategories()
    this.getBrands()
    this.getAllSupplements()
    this.getAllMedicines()
    this.getAllDiseases()
  },
  methods: {
    sourceAdd: function (event) {
      this.validations.sources = false
      if (this.source != "" && (event.key == "Enter" || event.pointerId || event.type == "focusout")) {
        if (this.isURLValid(this.source)) {
          this.sources.push({id : null, link : this.source, edit : false, error : false})
          this.source = ""
        } else {
          this.validations.sources = true
        }
      }
    },
    sourceEdit: function (val) {
      if (this.isURLValid(val.link)) {
        val.edit = false
      } else {
        val.error = true
      }
    },
    sourceDelete: function (val) {
      var i = this.sources.findIndex(function (s) {
        return s == val
      })
      this.sources.splice(i, 1)
    },
    getSupplement: function () {
      var supplement = this.supplement
      if (supplement.id) {
        Object.keys(this.lang_inputs).map(function(lang) {
          SupplementService.getSupplement(supplement.id, lang).then((response) => {

            var supplement = (response.data) ? response.data : response;
            Object.keys(this.lang_inputs[lang]).map(function(key) {
              this.lang_inputs[lang][key] = supplement[key]
            }.bind(this))

            if(this.getData){
              this.getData = false
              this.supplement = response.data ? response.data : response
              this.supplementCategories = this.supplement.categories
              this.dose =
                this.supplement.dose && this.supplement.dose.modality != null
                  ? this.supplement.dose
                  : this.dose
              this.sources =
                this.supplement.science_sources && this.supplement.science_sources.length > 0
                  ? this.supplement.science_sources
                  : []
              this.sources = this.sources.map(function(s){
                s.error = false
                s.edit = false
                return s
              })
              this.schedule.form = (this.supplement.schedule) ? this.supplement.schedule : ""
              this.schedule.count = (this.supplement.scheduleDay) ? this.supplement.scheduleDay : ""
              this.supplement.benefits = (this.supplement.benefits.length > 0) ? this.supplement.benefits : [""]  
            }
          })
        }.bind(this))
        this.getBrands()
      }
    },
    getAllSupplements(){
      SupplementService.getAll().then((response)=>{
        this.interactions.supplements = (response.data) ? response.data : response
        this.interactions.supplements.map(s => {
          s.active = false
          return s
        })

        this.interactions.supplements = this.interactions.supplements.filter(s => s.id !== this.supplement.id)
        this.getSupplenetInteraction()
      })
    },
    getAllMedicines(){
      MedicationService.getAllFamilies().then((response)=>{
        this.medicines = (response.data) ? response.data : response
        this.medicines.map(s => {
          s.active = false
          s.generic = ""
          s.class_id = s.id
          s.id = ""
          s.medication_class_drugs = s.medication_class_drugs.filter(d => d.drug !== null)

          s.medication_class_drugs.map(d => {
            d.active = false
            d.id = d.drug.id
            d.name = d.drug.name
            return d
          })
          return s
        })

        this.getMedicineInteraction()
      })
    },
    getCategories: function (lang) {
      SupplementService.getCategories(lang).then((response) => {
        this.categories = response ? response : []
      })
    },
    getBrands() {
      var _this = this
      SupplementService.getBrands().then((response) => {
        _this.brands = (response.data) ? response.data : response
        var sB = _this.brands.filter(b => {
          return _this.supplement.brands.includes(b.name)
        })
        _this.supplement.brands = sB.map(b => {
          return b.id.toString()
        })
      }).catch(function(e){
        _this.loading = false
      });
    },
    getAllDiseases(){
      DiseaseService.getAll().then((response)=>{
        this.interactions.sickness = (response.data) ? response.data : response
        this.interactions.sickness.map(s => {
          s.active = false
          return s
        })
        this.getDiseaseInteraction()
      })
    },
    getSupplenetInteraction(){
      if(this.supplement.id){
        SupplementService.getInteractionSupplement({"supplement_id" : this.supplement.id}).then((response) => {
          var s_s = response.data.map( s => s.supplement_id_2)
          this.actual_interaction_supplement = s_s
          this.interactions.supplements.map(s => s.active = s_s.includes(s.id))
        })
      }
    },
    getDiseaseInteraction(){
      if(this.supplement.id){
        DiseaseService.getSupplementInteraction(this.supplement.id).then((response) => {
          this.actual_interaction_diseases = JSON.parse(JSON.stringify(response.data))
          var s_s = response.data.map( s => s.disease_id)
          this.interactions.sickness.map(s => s.active = s_s.includes(s.id))
        })
      }
    },
    getMedicineInteraction(){
      if(this.supplement.id){
        MedicationService.getMedicineSupplement({"supplement_id" : this.supplement.id}).then((response) => {
          var int_medicines = response.data
          int_medicines = int_medicines.filter(m => m.drug)

          if(int_medicines.length === 0) {
            this.addMedicine({})
            return;
          }

          var data = int_medicines.reduce((acc, d) => {
            var class_id = d.drug && d.drug.medication_class_drugs.length > 0 ? d.drug.medication_class_drugs[0].medication_class_id : d.medication_class_id
            if (Object.keys(acc).includes(d.interaction_medication_supplement_detail_id)) return acc;
            this.class_drug_exist.push(class_id)
            var obj = {
              id: d.interaction_medication_supplement_detail_id,
              class_id: class_id,
              level: d.interaction_medication_supplement_detail.level,
              action_mechanism : d.interaction_medication_supplement_detail.action_mechanism,
              description: d.interaction_medication_supplement_detail.description,
              sources: d.interaction_medication_supplement_detail.interaction_medication_supplement_detail_sources || [],
              generic: "",
              medication_class_drugs : int_medicines.filter(g => {
                return g.interaction_medication_supplement_detail_id === d.interaction_medication_supplement_detail_id
              })
            }
            acc[d.interaction_medication_supplement_detail_id] = obj 
            return acc;
          }, [])
          this.interactions.medicines = []

          for(var i in data){
            const m_class = this.medicines.find(m => m.class_id === data[i].class_id)

            if(m_class){
              const medicine_class = Object.create(m_class);
              medicine_class.medication_class_drugs = JSON.parse(JSON.stringify(m_class.medication_class_drugs))
              
              medicine_class.medication_class_drugs.map(m => {
                if(data[i].medication_class_drugs.findIndex(d => d.drug_id === m.drug_id) !== -1){
                  this.drugs_exist.push(m.drug.id)
                }

                m.active = data[i].medication_class_drugs.findIndex(d => d.drug_id === m.drug_id) !== -1
                m.id = m.drug.id
                m.name = m.drug.name
                return m
              })
              const sources = data[i].sources
              sources.map(s => {
                s.edit = false
                s.error = false
                return s
              })
              medicine_class.action_mechanism = data[i].action_mechanism
              medicine_class.description = data[i].description
              medicine_class.active = true
              medicine_class.level = data[i].level
              medicine_class.class_id = data[i].class_id
              medicine_class.id = data[i].id
              medicine_class.sources = sources
              medicine_class.sources_deleted = []
              medicine_class.source = ""
              this.interactions.medicines.push(medicine_class)
            }
          }
        })
      }
    },
    saveSuccessSupplement() {
      Toastify({
        node: cash("#save-supplement-success").clone().removeClass("hidden")[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true
      }).showToast()
    },
    async saveSupplement() {
      this.validate()

      if (this.validations.sources || this.validations.value || this.validations.range || this.validations.times) {
        return
      } else {
        var catsNew = this.categories.filter(function (cat) {
          return cat.new == true
        })

        if (catsNew.length > 0) {
          for (var i in catsNew) {
            var newCat = { text: catsNew[i].name }
            var exist = this.supplementCategories.find(function (c) {
              return c == catsNew[i].id
            })
            if (exist) {
              var responseCats = await SupplementService.saveCategory(newCat, this.lang_key)
              if (responseCats && responseCats.supplement_category) {
                this.supplementCategories.push(
                  responseCats.supplement_category.id
                )
              }
            }
          }
        }

        this.supplement.categories = this.supplementCategories.filter(function (
          c
        ) {
          return !isNaN(c)
        })

        this.supplement.dose = this.dose
        this.supplement.science_sources = this.sources.map(function(s){
          return s.link
        })
        this.supplement.sources = this.supplement.science_sources
        this.supplement.schedule = this.schedule.form
        this.supplement.scheduleDay = this.schedule.count
        
        this.supplement.brands = this.supplement.brands.map(b => parseInt(b))
        this.supplement.benefits = this.supplement.benefits.filter(b => b !== '')

        var l_keys = Object.keys(this.lang_inputs)
        for(var i = 0; i < l_keys.length; i ++){
          this.loading = true
          var lang = l_keys[i]

          this.supplement.text = this.lang_inputs[lang].name

          Object.keys(this.lang_inputs[lang]).map(function(key) {
            this.supplement[key] = this.lang_inputs[lang][key]
          }.bind(this))

          this.validations.name = this.supplement.text === ""

          if (this.validations.text) {
            this.loading = false
            return
          }

          var serverResponse = await SupplementService.save(this.supplement, lang)

          this.loading = false
          if(serverResponse && serverResponse.id && !this.supplement.id){
            this.supplement.id = serverResponse.id
          }
          var drugs_ids = []
          var class_drug_ids = []
          if(i === (l_keys.length - 1)){
            for(var i = 0; i < this.interactions.medicines.length; i ++){
              var medicine = this.interactions.medicines[i]
              if(medicine.class_id !== ""){
                medicine.drug_ids = medicine.medication_class_drugs.filter(m => m.active)
                medicine.drug_ids = medicine.drug_ids.map(d => d.id)
                drugs_ids = drugs_ids.concat(medicine.drug_ids)
                medicine.supplement_id = this.supplement.id
                medicine.medication_class_id = medicine.class_id
                class_drug_ids.push(medicine.class_id)
                if(medicine.id === "")
                  delete medicine.id
                delete medicine.generic
                delete medicine.name
              }
            }
            var medicines = this.interactions.medicines.filter(m => m.class_id !== "")
            await MedicationService.addMedicineSupplement(medicines)

            const deleteMedicines = this.drugs_exist.filter(d => !drugs_ids.includes(d));
            var delete_medicine = []
            if(deleteMedicines.length > 0){
              delete_medicine.push({"supplement_id" : this.supplement.id, "drug_ids" : deleteMedicines})
            }

            const deleteClassMedicines = this.class_drug_exist.filter(d => !class_drug_ids.includes(d));
            if(deleteClassMedicines.length > 0){
              for(var mm in deleteClassMedicines){
                delete_medicine.push({"supplement_id" : this.supplement.id, "medication_class_id" : deleteClassMedicines[mm]})
              }
            }

            if(delete_medicine.length > 0)
              await MedicationService.deleteMedicineSupplement(delete_medicine)
            

            var spp = this.interactions.supplements.filter(s => s.active)
            if(spp.length > 0){
              var save_supplement = spp.map(sp => {
                sp = {"supplement_id_1" : this.supplement.id, "supplement_id_2" : sp.id}
                return sp
              })
              await SupplementService.saveInteractionSupplement(save_supplement)
            }

            var spp_delete = this.interactions.supplements.filter(s => !s.active && this.actual_interaction_supplement.includes(s.id))
            if(spp_delete.length > 0){
              var delete_supplement = spp_delete.map(sp => {
                sp = {"supplement_id_1" : this.supplement.id, "supplement_id_2" : sp.id}
                return sp
              })
              await SupplementService.deleteInteractionSupplement(delete_supplement)
            }


            var int_disease = this.interactions.sickness.filter(s => s.active)
            if(int_disease.length > 0){
              var save_sickn = int_disease.map(sp => {
                sp = {"supplement_id" : this.supplement.id, "disease_id" : sp.id}
                return sp
              })
              await DiseaseService.saveSupplementInteraction(save_sickn)
            }

            var int_disease_delete = this.interactions.sickness.filter(s => {
              var exist = this.actual_interaction_diseases.findIndex(d => {
                return d.disease_id === s.id
              })
              return !s.active && exist !== -1
            })
            if(int_disease_delete.length > 0){
              var delete_supplement = int_disease_delete.map(sp => {
                var interaction = this.actual_interaction_diseases.find(d => {
                  return d.disease_id === sp.id
                })
                sp = interaction ? {"id" : interaction.id} : null
                return sp
              })
              delete_supplement = delete_supplement.filter(sp => sp !== null)
              console.log(delete_supplement)
              await DiseaseService.removeSupplementInteraction(delete_supplement)
            }


            this.saveSuccessSupplement()
            localStorage.removeItem("supplementItem")
            this.$router.push({ path: "/supplement-list" })
          }
        }
      }
    },
    isURLValid: function (url) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" +
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
          "((\\d{1,3}\\.){3}\\d{1,3}))" +
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
          "(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
      return !!pattern.test(url)
    },
    goSource(url){
      window.open(url, '_blank');
    },
    validate(){
      this.validations.sources =
        this.supplement.sources && this.supplement.sources.includes(".")
          ? this.isURLValid(this.supplement.sources)
          : false
      this.validations.value = (this.dose.min != "" && this.dose.min < 0)
      this.validations.range = (this.dose.modality == "RANGE" && (this.dose.min < 0 || this.dose.max < 0))
      this.validations.times = (this.schedule.count < 0 && (this.schedule.form == this.$t('views.supplements.text31') || this.schedule.form == this.$t('views.supplements.text32')))
    },
    changeLang(l){
      this.activeLang = l
    },
    addBenefit(){
      var empty = this.supplement.benefits.filter(l => l === "")
      if(empty.length === 0 )
        this.supplement.benefits.push("")
    },
    removeBenefit(val){
      var i = this.supplement.benefits.findIndex(function (s) {
        return s == val
      })

      if(i !== -1 && this.supplement.benefits.length > 1)
        this.supplement.benefits.splice(i, 1)
    },
    addInteraction(section, type){
      console.log(section, type)
      if(type === "supplement"){
        this.interactions[section].map(s => {
          if(s.id === parseInt(this.interactions[type]))
            s.active = true
          return s
        })
      }

      if(type === "sickn"){
        this.interactions[section].map(s => {
          if(s.id === this.interactions[type])
            s.active = true
          return s
        })
      }
      this.interactions[type] = ""
    },
    removeInteraction(item, type){
      this.interactions[type].map(s => {
        if(s.id === item.id)
          s.active = false
        return s
      })
    },
    addMedicine(m){
      
      if(m.id != "0"){
          var item = this.medicines.find(mm => mm.class_id === m.class_id)
          if(item && !m.active){
            m.medication_class_drugs = []
            item.medication_class_drugs.forEach(l => {
              m.medication_class_drugs.push({id: l.drug_id, name: l.drug.name, active: false})
            });
          }
      }
      var empty = this.interactions.medicines.filter(m => m.class_id === "" || m.class_id === "0")

      if(empty.length === 0){
        this.interactions.medicines.push({
          id: '', 
          class_id: '', 
          name: '',
          level: '',
          action_mechanism : "", 
          description: "",
          generic: "",
          medication_class_drugs : [],
          source: "",
          sources: [],
          sources_deleted: []
        },)
      }
    },
    removeMedicine(m, key){
      var empty = this.interactions.medicines.filter(m => m.class_id === "" || m.class_id === "0")

      if((empty.length > 1 || (empty.length === 1 && m.class_id !== "" && m.class_id != "0") ) && this.interactions.medicines.length > 1)
        this.interactions.medicines.splice(key, 1);
    },
    addMedicineItem(medicine){
      medicine.medication_class_drugs.map(m => {
        if(m.id === medicine.generic){
          m.active = true
        }
        return m
      })
    },
    removeMedicineItem(medicine, drug){
      drug.active = false
    },
    medicineSourceAdd: function (event, m) {
      if (m.source != "" && (event.key == "Enter" || event.pointerId || event.type == "focusout")) {
        if (this.isURLValid(m.source)) {
          m.sources.push({source : m.source, edit : false, error : false})
          m.source = ""
        }
      }
    },
    medicineSourceEdit: function (val) {
      if (this.isURLValid(val.source)) {
        val.edit = false
      } else {
        val.error = true
      }
    },
    medicineSourceDelete: function (val, m) {
      var i = m.sources.findIndex(function (s) {
        return s == val
      })
      
      var s = m.sources.splice(i, 1)
      if(s.length > 0 && s[0].id){
        m.sources_deleted.push(s[0].id)
      }
    },
  }
}
</script>
<style>
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.2 !important; /* Firefox */
}
</style>
