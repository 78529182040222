import axios from 'axios'
//import { TokenService } from './storage.service'
// import { store } from '../store'
import { useStore } from '@/store'
const store = useStore()

//axios.defaults.baseURL = "http://127.0.0.1:3500/"
const setHeader = {
  lang(l = null){
    l = (l) ? l : localStorage.lang
    axios.defaults.headers.common["x-lang"] = localStorage.lang
  }
}

const ApiService = {

    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setHeader() {
        axios.defaults.headers.common["Authorization"] = localStorage.token
        axios.defaults.headers.common["x-lang"] = localStorage.lang || "es"
    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource, x_lang = null) {
        var headers = {}
        if(x_lang){
          headers = {
            'x-lang' : x_lang
          }
        }
        return axios.get(resource, {headers})
    },

    _get(resource,data = {}, x_lang = null) {
        var headers = {}
        if(x_lang){
          headers = {
            'x-lang' : x_lang
          }
        }
        return axios.get(resource, {params: data, headers}, {headers})
    },

    post(resource, data, x_lang) {
      var headers = {}
      if(x_lang){
        headers = {
          'x-lang' : x_lang
        }
      }
      return axios.post(resource, data, {headers})
    },

    put(resource, data) {
        return axios.put(resource, data)
    },

    delete(resource) {
        return axios.delete(resource)
    },

    getApiUri(){
      return axios.defaults.baseURL
    },

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        return axios(data)
    },

    _401interceptor: null,

    mount401Interceptor() {

        this._401interceptor = axios.interceptors.response.use(
            res => res,
            async (error) => {
              var message = "Tu cuenta ha sido bloqueada"
              if(error && error.response && error.response.data && error.response.data.hasOwnProperty('error') ){
                message = error.response.data.error
              }
              if(error && error.response && error.response.data && error.response.data.hasOwnProperty('message') ){
                message = error.response.data.message
              }

              if (error.response && error.response.status === 400) {
                store.dispatch('main/setAccessDenied', true)
                store.dispatch('main/setAccessDeniedMessage', message)
              }
              if (error.response && error.response.status === 401) {
                if(error.response.data && error.response.data.errors && error.response.data.errors === "permission denied"){
                  window.location.href = "/"
                }else{
                  store.dispatch('main/setAccessDenied', true)
                  store.dispatch('main/setAccessDeniedMessage', message)
                }
              }

              throw error;
            }
          );
    },

    _authInterceptor: null,

    mountauthInterceptor_authInterceptor() {
          this._authInterceptor = axios.interceptors.response.use(function (response) {
            if(response && response.data && response.data.auth === 'error'){
              store.dispatch('main/setAuthError', true)
              store.dispatch('main/setVerifyData', response.data.modal)
            }
              return response;
          }, function (error) {
            return Promise.reject(error);
          });
    },
}

export {ApiService, setHeader}

export default ApiService
