import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import ApiService from './services/api.service'
import i18n from './lang';

// SASS Theme
import './assets/sass/app.scss'

ApiService.init(process.env.VUE_APP_ROOT_API)
ApiService.setHeader();
ApiService.mount401Interceptor();
ApiService.mountauthInterceptor_authInterceptor();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)

globalComponents(app)
utils(app)

app.mount('#app')
