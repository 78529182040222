<template>
  <AlertMessage id_alert="receipeEdit" ref="alertMessage" class="dark myAuto" v-bind:message="'<p>Alerta</p>'" />
  <AlertMessage id_alert="warningSaveRecipe" ref="alertMessageWarning" class="dark myAuto"
    v-bind:message="'<p>Alerta</p>'" />
  <div>
    <div id="save-receipe-success" :class="'toastify-content hidden ' + 'flex'">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.receipes.text12') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div id="remove-receipe-success" :class="'toastify-content hidden ' + 'flex'">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{ $t('views.receipes.text13') }}</div>
        <div class="text-gray-600 mt-1"></div>
      </div>
    </div>
    <div class="intro-y mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ $t('views.receipes.text14') }}: {{ lang_inputs[global_lang].name }}
      </h2>
      <!-- <p class="mt-3">Ingrese los datos de la receta.</p> -->
    </div>
    <div class="grid grid-cols-1 gap-6 mt-5 mb-12">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="lang__tabs nav nav-tabs flex-col sm:flex-row text-gray-600 rounded-b-none" role="tablist">
          <a v-for="(lang, key) in langs" :key="key" data-toggle="tab" @click="changeLang('lang__' + lang.key)"
            :data-target="'#lang__' + lang.key" href="javascript:;"
            class="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center rounded-t-md"
            :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''" :id="'lang__' + lang.key + '-tab'" role="tab"
            :aria-controls="'lang__' + lang.key" aria-selected="false"> {{ $t('components.langs.' + lang.key + '.name') }}
          </a>
        </div>

        <div v-for="(lang, key) in langs" :key="key" :class="'post__lang__' + lang.key" class="tab-content">
          <div :id="'lang__' + lang.key" class="tab-pane " :class="(activeLang === 'lang__' + lang.key) ? 'active' : ''"
            role="tabpanel" :aria-labelledby="'lang__' + lang.key + '-tab'">

            <!-- BEGIN: Form Layout -->
            <div class="intro-y box p-5">
              <div class="relative">
                <label for="crud-form-1" class="form-label ">{{ $t('views.receipes.text15') }}</label>
                <div class="input-group">
                  <input id="crud-form-1" type="text" name="chart-tooltip" class="form-control w-full"
                    :placeholder="$t('views.receipes.text15')" v-model="lang_inputs[lang_key].name" maxlength="110"
                    v-on:keydown="searchRecipe" @blur="changeForm" />
                  <!-- @change="selectRecipe(null)" -->
                </div>
                <div
                  :class="'absolute top-full right-0 left-0 rounded alert-secondary h-auto mt-2 transition-all duration-500 overflow-y-scroll overflow-x-hidden ' + (autocompleteRecipes.length > 0 ? 'z-20 max-h-56' : 'max-h-0')">
                  <ul class="reltaive">
                    <li v-for="(recipe, key) in autocompleteRecipes" :key="key" @click="selectRecipe(recipe)"
                      :class="'cursor-pointer hover:bg-custom-gray-10 block'">
                      <span class="block w-full h-full p-2">{{ recipe.name }}</span>
                    </li>
                  </ul>
                </div>
                <div v-if="validations.name" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text21') }}
                </div>
              </div>
              <div class="mt-5">
                <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text16') }}</label>
                <div class="input-group">
                  <textarea id="crud-form-3" type="text" class="form-control" :placeholder="$t('views.receipes.text16')"
                    aria-describedby="input-group-1" rows="5" v-model="lang_inputs[lang_key].description"
                    @blur="changeForm" maxlength="260" />
                </div>
                <div v-if="validations.description" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text22') }}
                </div>
              </div>
              <!-- <hr class="my-10">
              <div class="">
                <div class="flex items-baseline justify-between">
                  <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text17') }}</label>
                </div>
                <template v-for="(procron, itemKey) in receipe.food_recipe_pro_cons" :key="itemKey">
                    <div class="flex mt-3 receipe-item">
                      <input
                        type="text"
                        name="procrons"
                        class="form-control w-full"
                        :placeholder="$t('views.receipes.text23')"
                        v-model="procron.description"
                        @blur="procronAdd"
                        v-on:keyup="procronAdd"
                      />
                      <button
                          v-on:click="procronDelete(procron)"
                          type="button"
                          class="btn-outline-dark btn-rounded btn-sm remove-item"
                        >
                          <XCircleIcon class="btn-source-icon" />
                        </button>
                    </div>
                </template>
                <div v-if="validations.food_recipe_pro_cons" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text24') }}
                </div>
              </div>
              <hr class="my-10">
              <div class="">
                <div class="flex items-baseline justify-between mb-6">
                  <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text18') }}</label>
                </div>
                <div v-if="essential_nutrients.length > 0" class="flex mt-3 flex-wrap gap-4">
                  <template v-for="(nutrient, itemKey) in essential_nutrients" :key="itemKey">
                    <div class="form-check mr-2 mt-2 sm:mt-0 nutrient"> <input :id="'checkbox-switch-nutrient-'+itemKey" class="form-check-input hidden" type="checkbox" v-model="nutrient.active" @change="changeNutrient"> <label class="form-check-label w-max p-3 px-5 border rounded block m-0" :for="'checkbox-switch-nutrient-'+itemKey">{{ formatName(nutrient.nutrient) }}</label> </div>
                  </template>
                </div>
                <div v-else class="flex mt-3 flex-wrap gap-4">
                    {{ $t('views.receipes.text25') }}
                </div>
                <div v-if="validations.food_recipes_essential_nutrients" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text26') }}
                </div>
              </div> -->
              <hr class="my-10">
              <div>
                <label for="crud-form-3" class="form-label ">{{ "Aplica para" }}</label>
                <div
                  class="mt-2 gap-4 gap-x-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                  <div v-for="item in applyTo" class="form-check mr-2 mt-2 sm:mt-0">
                    <input :id="'checkbox-switch-' + item" class="form-check-input" type="checkbox"
                      v-model="receipe[item]" @change="changeForm" />
                    <label class="form-check-label"
                      :for="'checkbox-switch-' + item">{{ $t('views.receipes.apply_to.' + item) }}</label>
                  </div>
                </div>

                <div v-if="validations.category" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text28') }}
                </div>
              </div>
              <hr class="my-10">
              <div>
                <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text20') }}</label>
                <div
                  class="mt-2 gap-4 gap-x-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-6 xxl:grid-cols-6">
                  <div v-for="diet in typeDiet" class="form-check mr-2 mt-2 sm:mt-0">
                    <input :id="'checkbox-switch-' + diet" class="form-check-input" type="checkbox"
                      v-on:change="(ev) => { setDiet(ev); changeForm() }" v-model="receipe[diet]" />
                    <label class="form-check-label"
                      :for="'checkbox-switch-' + diet">{{ $t('views.receipes.type_diet.' + diet) }}</label>
                  </div>
                </div>

                <div v-if="validations.category" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text28') }}
                </div>
              </div>
              <div class="mt-8">
                <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text19') }}</label>
                <template v-for="(ingredient, ingredientKey) in receipe.food_recipe_ingredients" :key="ingredientKey">
                  <Ingredient :ingredient="ingredient" :foods="foods" :units="units" :setUnits="setUnits"
                    :remove="ingredientDelete" :add="ingredientAdd" :changeFood="changeFood" :recipe="receipe"
                    :glycemics_index="glycemics_index" :showIngredientErrors="validations.food_recipe_ingredients_unit"
                    :clearIngredient="clearIngredient" />
                </template>
                <div v-if="validations.food_recipe_ingredients" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text27') }}
                </div>
              </div>
              <div class="mt-8">
                <label for="crud-form-3" class="form-label ">{{ $t('views.receipes.text43') }}</label>
                <template v-for="(receipeItem, receipeItemKey) in receipe.sub_recipes" :key="receipeItemKey">
                  <ReceipeCompound :receipeItem="receipeItem" :receipes="receipeItems" :units="units" :setUnits="setUnits"
                    :remove="receipeItemDelete" :add="receipeAdd" :changeReceipe="changeReceipe"
                    :glycemics_index="glycemics_index" :showReceipeItemErrors="validations.food_recipe_ReceipeItems_unit"
                    :clearReceipeItem="clearReceipeItem" />
                </template>
                <div v-if="validations.food_recipe_ingredients" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text27') }}
                </div>
              </div>
              <div class="mt-8">
                <div class="flex flex-col sm:flex-row justify-between">
                  <label class="form-label">{{ $t('views.receipes.text9') }}</label>
                  <div class="alert show py-1 px-4 w-max" v-bind:class="glycemic_index_suggested.color" role="alert">
                    {{ $t('views.receipes.text29') }}: {{ glycemic_index_suggested.text }}
                  </div>
                </div>
              </div>

              <hr class="my-10">
              <div class="flex gap-8">
                <div class="w-2/3">
                  <label for="crud-form-3" class="form-label mb-7">{{ 'Tipo de cocina' }}</label>
                  <div class="flex gap-4">
                    <div class="input-group w-full cuisine">
                      <TomSelect class="w-full cursor" v-model="receipe.cuisine_type" :placeholder="'Tipo de cocina'"
                        @change="changeForm">
                        <option :value="''">{{ 'Tipo de cocina' }}</option>
                        <option v-for="(cuisine, i) in cuisines" :key="i" :value="cuisine">
                          {{ $t('views.cuisines.' + cuisine) }}
                        </option>
                      </TomSelect>
                    </div>
                    <div class="input-group w-full cuisine_country">
                      <TomSelect class="w-full cursor" v-model="receipe.cuisine_type_country" :placeholder="'Country'"
                        @change="changeForm">
                        <option :value="''">{{ "Country" }}</option>
                        <option v-for="(country, i) in cuisines_country[receipe.cuisine_type]" :key="i" :value="country">
                          {{ $t('views.country.' + country) }}
                        </option>
                      </TomSelect>
                    </div>
                  </div>
                </div>

                <div class="w-1/3">
                  <label for="crud-form-3" class="form-label mb-7">{{ 'Tiempo de cocción' }}</label>
                  <div class="flex gap-4">
                    <div class="input-group w-full hours">
                      <TomSelect class="w-full cursor" v-model="hours" :placeholder="'Horas'" @change="changeForm">
                        <option :value="''">{{ 'Horas' }}</option>
                        <option :value="0">{{ '00' }}</option>
                        <option v-for="hour in 12" :key="hour" :value="hour">{{ hour < 10 ? '0' + hour : hour }}</option>
                      </TomSelect>
                    </div>
                    <div class="flex justify-center items-center">:</div>
                    <div class="input-group w-full minutes">
                      <TomSelect class="w-full cursor" v-model="minutes" :placeholder="'Minutos'" @change="changeForm">
                        <option :value="''">{{ "Minutos" }}</option>
                        <option :value="0">{{ '00' }}</option>
                        <option v-for="minute in 60" :key="minute" :value="minute">
                          {{ minute < 10 ? '0' + minute : minute }}
                        </option>
                      </TomSelect>
                    </div>
                  </div>
                </div>

              </div>
              <div class="flex">
                <div class="mt-10 mr-5" style="width: 31%;">
                  <label for="crud-form-3" class="form-label mb-7">{{ 'Dificultad' }}</label>
                  <div class="flex gap-4">
                    <div class="input-group w-full dificulty">
                      <TomSelect class="w-full cursor" v-model="receipe.dificulty_level" :placeholder="'Dificultad'"
                        @change="changeForm">
                        <option :value="''">{{ 'Dificultad' }}</option>
                        <option v-for="(val, i) in dificulty" :key="i" :value="val">
                          {{ $t('views.dificulty.' + val) }}
                        </option>
                      </TomSelect>
                    </div>
                  </div>
                </div>

                <div class="mt-10" style="width: 31%;">
                  <label for="crud-form-3" class="form-label mb-7">{{ 'Alimento compuesto' }}</label>
                  <div class="flex gap-4">
                    <div class="input-group w-full">
                      <TomSelect class="w-full cursor" @change="changeForm" v-model="receipe.is_compound_food">
                        <option value="0" key="0">{{ 'No ' }}</option>
                        <option value="1" key="1">{{ 'Si ' }}</option>
                      </TomSelect>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-10">


              <div class="">
                <label for="crud-form-3" class="form-label mb-7">{{ $t('views.receipes.text30') }}</label>
                <div class="input-group">
                  <textarea id="crud-form-3" type="text" class="form-control" :placeholder="$t('views.receipes.text30')"
                    aria-describedby="input-group-1" rows="5" v-model="lang_inputs[lang_key].preparation_mode"
                    @blur="changeForm" />
                </div>
                <div v-if="validations.preparation_mode" class="text-theme-6 mt-2">
                  {{ $t('views.receipes.text31') }}
                </div>
              </div>

              <hr class="my-10">
              <div class="">
                <label for="crud-form-3" class="form-label mb-5">{{ $t('views.receipes.text32') }}</label>
                <p style="display:none" class="mb-5">{{ $t('views.receipes.text33') }}</p>
                <Dropzone v-if="showGallery" class="w-full" ref="gallery" refKey="gallery" v-model="gallery"
                  :options="galleryOptions"
                  :events="{ addedFile: onFileAdd, removedFile: onFileRemove, showMessage: showMessageDropzone }"
                  :existFiles="gallery" />
              </div>
              <div class="text-right mt-5">
                <button v-on:click="confirmRemove" type="button" class="btn btn-outline-dark w-24 mr-5">
                  <LoadingIcon v-if="removeLoading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.delete') }}</span>
                </button>
                <button v-on:click="savereceipe" :disabled="loading" type="button" class="btn btn-primary w-24">
                  <LoadingIcon v-if="loading" icon="three-dots" class="" />
                  <span v-else>{{ $t('views.save') }}</span>
                </button>
              </div>
            </div>
            <!-- END: Form Layout -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dark .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  --tw-text-opacity: 1;
  color: #fff;
}

.mt-3 {
  color: #fff;
}

.modal-body .recomendacion-headline {
  display: none;
}

.modal-body ul:not(ul:first-of-type) {
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal-body li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.max-w-label {
  max-width: 90%;
}

.dark .form-check-input[type=checkbox] {
  background-color: #182033 !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dark .nutrient {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dark .nutrient .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.dark .nutrient .form-check-label {
  border-color: #6c869a;
}

.dark .nutrient:hover input~.form-check-label {
  background-color: rgba(108, 134, 154, 0.5);
}

/* When the checkbox is checked, add a blue background */
.form-check-input[type=checkbox]:checked {
  background-color: #6c869a;
  border-color: #6c869a;
  color: white;
}

.dark .nutrient input:checked~.form-check-label {
  background-color: #6c869a;
  color: white;
}

.form-check-input.checked[type=radio] {
  border-color: #6c869a !important;
}

.form-check-input[type=radio]:before {
  background-color: #6c869a !important;
}

.dark .form-check-input.checked[type=radio] {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dark .form-check-input.checked[type=radio]:before {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}
</style>
<style>
.receipe-item:last-child .remove-item {
  visibility: hidden;
}

.hours .items.ts-input.not-full:not(.has-items)::after {
  content: "Horas"
}

.minutes .items.ts-input.not-full:not(.has-items)::after {
  content: "Minutos"
}

.cuisine .items.ts-input.not-full:not(.has-items)::after {
  content: "Cocina"
}

.cuisine_country .items.ts-input.not-full:not(.has-items)::after {
  content: "Pais"
}

.dificulty .items.ts-input.not-full:not(.has-items)::after {
  content: "Dificultad"
}
</style>
<script>
import { computed } from 'vue'
import ReceipeService from "/src/services/receipes.service";
import FoodService from "/src/services/food.service";
import ApiService from "/src/services/api.service";
import { TokenService, Recipes } from '/src/services/storage.service'
import Toastify from "toastify-js";
import lang_nutrition_contents from '../../utils/lang_nutrition_contents';
import Ingredient from '@/components/ingredient/Main.vue'
import ReceipeCompound from '@/components/compound-recipe/Main.vue'
import AlertMessage from "@/components/alert/Main.vue"
import { useStore } from '@/store'
const store = useStore()

export default {
  components: {
    Ingredient,
    AlertMessage,
    ReceipeCompound
  },
  setup() {

    // const showWarningSave = computed(() => store.state.main.showWarningSave)
    // console.log(showWarningSave.value)
  },
  data() {
    return {
      showWarningSave: computed(() => store.state.main.showWarningSave),
      validations: {
        name: false,
        description: false,
        category: false,
        preparation_mode: false,
        food_recipe_ingredients: false,
        food_recipe_pro_cons: false,
        food_recipe_ingredients_unit: false
      },
      loading: false,
      removeLoading: false,
      queryUrl: this.$route.query,
      hiddenKey: ['id', 'id_api', 'receipe_id', 'updatedAt', 'createdAt', 'deletedAt'],
      units: new Array("gr", "mg", "kg", "lt", "ml"),
      category: "0",
      receipe: {
        name: "",
        description: "",
        food_recipe_pro_cons: [{
          description: ""
        }],
        food_recipe_ingredients: [{
          food_id: "",
          quantity: null,
          unit: "",
          g_equivalent: null,
          edited_g_equivalent: false,
        }],
        sub_recipes: [{
          food_recipe_child_id: "",
          quantity: null,
          unit: "",
          g_equivalent: null,
          edited_g_equivalent: false,
          gi_category: "",
          name: "",
          processed_quantity: 0,
        }],
        preparation_mode: "",
        food_recipe_galleries: [],
        glycemic_index: 0,
        food_recipes_essential_nutrients: [],
        langs: {
          es: {
            name: "",
            description: "",
            preparation_mode: ""
          },
          en: {
            name: "",
            description: "",
            preparation_mode: ""
          },
        },
        cooking_time: null
      },
      searchInput: "",
      categories: [],
      receipeCategories: [],
      foods: [],
      ingredients: [],
      food_recipe_pro_cons: [],
      gallery: [],
      galleryOptions: {
        url: "",
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        paramName: "gallery",
        headers: { "Authorization": `${TokenService.getToken()}` },
        maxFiles: 5,
        maxFilesize: 2,
        dictFileTooBig: this.$t('views.receipes.text34'),
        dictInvalidFileType: this.$t('views.receipes.text35'),
        dictFallbackMessage: this.$t('views.receipes.text36'),
        dictMaxFilesExceeded: this.$t('views.receipes.text37'),
        dictFallbackText: this.$t('views.receipes.text38')
      },
      glycemic_index_suggested: { val: 0, text: "No aplica", color: 'alert-secondary' },
      show_message_error_glycemic_index: false,
      glycemics_index: [
        { id: "NONE", color: 'alert-secondary', text: this.$t('views.receipes.glycemic_index.none'), val: 0 },
        { id: "LOW", color: 'bg-green-600', text: this.$t('views.receipes.glycemic_index.low'), val: 1 },
        { id: "LOW_MODERATE", color: 'bg-custom-green-2', text: this.$t('views.receipes.glycemic_index.low_moderate'), val: 1 },
        { id: "MODERATE", color: 'bg-yellow-600', text: this.$t('views.receipes.glycemic_index.moderate'), val: 2 },
        { id: "MODERATE_HIGH", color: 'bg-custom-orange-1', text: this.$t('views.receipes.glycemic_index.moderate_high'), val: 2 },
        { id: "HIGH", color: 'bg-red-600', text: this.$t('views.receipes.glycemic_index.high'), val: 3 },
      ],
      essential_nutrients: [],
      food_recipes_essential_nutrients: [],
      showGallery: true,
      activeLang: 'lang__' + (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      langs: this.$global.getLangs(),
      lang_key: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      global_lang: (localStorage.lang ? localStorage.lang : (navigator.language).split("-")[0]),
      lang_inputs: [],
      getData: true,
      oldIngredients: [],
      // foodsByDiet: [],
      typeDiet: this.$global.getTypeDiet(),
      receipe_ingredients: [],
      hours: "",
      minutes: "",
      cuisines: this.$global.getTypeCuisine(),
      cuisines_country: this.$global.getTypeCuisineCountry(),
      fetchFoods: () => { },
      applyTo: this.$global.getApplyTo(),
      clone: false,
      receipeItems: [],
      receipeItemsByDiet: [],
      oldRecipes: [],
      dificulty: this.$global.getDificulty(),
      loading: false,
      autocompleteRecipes: [],
      lastRecipeData: "",
      lastLangInputs: "",
      newRecipeData: ""
    };
  },
  watch: {
    food_recipe_pro_cons: function () {
      var food_recipe_pro_cons = this.receipe.food_recipe_pro_cons
      this.food_recipe_pro_cons.forEach(function (c) {
        var pro_cons = food_recipe_pro_cons.find(function (cc) {
          return cc.id == c
        })
        if (!pro_cons) {
          food_recipe_pro_cons.push({ id: c, description: c, new: true })
        }
      })
      this.receipe.food_recipe_pro_cons = food_recipe_pro_cons
    },
    '$i18n.locale': function (l) {
      this.getReceipe();
      this.getUnist()
    },
    activeLang: function () {
      this.lang_key = this.activeLang.replace("lang__", "")
    },
    showWarningSave: function () {
      console.log(this.showWarningSave)
      if (this.showWarningSave !== false) {
        var message = "Tiene cambios sin guardar, seguro que desea salir?"
        this.$refs.alertMessageWarning.show(message, true, true, this.confirmExitWithowSave, true, [], {}, false, "", false, false, false, this.cancelWarning, "Salir", "Cancelar")
      }
    }
  },
  computed: {

  },
  created() {
    var apiUri = ApiService.getApiUri()
    this.galleryOptions.url = apiUri + "/api/admin/food_recipe/upload-gallery"
    this.lang_inputs = this.langs.map(l => l.key).reduce((x, y) => (x[y] = { name: "", description: "", preparation_mode: "" }, x), {});
    this.lang_inputs[this.lang_key]["name"] = this.receipe["name"]

    this.getReceipe();
    this.getUnist()
  },
  mounted() {
    this.fetchFoods = this.debounce(function () {
      this.getFoods(this.global_lang)
    }, 1200);
  },
  methods: {
    searchRecipe: _.debounce(function (ev) {
      this.autocompleteRecipes = []
      var search = ev.target.value
      if (search !== "") {
        var params = {
          search: search
        }
        ReceipeService.getAll(params).then((response) => {
          this.autocompleteRecipes = response.data
        })
      }
    }, 500),
    selectRecipe: function (recipe) {
      if (recipe) {
        recipe.clone = true
        localStorage.setItem('receipeItem', JSON.stringify(recipe));
        this.getReceipe()
        Recipes.setIsSaving(true)
      }
      this.autocompleteRecipes = []
    },
    getReceipe: function () {
      var receipe = (localStorage.receipeItem) ? JSON.parse(localStorage.receipeItem) : {}

      if (receipe.id) {
        this.clone = Object.assign({}, receipe).clone;
        const clone = Object.assign({}, receipe).clone;
        this.galleryOptions.url = ApiService.getApiUri() + "/api/admin/food_recipe/upload-gallery?food_receipe_id=" + receipe.id
        Object.keys(this.lang_inputs).map(function (lang) {
          ReceipeService.get(receipe.id, lang).then((response) => {

            var receipe = (response.data) ? response.data : response;
            receipe.is_compound_food = receipe.is_compound_food.toString()

            Object.keys(this.lang_inputs[lang]).map(function (key) {
              this.lang_inputs[lang][key] = receipe[key]
            }.bind(this))

            if (this.getData) {
              this.receipe = (response.data) ? response.data : response;



              this.receipe.food_recipe_ingredients = this.receipe.food_recipe_ingredients.map(function (f) {
                f.unit_id = (f.unit_id) ? f.unit_id.toString() : ""
                f.quantity = (f.quantity && f.quantity !== null) ? f.quantity : ""
                f.food_id = (f.food_id) ? f.food_id.toString() : f.food_id
                f.edited_g_equivalent = true
                if (clone) {
                  delete f.id
                  delete f.food_recipe_id
                }
                return f
              })

              // this.receipe.food_recipe_ingredients = this.receipe.food_recipe_ingredients.filter((f) => {
              //   return f.food_id
              // })

              // console.log(this.receipe.food_recipe_ingredients)

              this.foods = this.receipe.food_recipe_ingredients.map((f) => {
                return f.food
              })

              this.receipe.sub_recipes = this.receipe.sub_recipes.map(function (f) {
                f.unit_id = (f.unit_id) ? f.unit_id.toString() : ""
                f.quantity = (f.quantity && f.quantity !== null) ? f.quantity : ""
                f.food_recipe_child_id = (f.food_recipe_child_id) ? f.food_recipe_child_id.toString() : f.food_recipe_child_id
                f.edited_g_equivalent = true
                if (clone) {
                  delete f.id
                  delete f.food_recipe_child_id
                }
                return f
              })

              this.food_recipe_pro_cons = this.receipe.food_recipe_pro_cons.map(i => i.id)
              this.typeDiet.forEach((diet) => {
                this.receipe[diet] = Boolean(this.receipe[diet])
              })
              this.applyTo.forEach((time) => {
                this.receipe[time] = Boolean(this.receipe[time])
              })

              // this.getFoods(this.global_lang);
              this.getReceipeItems(this.global_lang);

              this.gallery = this.receipe.food_recipe_galleries.map(function (g) {
                g.metadata = JSON.parse(g.metadata)
                g.size = g.metadata.size
                g.name = g.metadata.name
                if (clone) {
                  delete g.id
                  delete g.food_recipe_id
                }
                return g
              })

              this.receipe.glycemic_index = (this.receipe.categories.includes(5)) ? 1 : (this.receipe.categories.includes(6)) ? 2 : (this.receipe.categories.includes(96)) ? 3 : 0

              this.hours = (this.receipe.cooking_time ? Math.floor(this.receipe.cooking_time / 60) : "").toString()
              this.minutes = (this.receipe.cooking_time ? this.receipe.cooking_time % 60 : "").toString()

              this.showGallery = false
              this.calculateGlycemicIndex()
              setTimeout(function () { this.showGallery = true }.bind(this), 60)
              if (clone) {
                delete this.receipe.id
              }
            }

            this.ingredientAdd()
            this.procronAdd()
            this.receipeAdd()

            this.lastRecipeData = JSON.stringify(this.receipe)
            this.lastLangInputs = JSON.stringify(this.lang_inputs)

          });
        }.bind(this));
      } else {
        this.getFoods(this.global_lang);
        this.getReceipeItems(this.global_lang);

        this.lastRecipeData = JSON.stringify(this.receipe)
        this.lastLangInputs = JSON.stringify(this.lang_inputs)
      }
    },
    changeForm: function () {
      setTimeout(function () {
        this.autocompleteRecipes = []
      }.bind(this), 180)

      setTimeout(function () {
        this.receipe.cooking_time = parseInt(this.hours * 60) + parseInt(this.minutes)
        var ids = this.essential_nutrients.filter(n => n.active)
        ids = ids.map(n => n.id)

        this.receipe.food_recipes_essential_nutrients.forEach(function (n, i) {
          if (!ids.includes(n.essential_nutrients_id)) {
            this.receipe.food_recipes_essential_nutrients.splice(i, 1)
          }
        }.bind(this))

        this.receipe.food_recipe_ingredients = this.receipe.food_recipe_ingredients.map(i => {
          i.g_equivalent = i.g_equivalent ? parseFloat(i.g_equivalent) : null
          return i
        })

        const newRecipeData = this.lastRecipeData !== JSON.stringify(this.receipe)
        const newLangInputs = this.lastLangInputs !== JSON.stringify(this.lang_inputs)

        Recipes.setIsSaving(newRecipeData || newLangInputs)
        if (newRecipeData || newLangInputs) {
          Recipes.setIsSaving(newRecipeData || newLangInputs)
        } else {
          Recipes.removeIsSaving()
        }
      }.bind(this), 600)
    },
    confirmExitWithowSave() {
      Recipes.removeIsSaving()
      this.$refs.alertMessageWarning.hide()
      const routePath = JSON.stringify(this.showWarningSave)

      localStorage.removeItem('receipeItem');
      setTimeout(function () {
        this.$router.push({ path: JSON.parse(routePath) });
      }.bind(this), 120)
    },
    cancelWarning() {
      setTimeout(function () {
        store.dispatch('main/setShowWarningSave', false)
        //javascript function to delete element from dom
        var element = document.getElementById("warningSaveRecipe");
        element.parentNode.removeChild(element);
      }, 120)
    },
    getFoods: async function (lang = null) {
      const params = {
        // ingredients: true, 
        pagination: {
          page: 1,
          per_page: 50,
          total: 1,
          total_pages: 1
        }
      }
      this.typeDiet.forEach((d) => {
        if (this.receipe[d])
          params[d] = 1
      })

      const newFoods = await FoodService.getFoodItems(params, this.global_lang)
      const foods = (newFoods.data) ? newFoods.data : newFoods

      this.foods = [...foods, ...this.foods]
      this.setEssentialNutrients(true)
    },
    getReceipeItems: async function (lang = null) {
      const params = {}
      this.typeDiet.forEach((d) => {
        if (this.receipe[d])
          params[d] = 1
      })

      const newReceipes = await ReceipeService.getAll(params, this.global_lang)
      this.receipeItems = (newReceipes.data) ? newReceipes.data : newReceipes

      this.receipeItemsByDiet = [...this.receipeItems]
      this.setEssentialNutrients(true)
    },
    debounce: (fn, delay) => {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer);
        }
        const context = this;
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      }
    },
    setDiet: function (ev) {
      this.receipe.food_recipe_ingredients = this.receipe.food_recipe_ingredients.map(function (s) {
        var f = s.food
        if (f) {
          this.typeDiet.forEach(function (t) {
            if (this.receipe[t] === true && f[t] === 0) {
              s = {
                food_id: "",
                quantity: "",
                unit_id: "",
                g_equivalent: null,
                food: null,
                edited_g_equivalent: false
              }
            }
          }.bind(this))
        }
        return s
      }.bind(this))
    },
    getUnist: function (lang = null) {
      ReceipeService.getUnits(lang).then((response) => {
        this.units = (response.data) ? response.data : response
      })
    },
    formatName(text) {
      return (lang_nutrition_contents[text]) ? lang_nutrition_contents[text].name : ""
    },
    formatUnit(text) {
      return (lang_nutrition_contents[text]) ? lang_nutrition_contents[text].unit : ""
    },
    saveSuccessreceipe() {
      Toastify({
        node: cash('#save-receipe-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      Recipes.removeIsSaving()
      localStorage.removeItem('receipeItem');
      this.$router.push({ path: '/receipes-list' });
    },
    savereceipe: async function (show_message_error_glycemic_index = true) {
      this.loading = true
      const ingredients = this.receipe.food_recipe_ingredients.filter(f => f.food_id && f.food_id !== "0")
      const incompleteUnit = this.receipe.food_recipe_ingredients.filter(f => f.food_id && f.food_id !== "0" && (f.unit_id === '' || f.unit_id === "0"))
      this.validations.food_recipe_ingredients = (ingredients.length == 0)
      this.validations.food_recipe_ingredients_unit = (incompleteUnit.length > 0)
      this.validations.food_recipe_pro_cons = false //(this.receipe.food_recipe_pro_cons.length === 0 || this.receipe.food_recipe_pro_cons.filter(i => i.description != 0).length === 0)

      if ((
        this.validations.food_recipe_ingredients ||
        this.validations.food_recipe_pro_cons ||
        this.validations.food_recipe_ingredients_unit
      )) {
        setTimeout(() => {
          const erros = document.getElementsByClassName('border-red-600')
          if (erros.length > 0) {
            erros[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }, 200);
        this.loading = false
        return
      } else {
        //Comparaba el indice glucemico seleccionado por el usuario y el calculado por ilongevity
        // if(this.receipe.glycemic_index !== this.glycemic_index_suggested.val && show_message_error_glycemic_index){
        //   var message = `<p class='text-base px-10'>${this.$t('views.receipes.text39')} ${this.glycemic_index_suggested.text}. ¿${this.$t('views.receipes.text40')} ${this.glycemics_index[this.receipe.glycemic_index].text}?</p>`
        //   this.$refs.alertMessage.show(message, true, true, this.confirmIndex, false)
        //   return
        // }

        this.receipe.cooking_time = parseInt(this.hours * 60) + parseInt(this.minutes)

        this.receipe.food_recipe_ingredients = ingredients.map(i => {
          i.g_equivalent = i.g_equivalent ? parseFloat(i.g_equivalent) : null
          return i
        })

        this.receipe.sub_recipes = this.receipe.sub_recipes
          .filter(i => i.food_recipe_child_id !== "")
          .map(i => {
            i.g_equivalent = i.g_equivalent ? parseFloat(i.g_equivalent) : null
            return i
          })

        var ids = this.essential_nutrients.filter(n => n.active)
        ids = ids.map(n => n.id)

        this.receipe.food_recipes_essential_nutrients.forEach(function (n, i) {
          if (!ids.includes(n.essential_nutrients_id)) {
            this.receipe.food_recipes_essential_nutrients.splice(i, 1)
          }
        }.bind(this))

        var l_keys = Object.keys(this.lang_inputs)
        var lang = l_keys[0]

        Object.keys(this.lang_inputs[lang]).map(function (key) {
          this.receipe[key] = this.lang_inputs[lang][key]
        }.bind(this))

        if (this.receipe.name !== "" && lang == "es") {
          this.validations.name = (this.receipe.name === "")
          this.validations.description = (this.receipe.description === "")
          this.validations.preparation_mode = (this.receipe.preparation_mode === "")
          if (this.validations.name || this.validations.description || this.validations.preparation_mode) {
            this.loading = false
            return
          }
        }

        // this.receipe.food_recipe_galleries = this.gallery
        this.receipe.langs = this.lang_inputs

        if (((this.receipe.name !== "") && (this.receipe.description !== "") && (this.receipe.preparation_mode !== ""))) {
          var serverResponse = await ReceipeService.save(this.receipe, lang)
          this.loading = false

          if (serverResponse && serverResponse.data && serverResponse.data.length > 0 && !this.receipe.id) {
            this.receipe.id = serverResponse.data[0].id
          }
        }

        this.saveSuccessreceipe()
      }
    },
    ingredientDelete(val) {
      var i = this.receipe.food_recipe_ingredients.findIndex(function (s) {
        return s.food_id == val.food_id
      })

      if (i !== -1 && this.receipe.food_recipe_ingredients.length > 1)
        this.receipe.food_recipe_ingredients.splice(i, 1)
    },
    receipeItemDelete(val) {
      var i = this.receipe.sub_recipes.findIndex(function (s) {
        return s.food_recipe_child_id == val.food_recipe_child_id
      })

      if (i !== -1 && this.receipe.sub_recipes.length > 1)
        this.receipe.sub_recipes.splice(i, 1)
    },
    ingredientAdd(type = "") {
      var empty = this.receipe.food_recipe_ingredients.filter(function (i) {
        return !i.food_id || i.food_id === "0"
      })

      var i = 0
      if (empty.length > 1) {
        var lastIndex = this.receipe.food_recipe_ingredients.findIndex(function (f) {
          if (!f.food_id || f.food_id === "0") {
            i++
          }
          return (!f.food_id || f.food_id === "0") && !f.quantity && !f.unit_id && i > 1
        })

        if (lastIndex !== -1 && this.receipe.food_recipe_ingredients.length > 1)
          this.receipe.food_recipe_ingredients.splice(lastIndex, 1)
      }

      if (empty.length === 0) {
        this.receipe.food_recipe_ingredients.push({
          food_id: "",
          quantity: "",
          unit_id: "",
          g_equivalent: null,
          edited_g_equivalent: false
        })
      }

      if (type == "food") {
        this.setEssentialNutrients()
      }

      this.calculateGlycemicIndex()
    },
    changeFood() {
      this.ingredientAdd("food")
    },
    changeReceipe() {
      this.receipeAdd("receipe")
    },
    receipeDelete(val) {
      var i = this.receipe.sub_recipes.findIndex(function (s) {
        return s.food_recipe_child_id == val.food_recipe_child_id
      })

      if (i !== -1 && this.receipe.sub_recipes.length > 1)
        this.receipe.sub_recipes.splice(i, 1)
    },
    receipeAdd(type = "") {
      var empty = this.receipe.sub_recipes.filter(function (i) {
        return !i.food_recipe_child_id || i.food_recipe_child_id === "0"
      })

      var i = 0
      if (empty.length > 1) {
        var lastIndex = this.receipe.sub_recipes.findIndex(function (f) {
          if (!f.food_recipe_child_id || f.food_recipe_child_id === "0") {
            i++
          }
          return (!f.food_recipe_child_id || f.food_recipe_child_id === "0") && !f.quantity && !f.unit_id && i > 1
        })

        if (lastIndex !== -1 && this.receipe.sub_recipes.length > 1)
          this.receipe.sub_recipes.splice(lastIndex, 1)
      }

      if (empty.length === 0) {
        this.receipe.sub_recipes.push({
          food_recipe_child_id: "",
          quantity: null,
          unit_id: "",
          g_equivalent: null,
          edited_g_equivalent: false,
          gi_category: "",
          name: "",
          processed_quantity: 0,
        })
      }

      this.calculateGlycemicIndex()
    },
    procronAdd() {
      var i = 0
      var empty = this.receipe.food_recipe_pro_cons.filter(p => p.description === "")

      if (empty.length > 1) {
        var lastIndex = this.receipe.food_recipe_pro_cons.findIndex(function (f) {
          if (f.description === "") {
            i++
          }
          return f.description === "" && i > 1
        })

        if (lastIndex !== -1 && this.receipe.food_recipe_pro_cons.length > 1)
          this.receipe.food_recipe_pro_cons.splice(lastIndex, 1)
      }

      if (empty.length === 0) {
        this.receipe.food_recipe_pro_cons.push({
          description: ""
        })
      }
    },
    procronDelete(val) {
      var i = this.receipe.food_recipe_pro_cons.findIndex(function (s) {
        return s.description == val.description
      })

      if (i !== -1 && this.receipe.food_recipe_pro_cons.length > 1)
        this.receipe.food_recipe_pro_cons.splice(i, 1)
    },
    onFileAdd(data) {
      if (data && data.xhr && data.xhr.response)
        try {
          var data = JSON.parse(data.xhr.response).data
          if (data && data.length > 0) {
            this.receipe.food_recipe_galleries.push(data[0])
          }
        } catch (e) { }
      this.changeForm()
    },
    onFileRemove(data) {

      if (data && data.xhr && data.xhr.response)
        try {
          var data = JSON.parse(data.xhr.response).data
          if (data && data.length > 0) {
            data = data[0]
          }
        } catch (e) { }

      var i = this.receipe.food_recipe_galleries.findIndex((img) => img.link == data.dataURL || img.name === data.name)

      if (i !== -1 && !this.clone) {
        let photoId = this.receipe.food_recipe_galleries[i].id;
        this.receipe.food_recipe_galleries.splice(i, 1)
        ReceipeService.deleteGalleryPhoto(photoId)

      }

      this.changeForm()
    },
    setUnits(units) {
      this.units = units
    },
    calculateGlycemicIndex: async function () {
      var _this = this

      this.receipe.food_recipe_ingredients = await this.receipe.food_recipe_ingredients.map(function (i) {
        var food = _this.foods.find(function (f) {
          return f.id === parseInt(i.food_id)
        })

        if (food)
          i.food = food

        return i
      })

      const ingredients = [];
      this.receipe.food_recipe_ingredients.forEach((i) => {
        let gi = ""

        if (i.food && i.food.categories) {
          gi = i.food.categories.includes(5) ? 5 : gi
          gi = i.food.categories.includes(6) ? 6 : gi
          gi = i.food.categories.includes(96) ? 96 : gi
        }

        if (i.food_id && i.quantity && i.unit_id)
          ingredients.push({ "id": i.food_id, "quantity": i.quantity, "unit_id": i.unit_id, "glycemic_index": gi, "g_equivalent": i.g_equivalent, "edited_g_equivalent": i.edited_g_equivalent })
      })

      const recipesAdded = [];
      this.receipe.sub_recipes.forEach((i) => {
        if (i.food_recipe_child_id && i.quantity && i.unit_id)
          recipesAdded.push({
            food_recipe_child_id: i.food_recipe_child_id,
            quantity: i.quantity,
            unit_id: i.unit_id,
            g_equivalent: i.g_equivalent,
            edited_g_equivalent: i.edited_g_equivalent,
            gi_category: i.gi_category,
            name: i.name,
            processed_quantity: i.processed_quantity
          })
      })

      if ((JSON.stringify(Object.values(this.oldIngredients)) !== JSON.stringify(ingredients)) || (JSON.stringify(Object.values(this.oldRecipes)) !== JSON.stringify(recipesAdded))) {
        this.oldIngredients = { ...ingredients }
        this.oldRecipes = { ...recipesAdded }
        const params = { "foods": ingredients, sub_recipes: recipesAdded };
        const response = await ReceipeService.getIG(params, this.lang_key)

        var receipe_ig = this.glycemics_index.find(g => {
          return g.id === response
        })

        if (receipe_ig) {
          this.glycemic_index_suggested = receipe_ig
          this.receipe.gi_category = receipe_ig.id
        } else {
          this.glycemic_index_suggested = this.glycemics_index[0]
        }
      }

      this.changeForm();
    },
    setEssentialNutrients: async function (init = false) {
      var _this = this
      var nutrients = []

      await this.receipe.food_recipe_ingredients.forEach(function (i) {
        var food = _this.foods.find(function (f) {
          return f.id === parseInt(i.food_id)
        })

        if (food && food.essential_nutrients) {
          food.essential_nutrients.forEach(nutrient => {
            nutrient.active = false
            nutrients.push(nutrient)
          })
        }
      })

      this.essential_nutrients = nutrients
      this.essential_nutrients = [...new Map(this.essential_nutrients.map(item => [item["id"], item])).values()];
      if (init) {
        this.essential_nutrients.map(function (n) {
          var exist = this.receipe.food_recipes_essential_nutrients.find(f_nutrirent => f_nutrirent.essential_nutrients_id === n.id)
          n.active = (exist) ? true : false
          return n
        }.bind(this))
      }
    },
    confirmIndex() {
      this.$refs.alertMessage.hide()
      this.loading = true
      setTimeout(function () { this.savereceipe(false) }.bind(this), 600);
    },
    showMessageDropzone(file) {
      if (file.size > 2000000) {
        var message = this.$t('views.receipes.text41')
        this.$refs.alertMessage.show(message)
        var _this = this
        setTimeout(function () {
          _this.$refs.alertMessage.hide()
        }, 3000)
      }
    },
    confirmRemove() {
      var message = "<p class='text-base px-4'>" + this.$t('views.receipes.text42') + "</p>"
      this.$refs.alertMessage.show(message, true, true, this.removeReceipe)
    },
    removeReceipe() {
      this.removeLoading = true
      this.$refs.alertMessage.hide()
      var _this = this
      ReceipeService.remove(this.receipe.id).then(function (response) {
        _this.removeLoading = false
        _this.removeSuccessreceipe()
        _this.getReceipe()
      }).catch((error) => {
        this.removeLoading = false
      })
    },
    removeSuccessreceipe() {
      Toastify({
        node: cash('#remove-receipe-success')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
      localStorage.removeItem('receipeItem');
      this.$router.push({ path: '/receipes-list' });
    },
    changeNutrient() {
      var ids = this.essential_nutrients.filter(n => n.active)
      ids = ids.map(n => n.id)
      ids.forEach(id => {
        var exist = this.receipe.food_recipes_essential_nutrients.find(n => n.essential_nutrients_id === id)
        if (!exist) {
          this.receipe.food_recipes_essential_nutrients.push({ essential_nutrients_id: id })
        }
      })
    },
    changeLang(l) {
      this.activeLang = l
    },
    clearIngredient() {

    },
    clearReceipeItem(val) {
      this.receipe.sub_recipes = this.receipe.sub_recipes.map(function (s) {
        if (s.food_recipe_child_id == val.food_recipe_child_id) {
          var f = this.receipeItemsByDiet.find(function (ss) {
            return ss.id == parseInt(val.food_recipe_child_id)
          })

          if ((!f || f == undefined) && val.quantity !== "" && val.unit_id !== "") {
            s = {
              food_recipe_child_id: "",
              quantity: null,
              unit_id: "",
              g_equivalent: null,
              edited_g_equivalent: false,
              gi_category: "",
              name: "",
              processed_quantity: 0,
            }
          }
        }
        return s
      }.bind(this))

    }
  },
};
</script>
<style></style>
