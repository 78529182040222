import ApiService from './api.service'

const DiseaseService = {

    getAll: async function(params) {

        const response = await ApiService._get("/api/admin/disease/get-all", params);
        return response.data

    },


    save: async function(params, lang = null) {

      const response = await ApiService.post("/api/admin/disease/save", params, lang);
      return response.data

    },


    remove: async function(id, lang = null) {

      const response = await ApiService.get("/api/admin/disease/delete?id="+id, lang);
      return response.data

    },

    getSupplementInteraction: async function(id, lang = null) {

        const response = await ApiService.get("/api/admin/disease/get-supplement-interaction?supplement_id="+id, lang);
        return response.data
  
    },

    saveSupplementInteraction: async function(params, lang = null) {

        const response = await ApiService.post("/api/admin/disease/save-supplement-interaction", params, lang);
        return response.data
  
    },


    removeSupplementInteraction: async function(params, lang = null) {

        const response = await ApiService.post("/api/admin/disease/delete-supplement-interaction", params, lang);
        return response.data

    }

}

export default DiseaseService
